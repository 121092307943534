

import { BASE_URL } from '../config/config';

// const BASE_URL = 'https://qa_green_wallet_api.bitzwallet.com/'; // Replace with your API base UR
// const BASE_URL = 'http://localhost:3000'; // Replace with your API base UR
// const BASE_URL = 'http://192.168.8.137:3000';


// const BASE_URL = 'https://green_wallet_api.bitzwallet.com';



const PublicAPI = {

    post: async (path: any, data: any) => {
        try {
            const result = await fetch(BASE_URL + path, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            return result.json();
        } catch (error) {
            console.log("Public API POST --> " + error);
            return error;
        }
    },

    get: async (path: any) => {
        try {
            const result = await fetch(BASE_URL + path, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            })
            return result.json();
        } catch (error) {
            console.log("Public API GET --> " + error);
            return error;
        }
    },

};

// import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

// const BASE_URL = 'http://localhost:3000'; // Replace with your API base URL
// // const BASE_URL = 'https://api.bitzwallet.com';

// const api = axios.create({
//     baseURL: BASE_URL,
// });

// // Function to handle API errors
// const handleApiError = (error: any) => {
//     console.error('API Error:', error);
//     throw new Error('An error occurred while making the API call.');
// };

// // Generic function to make an API request
// const makeApiRequest = async <T>(
//     method: string,
//     url: string,
//     data?: any,
//     config?: AxiosRequestConfig
// ): Promise<any> => {
//     try {
//         const response: AxiosResponse<T> = await api.request<T>({
//             method,
//             url,
//             data,
//             ...config,
//         });

//         return response.data;
//     } catch (error) {
//         handleApiError(error);
//     }
// };

// // API methods
// const API = {
//     get: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
//         return makeApiRequest<T>('GET', url, undefined, config);
//     },
//     post: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
//         return makeApiRequest<T>('POST', url, data, config);
//     },
//     patch: async <T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> => {
//         return makeApiRequest<T>('PATCH', url, data, config);
//     },
//     delete: async <T>(url: string, config?: AxiosRequestConfig): Promise<T> => {
//         return makeApiRequest<T>('DELETE', url, undefined, config);
//     },
// };

export default PublicAPI;
