import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    Image,
    TextInput,
    TouchableOpacity,
    ScrollView,
    Modal,
} from 'react-native';

import { useTheme } from '@react-navigation/native';
import { COLORS, FONTS, ICONS, IMAGES } from '../../constants/theme';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import CustomButton from '../customButton';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SvgQRCode from 'react-native-qrcode-svg';
import * as Clipboard from 'expo-clipboard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import OptionBar from '../../components/modal/OptionBar';

function Simple({ address }: any) {
    return <SvgQRCode backgroundColor='transparent' size={300} color='white' value={address} />;
}


const DepositCryptoQr = () => {

    const { colors } = useTheme();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [depAddress, setdepAddress] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });

    const retrieveData = () => {
        AsyncStorage.getItem('depSelectData')
            .then(value => {
                if (value !== null) {
                    const depAddress = JSON.parse(value);
                    console.log('Get deposit Select Data', value)
                    setdepAddress(depAddress);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }

    const copyToClipboard = async () => {
        Clipboard.setStringAsync(depAddress.address);
    };

    const saveDepositTry = () => {
        let obj = {
            user: profile.id,
            wallet: depAddress.coin,
            address: depAddress.address,
            createdAt: new Date()
        }
        console.log(obj)
        PrivateApi.post('/deposit-try', tokens.user, obj).then((res: any) => {
            console.log('res', res);
            model('information-circle-sharp', 'Confirm', 'Deposit successfully');
        }).catch((err) => {
            console.log('err', err);
            model('information-circle-sharp', 'Error', 'Error in deposit');
        });
    }

    useEffect(() => {
        retrieveData();
        getProfile();
    }, []);

    return (
        <>
            <View
                style={{
                    paddingHorizontal: 15,
                    marginTop: 15,
                }}
            >
                <Text style={{ ...FONTS.h5, color: colors.title }}>Deposit Crypto QR</Text>
            </View>
            <View style={{ flex: 1 }}>
                <ScrollView contentContainerStyle={{ padding: 15 }} showsVerticalScrollIndicator={true}>
                    <TouchableOpacity activeOpacity={1}>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 325,
                            marginTop: -20,
                            position: 'relative',
                        }}>
                            {<Simple address={depAddress.address} />
                            }

                            {/* <Image
                                style={{
                                    height: 220,
                                    width: 220,
                                    resizeMode: 'contain',
                                    tintColor: colors.title,
                                }}
                                source={IMAGES.qrCode}
                            // source={{uri:'https://img.freepik.com/premium-vector/qr-code-vector-icon_389832-989.jpg'}}
                            /> */}

                            {/* <Image
                                source={IMAGES.bitcoin}
                                style={{
                                    height: 38,
                                    width: 38,
                                    position: 'absolute',
                                    borderRadius: 38,
                                }}
                            /> */}
                        </View>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                value='Bitcoin BTC'
                            />
                            <View style={{
                                flexDirection: 'row',
                                position: 'absolute',
                                right: 18,
                                top: 12,
                            }}>
                                <TouchableOpacity>
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary
                                        }}
                                        source={ICONS.transfer} />
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                // value='Binance Chain (BEP2)'
                                value={depAddress.coin}
                            />
                        </View>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                // value='0xbc6b1972ea764159a4cf1c0'
                                value={depAddress.address}
                            />
                            <View style={{
                                flexDirection: 'row',
                                position: 'absolute',
                                right: 18,
                                top: 12,
                            }}>
                                <TouchableOpacity onPress={() => copyToClipboard()}>
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary
                                        }}
                                        source={ICONS.copy} />
                                </TouchableOpacity>
                            </View>
                        </View>

                        <CustomButton onPress={saveDepositTry} title='Deposit' />
                    </TouchableOpacity>
                </ScrollView>

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                >
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        position: 'relative',
                    }}>
                        <TouchableOpacity
                            activeOpacity={1}
                            onPress={() => setModalVisible(false)}
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,.3)',
                            }}
                        />
                        <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                    </View>
                </Modal>

            </View>

        </>
    )
}


export default DepositCryptoQr;