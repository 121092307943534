import React, { useEffect } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Image,
} from 'react-native';
import {
    LineChart,
} from "react-native-chart-kit";
import { useTheme } from '@react-navigation/native';
import { FONTS, COLORS, IMAGES } from '../constants/theme';
import { GlobalStyleSheet } from '../constants/styleSheet';

const GainersData = [
    {
        "id": "bitcoin",
        "symbol": "btc",
        "name": "Bitcoin",
        "image": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
        "current_price": 29890,
        "market_cap": 581157151367,
        "market_cap_rank": 1,
        "fully_diluted_valuation": 627923228894,
        "total_volume": 8100011748,
        "high_24h": 30012,
        "low_24h": 29758,
        "price_change_24h": 91.45,
        "price_change_percentage_24h": 0.30688,
        "market_cap_change_24h": 1900162802,
        "market_cap_change_percentage_24h": 0.32803,
        "circulating_supply": 19435975.0,
        "total_supply": 21000000.0,
        "max_supply": 21000000.0,
        "ath": 69045,
        "ath_change_percentage": -56.66339,
        "ath_date": "2021-11-10T14:24:11.849Z",
        "atl": 67.81,
        "atl_change_percentage": 44026.39047,
        "atl_date": "2013-07-06T00:00:00.000Z",
        "roi": null,
        "last_updated": "2023-07-22T10:38:23.000Z",
        "price_change_percentage_7d_in_currency": -1.5209173803027682
    },
    {
        "id": "ethereum",
        "symbol": "eth",
        "name": "Ethereum",
        "image": "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
        "current_price": 1886.89,
        "market_cap": 227142232772,
        "market_cap_rank": 2,
        "fully_diluted_valuation": 227142232772,
        "total_volume": 4993212281,
        "high_24h": 1905.23,
        "low_24h": 1885.91,
        "price_change_24h": -1.4467351254963887,
        "price_change_percentage_24h": -0.07661,
        "market_cap_change_24h": 219953040,
        "market_cap_change_percentage_24h": 0.09693,
        "circulating_supply": 120331160.714735,
        "total_supply": 120331160.714735,
        "max_supply": null,
        "ath": 4878.26,
        "ath_change_percentage": -61.33034,
        "ath_date": "2021-11-10T14:24:19.604Z",
        "atl": 0.432979,
        "atl_change_percentage": 435581.07772,
        "atl_date": "2015-10-20T00:00:00.000Z",
        "roi": {
            "times": 83.39291039204728,
            "currency": "btc",
            "percentage": 8339.291039204729
        },
        "last_updated": "2023-07-22T10:38:16.092Z",
        "price_change_percentage_7d_in_currency": -2.800419536183838
    },
    // {
    //     id: "1",
    //     icon: IMAGES.bitcoin,
    //     name: "Bitcoin",
    //     amount: "$0.0000",
    //     tag: "BTC",
    //     balance: "0,0000335",
    //     data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
    //     gain: "+3.50%",
    // },
    // {
    //     id: "2",
    //     icon: IMAGES.ethereum,
    //     name: "Ethereum",
    //     amount: "$0.0000",
    //     tag: "ETH",
    //     balance: "0,0000335",
    //     data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
    //     gain: "+2.00%",
    // },
    // {
    //     id: "3",
    //     icon: IMAGES.ripple,
    //     name: "Ripple",
    //     amount: "$0.0000",
    //     tag: "BTC",
    //     balance: "0,0000335",
    //     data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
    //     gain: "+3.50%",
    // },
    // {
    //     id: "4",
    //     icon: IMAGES.dash,
    //     name: "Dash",
    //     amount: "$0.0000",
    //     tag: "DASH",
    //     balance: "0,0000335",
    //     data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
    //     gain: "+4.00%",
    // },
    // {
    //     id: "5",
    //     icon: IMAGES.nem,
    //     name: "NEM",
    //     amount: "$0.0000",
    //     tag: "NEM",
    //     balance: "0,0000335",
    //     data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
    //     gain: "+3.50%",
    // },
]

const TopGainers = () => {

    const { colors } = useTheme();
    const theme = useTheme();

    const [gainersData, setGainersData] = React.useState([
        {
            "id": "bitcoin",
            "symbol": "btc",
            "name": "Bitcoin",
            "image": "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
            "current_price": 29890,
            "market_cap": 581157151367,
            "market_cap_rank": 1,
            "fully_diluted_valuation": 627923228894,
            "total_volume": 8100011748,
            "high_24h": 30012,
            "low_24h": 29758,
            "price_change_24h": 91.45,
            "price_change_percentage_24h": 0.30688,
            "market_cap_change_24h": 1900162802,
            "market_cap_change_percentage_24h": 0.32803,
            "circulating_supply": 19435975.0,
            "total_supply": 21000000.0,
            "max_supply": 21000000.0,
            "ath": 69045,
            "ath_change_percentage": -56.66339,
            "ath_date": "2021-11-10T14:24:11.849Z",
            "atl": 67.81,
            "atl_change_percentage": 44026.39047,
            "atl_date": "2013-07-06T00:00:00.000Z",
            "roi": null,
            "last_updated": "2023-07-22T10:38:23.000Z",
            "price_change_percentage_7d_in_currency": -1.5209173803027682
        },
    ]);

    const getCoins = () => {
        fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=20&page=1&sparkline=false&price_change_percentage=7d&locale=en').then((response) => response.json()).then((json) => {
            console.log(json);
            setGainersData(json);
        });
    }

    useEffect(() => {
        getCoins();
    }, [])

    return (
        <View style={{ paddingTop: 15 }}>
            {gainersData.map((data, index) => {
                return (
                    <View
                        key={index}
                    >
                        <View style={[styles.coinList, { backgroundColor: colors.card },
                        theme.dark && {
                            backgroundColor: colors.background,
                            paddingHorizontal: 0,
                        },
                        !theme.dark && {
                            ...GlobalStyleSheet.shadow,
                        }
                        ]}>
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                flex: 1,
                            }}>
                                <View
                                    style={[{
                                        height: 48,
                                        width: 48,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: 12,
                                        backgroundColor: colors.background,
                                        borderWidth: 1,
                                        borderColor: colors.borderColor,
                                        marginRight: 12,
                                    }, theme.dark && {
                                        borderWidth: 0,
                                        backgroundColor: colors.card,
                                    }]}
                                >
                                    <Image
                                        source={data.image}
                                        style={{
                                            height: 26,
                                            width: 26,
                                            borderRadius: 26,
                                        }}
                                    />
                                </View>
                                <View>
                                    <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                                        <Text style={{ ...FONTS.font, fontSize: 15, ...FONTS.fontMedium, color: colors.title }}>{data.name}</Text>
                                        <Text style={{ ...FONTS.fontXs, color: colors.text, marginLeft: 3 }}>({data.symbol})</Text>
                                    </View>
                                    <Text style={{ ...FONTS.fontXs, color: colors.text }}>{data.atl_change_percentage}</Text>
                                </View>
                            </View>
                            {/* <View style={{ width: 50, height: 30, overflow: 'hidden', marginRight: 30 }}>
                                <View style={{ marginLeft: -65, marginTop: -8 }}>
                                    <LineChart
                                        data={{
                                            datasets: [{
                                                data: data.data,
                                                color: (opacity = 1) => COLORS.success,
                                            }]
                                        }}
                                        transparent={true}
                                        width={120} // from react-native
                                        height={30}
                                        withHorizontalLabels={false}
                                        withVerticalLabels={false}
                                        yAxisInterval={1} // optional, defaults to 1
                                        chartConfig={{
                                            strokeWidth: 2,
                                            fillShadowGradientFromOpacity: 0,
                                            fillShadowGradientToOpacity: 0,
                                            decimalPlaces: 2, // optional, defaults to 2dp
                                            color: (opacity = 1) => 'rgba(255,255,255,0)',
                                            labelColor: (opacity = 0) => `rgba(0, 0, 0, ${opacity})`,
                                            propsForBackgroundLines: {
                                                strokeWidth: 0
                                            },
                                            propsForDots: {
                                                r: "0",
                                                strokeWidth: "2",
                                            },
                                        }}
                                    />
                                </View>
                            </View> */}
                            <View
                                style={{
                                    alignItems: 'flex-end',
                                    paddingRight: 5,
                                }}
                            >
                                <Text style={{ ...FONTS.font, ...FONTS.fontMedium, color: colors.title, marginBottom: 5 }}>{data.current_price}</Text>
                                <Text style={{ ...FONTS.fontXs, ...FONTS.fontMedium, color: COLORS.success }}>{data.price_change_percentage_24h}</Text>
                            </View>
                        </View>
                        {theme.dark &&
                            <View
                                start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                colors={["rgba(255,255,255,.0)", "rgba(255,255,255,.1)", "rgba(255,255,255,0)"]}
                                style={{
                                    height: 1,
                                    width: '100%',
                                    bottom: 0,
                                }}
                            >
                            </View>
                        }
                    </View>
                );
            })}
        </View>
    )
}

const styles = StyleSheet.create({
    coinList: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 12,
        paddingHorizontal: 6,
        paddingVertical: 6,
        marginVertical: 4,
        marginHorizontal: 15,
    }
})

export default TopGainers;