import React, { useRef, useState, useEffect } from 'react';

import {
    View,
    StyleSheet,
    Image,
    ScrollView,
    TouchableOpacity,
    Text,
    TextInput,
    ImageBackground,
    Modal,
    Platform
} from 'react-native';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';

import HeaderBar from '../../layout/header';
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import RBSheet from 'react-native-raw-bottom-sheet';
import SearchCoin from '../../components/searchCoin';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import PrivateApi from '../../api/PrivateAPI';
import { RootState } from '../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import * as Animatable from 'react-native-animatable';
import ButtonOutline from '../../components/Button/ButtonOutline';
import OptionBar from '../../components/modal/OptionBar';

const CoinItem = [
    {
        icon: IMAGES.usdt,
        coin: "Tether",
        sortName: "USDT",
    },
    {
        icon: IMAGES.bitcoin,
        coin: "Bitcoin",
        sortName: "BTC",
    },
    {
        icon: IMAGES.ethereum,
        coin: "Etherium",
        sortName: "ETH",
    },
    {
        icon: IMAGES.gdb,
        coin: "Own Token",
        sortName: "BITZ",
    },

    // {
    //     icon : IMAGES.ripple,
    //     coin : 'litherium',
    //     sortName : 'LTC',
    // },
];


const TradeBasic = (props: any) => {
    const { colors } = useTheme();
    const refRBSheet = useRef();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [modalVisible, setModalVisible] = useState(false);
    const [swapodalVisible, setSwapModalVisible] = useState(false);
    const [ItemValue, setItemValue] = useState(CoinItem[0]);
    const [swapValue, setSwapValue] = useState('');
    const [USDTValue, setUSDTValue] = useState('');
    const [alertModalVisible, setAlertModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [swapList, setSwapList] = useState([
        {
            id: '1',
            coin: IMAGES.bitcoin,
            coinName: 'Bitcoin',
            amount: '0.0000',
            trade: '+0.0%',
            tag: "DASH",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000335',
        },
    ]);
    const [swapSelect, setSwapSelectValue] = useState(swapList[0]);
    const [swapFee, setSwapFee] = useState(
        {
            fee: 0.0000,
        }
    );

    const getFee = () => {
        PrivateApi.get('/xy-transaction/swapFee', tokens.user).then((res: any) => {
            console.log('res', res);
            setSwapFee(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setAlertModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);

    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            setUSDTValue(res1.usdt_balance);
            PrivateApi.get('/xy-buy/my', tokens.user).then((res2: any) => {
                console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
                console.log(res2);
                console.log(res1);
                setWalletBalance(res1, res2);
                console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
            }).catch((err) => {
                console.log('err', err);
            });


        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getMyBalance2 = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            setUSDTValue(res1.usdt_balance);
            PrivateApi.get('/xy-buy/my', tokens.user).then((res2: any) => {
                console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
                setSwapValue(res2.claim_x_value);
                console.log(res2);
                console.log(res1);
                setWalletBalance(res1, res2);
                console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
            }).catch((err) => {
                console.log('err', err);
            });


        }).catch((err) => {
            console.log('err', err);
        });
    }

    const setWalletBalance = async (res1: any, res2: any) => {
        console.log("))))))))))))))))))))))))))))))))))))))))))))))))))))))")
        console.log(res1);

        let walletListNew: any = [];

        let objUsdt = {
            id: '1',
            coin: IMAGES.usdt,
            coinName: 'Tether',
            amount: res1.usdt_balance,
            trade: '+0.00%',
            tag: "USDT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objBtc = {
            id: '2',
            coin: IMAGES.bitcoin,
            coinName: 'Bitcoin',
            amount: res1.btc_balance,
            trade: '+0.00%',
            tag: "BTC",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objEth = {
            id: '3',
            coin: IMAGES.ethereum,
            coinName: 'Ethereum',
            amount: res1.eth_balance,
            trade: '+0.00%',
            tag: "ETH",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objSol = {
            id: '4',
            coin: IMAGES.solana,
            coinName: 'Solana',
            amount: res1.solana_balance,
            trade: '+0.00%',
            tag: "SOL",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objBitc = {
            id: '5',
            coin: IMAGES.lun,
            coinName: 'BiTC Token',
            amount: res2.claim_x_value,
            trade: '+0.00%',
            tag: "BiTC",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objFat = {
            id: '6',
            coin: IMAGES.dash,
            coinName: 'FLT Token',
            amount: res2.y_balance,
            trade: '+0.00%',
            tag: "FAT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objOwnI = {
            id: '7',
            coin: IMAGES.gdb,
            coinName: 'NFT Token',
            amount: '0.0000',
            trade: '+0.00%',
            tag: "NFTT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }
        let objOwnII = {
            id: '8',
            coin: IMAGES.etp,
            coinName: 'Native Token',
            amount: '0.0000',
            trade: '+0.00%',
            tag: "NTVT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }


        let dummy = {
            id: '99999',
            coin: IMAGES.nem,
            coinName: 'Tether',
            amount: "0",
            trade: '+0.00%',
            tag: "USDT",
            data: [0, 40, 60, 40, 70, 40, 50, 80, 50, 45, 50, 30, 45],
            btc: '0,0000000'
        }

        await walletListNew.push(objUsdt, objBtc, objEth, objSol, objBitc, objFat, objOwnI, objOwnII);

        setSwapList(walletListNew);

    }

    const saveSwap = () => {

        // model('information-circle-sharp', 'Information', 'Flash exchange under maintaining');

        let obj = {
            "x": swapValue,
        }
        PrivateApi.post('/xy-transaction/swap', tokens.user, obj).then((res: any) => {
            console.log('res', res);
            model('information-circle-sharp', 'Success', 'Successfully swap');
            getMyBalance2();
            // swapValue;
            // setSwapValue('0.0000');
        }).catch((err) => {
            console.log('err', err);
            model('information-circle-sharp', 'Error', 'Unsuccessfully swap');
        });
    }

    useEffect(() => {
        getMyBalance();
        setSwapValue('0.0000');
        getFee();
    }, []);

    return (
        <>
            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                height={SIZES.height}
                openDuration={300}
                customStyles={{
                    wrapper: {
                        //backgroundColor: appTheme.modalBackLayer,
                    },
                    container: {
                        backgroundColor: colors.background,
                    },
                    draggableIcon: {
                        width: 90,
                        height: 0,
                        backgroundColor: colors.borderColor
                    }
                }}
            >

                <SearchCoin refRBSheet={refRBSheet} />

            </RBSheet>

            <Modal
                animationType="slide"
                transparent={true}
                visible={alertModalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setAlertModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <Modal animationType="slide" transparent={true} visible={modalVisible}>
                <View
                    style={[
                        { flex: 1, backgroundColor: colors.card },
                        Platform.OS === "ios" && { paddingTop: 40 },
                    ]}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => setModalVisible(false)}
                            style={{
                                padding: 12,
                            }}
                        >
                            <FeatherIcon name="arrow-left" size={20} color={colors.title} />
                        </TouchableOpacity>
                    </View>
                    <ScrollView>
                        {CoinItem.map((data, index) => (
                            <View key={index}>
                                <TouchableOpacity
                                    onPress={() => {
                                        setItemValue(data);
                                        setModalVisible(false);
                                    }}
                                    style={[
                                        {
                                            flexDirection: "row",
                                            alignItems: "center",
                                            paddingVertical: 12,
                                            paddingHorizontal: 15,
                                        },
                                        ItemValue.coin === data.coin && {},
                                    ]}
                                    disabled={data.coin == 'Tether' ? false : true}
                                >
                                    <Image
                                        style={{
                                            height: 30,
                                            width: 30,
                                            borderRadius: 30,
                                            marginRight: 10,
                                        }}
                                        source={data.icon}
                                    />
                                    <Text
                                        style={{
                                            ...FONTS.font,
                                            ...FONTS.fontMedium,
                                            color: colors.title,
                                            flex: 1,
                                        }}
                                    >
                                        {data.coin}
                                    </Text>
                                    <Text style={{ ...FONTS.fontSm, color: colors.text }}>
                                        {data.sortName}
                                    </Text>
                                </TouchableOpacity>
                                <View
                                    style={{
                                        height: 1,
                                        width: "100%",
                                    }}
                                ></View>
                            </View>
                        ))}
                    </ScrollView>
                </View>
            </Modal>

            <Modal animationType="slide" transparent={true} visible={swapodalVisible}>
                <View
                    style={[
                        { flex: 1, backgroundColor: colors.card },
                        Platform.OS === "ios" && { paddingTop: 40 },
                    ]}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => setSwapModalVisible(false)}
                            style={{
                                padding: 12,
                            }}
                        >
                            <FeatherIcon name="arrow-left" size={20} color={colors.title} />
                        </TouchableOpacity>
                    </View>
                    <ScrollView>
                        {swapList.map((data, index) => (
                            <View key={index}>
                                <TouchableOpacity
                                    onPress={() => {
                                        setSwapSelectValue(data);
                                        setSwapValue(data.amount);
                                        setSwapModalVisible(false);
                                    }}
                                    style={[
                                        {
                                            flexDirection: "row",
                                            alignItems: "center",
                                            paddingVertical: 12,
                                            paddingHorizontal: 15,
                                        },
                                        swapSelect.coinName === data.coinName && {},
                                    ]}
                                    disabled={data.coinName == 'BiTC Token' ? false : true}
                                >
                                    <Image
                                        style={{
                                            height: 30,
                                            width: 30,
                                            borderRadius: 30,
                                            marginRight: 10,
                                        }}
                                        source={data.coin}
                                    />
                                    <Text
                                        style={{
                                            ...FONTS.font,
                                            ...FONTS.fontMedium,
                                            color: colors.title,
                                            flex: 1,
                                        }}
                                    >
                                        {data.coinName}
                                    </Text>
                                    <Text style={{ ...FONTS.fontSm, color: colors.text }}>
                                        {data.tag}
                                    </Text>
                                </TouchableOpacity>
                                <View
                                    style={{
                                        height: 1,
                                        width: "100%",
                                    }}
                                ></View>
                            </View>
                        ))}
                    </ScrollView>
                </View>
            </Modal>

            <View style={{ ...styles.container, backgroundColor: colors.background }}>
                <HeaderBar leftIcon={'back'} title="Trade" />
                <ScrollView contentContainerStyle={{ paddingBottom: 100 }}>

                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',  // Align children to the right
                        alignItems: 'center',       // Center children vertically
                        marginBottom: 20,
                        marginTop: 20,
                        height: 50,
                        borderRadius: 10,

                    }}>
                        <Text style={{ color: colors.text }} >Records</Text>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate('FlashExchange') }}
                            style={{
                                height: '100%',
                                width: 50,  // Adjust width as needed
                                alignItems: 'center',  // Center content horizontally within TouchableOpacity
                                justifyContent: 'center',  // Center content vertically within TouchableOpacity
                            }}
                        >

                            <FeatherIcon name='list' size={22} color={colors.title} />
                        </TouchableOpacity>

                    </View>


                    <View style={{ padding: 15 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Send</Text>
                                    <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        value={swapValue}
                                        onChangeText={(text) => setSwapValue(text)}
                                    // value={swapSelect.amount}
                                    />
                                </View>
                                <TouchableOpacity
                                    onPress={() => setSwapModalVisible(true)}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    <Image
                                        source={swapSelect.coin}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    />
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{swapSelect.coinName}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-down' />
                                </TouchableOpacity>
                            </ImageBackground>
                            <View
                                style={{
                                    alignItems: 'center',
                                    marginVertical: -22,
                                    position: 'relative',
                                    zIndex: 1,
                                }}
                            >
                                <TouchableOpacity
                                    activeOpacity={1}
                                    style={{
                                        height: 50,
                                        width: 50,
                                        backgroundColor: COLORS.primary,
                                        borderRadius: 50,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Image
                                        source={ICONS.trade}
                                        style={{
                                            height: 28,
                                            width: 28,
                                        }}
                                    />
                                </TouchableOpacity>
                            </View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Receive</Text>
                                    <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        // defaultValue='0.0000'
                                        value={USDTValue}
                                    />
                                </View>
                                <TouchableOpacity
                                    onPress={() => setModalVisible(true)}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    <Image
                                        source={ItemValue.icon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    />
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{ItemValue.coin}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-down' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                backgroundColor: colors.card,
                                paddingHorizontal: 15,
                                paddingVertical: 15,
                                borderRadius: SIZES.radius,
                                marginTop: 20,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <Text style={{ flex: 1, ...FONTS.fontSm, color: colors.title }}>Available Balance :</Text>
                            {/* <Text style={{...FONTS.h6,...FONTS.fontMedium,color:colors.title,top:1}}>$31,258.56 BTC</Text> */}
                            <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: colors.title, top: 1 }}>$ {USDTValue}</Text>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                backgroundColor: colors.card,
                                paddingHorizontal: 15,
                                paddingVertical: 15,
                                borderRadius: SIZES.radius,
                                marginTop: 10,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <View style={{
                                flex: 1
                            }}>
                                <Text style={{ ...FONTS.fontSm, color: colors.title, marginBottom: 6 }}>Exchange Fees :</Text>
                                <Text style={{ ...FONTS.fontXs, color: colors.text }}>Read term and conditions</Text>
                            </View>
                            <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: colors.title, top: 1 }}>{swapFee.fee} %</Text>
                        </View>
                        <Animatable.View
                            animation="fadeInUp"
                            duration={300}
                            delay={200}
                            style={{ marginTop: 10 }}
                        >
                            <ButtonOutline
                                // onPress={() => { navigation.navigate('drawernavigation') }}
                                onPress={saveSwap}
                                title="Swap"
                            />
                        </Animatable.View>
                    </View>
                </ScrollView>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})

export default TradeBasic;