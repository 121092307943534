import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Image,
  Modal
} from 'react-native';

// import { VictoryPie } from 'victory-native';
import { VictoryPie } from './chartModules/chart';
import { useNavigation, useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../constants/theme';
import RBSheet from 'react-native-raw-bottom-sheet';
import DepositModal from './modal/depositModal';
import WithdrawModal from './modal/withdrawModal';
// import LinearGradient from 'react-native-linear-gradient';
// import Ripple from 'react-native-material-ripple';
import { GlobalStyleSheet } from '../constants/styleSheet';
import ButtonOutline from './Button/ButtonOutline';
import PrivateApi from '../api/PrivateAPI';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { useIsFocused, useRoute } from '@react-navigation/native';
import OptionBar from '../components/modal/OptionBar';

const categories = [
  {
    id: '1',
    name: 'BTC',
    color: '#49BABE',
    // color: '#E6FF8B',
    percentage: 90,
    balance: '$64,926',
  },
  // {
  //   id: '2',
  //   name: 'ETH',
  //   color: '#AE48D9',
  //   percentage: 24,
  //   balance: '$50,250',
  // },
  // {
  //   id: '3',
  //   name: 'ADA',
  //   color: '#D6538A',
  //   percentage: 16,
  //   balance: '$25,856',
  // },
  // {
  //   id: '4',
  //   name: 'DASH',
  //   color: '#E6FF8B',
  //   percentage: 13,
  //   balance: '$22,700',
  // },
  // {
  //   id: '5',
  //   name: 'EMC',
  //   color: '#84878D',
  //   percentage: 8,
  //   balance: '$15,150',
  // },
]


const WalletWidget = ({ headerTitle, header }) => {

  // const { navigation } = props;
  const navigation = useNavigation();

  const { colors } = useTheme();
  const tokens: any = useSelector((state: RootState) => state.auth);
  const isFocused = useIsFocused();
  const theme = useTheme();
  // const navigation = useNavigation();
  const [endAngle, setEndAngle] = useState(0);
  const refRBSheet = useRef();
  const [walletRBSheet, setWalletRBSheet] = useState('withdraw');
  const [modalVisible, setModalVisible] = useState(false);
  const [modelIcon, setmodelIcon] = useState('');
  const [modelTitle, setmodelTitle] = useState('');
  const [modelDesc, setmodelDesc] = useState('');
  const [btnName, setBtnName] = useState('');
  const [showComponent, setShowComponent] = useState(false);
  const [showEndComponent, setShowEndComponent] = useState(false);


  const [myXYBalance, setMyXYBalance] = useState({
    pool_x_value: "0.0000",
    released_x_value: "0.0000",
    claim_x_value: "0.0000",
    y_balance: "0.0000",
  });

  const [YMiningBalance, setYMiningBalance] = useState({
    id: 0,
    userId: 0,
    yStartAt: "2023-12-03T14:45:50.000Z",
    yEndAt: null,
    yAmount: "0.0000",
    yStatus: 0,
    ecology: "0.0000",
    earned: "0.0000",
    message: ""
  });

  const [xyValues, setXYValues] = useState({
    id: 0,
    x: "0",
    y: "0",
    at: "2023-11-27T03:52:08.000Z",
  })

  const model = (icon: any, title: any, desc: any) => {
    setmodelIcon(icon);
    setmodelTitle(title);
    setmodelDesc(desc);
    setModalVisible(true);
    setTimeout(() => {
      setModalVisible(false);
    }, 3000);
  }

  const getXYValues = () => {
    PrivateApi.get('/xy-value/value', tokens.user).then((res: any) => {
      console.log('res', res);
      setXYValues(res);
    }).catch((err) => {
      console.log('err', err);
    });
  }

  const startYMining = () => {
    PrivateApi.get('/xy-mining/yStart', tokens.user).then((res: any) => {
      console.log('res', res);
      setYMiningBalance(res);
      model('information-circle-sharp', 'Success', 'Successfully start FLT mining process');
      setShowComponent(false);
      setShowEndComponent(true);
    }).catch((err) => {
      console.log('err', err);
    });
  }

  const endYMining = () => {
    PrivateApi.get('/xy-mining/yStop', tokens.user).then((res: any) => {
      console.log('res', res);
      model('information-circle-sharp', 'Success', 'Successfully end FLT mining process');
      let obj = {
        id: 0,
        userId: 0,
        yStartAt: "2023-12-03T14:45:50.000Z",
        yEndAt: null,
        yAmount: "0.0000",
        yStatus: 0,
        ecology: "0.0000",
        earned: "0.0000",
        message: ""
      }
      setYMiningBalance(obj);
      setShowEndComponent(false);
      setShowComponent(true);
    }).catch((err) => {
      console.log('err', err);
    });
  }

  const startYMiningPageLoad = () => {
    PrivateApi.get('/xy-mining/processing', tokens.user).then((res: any) => {
      console.log('res', res);
      if (res.message == 'You have already started Y mining') {
        setYMiningBalance(res);
        setShowComponent(false);
        setShowEndComponent(true);
      }
      else {
        let obj = {
          id: 0,
          userId: 0,
          yStartAt: "2023-12-03T14:45:50.000Z",
          yEndAt: null,
          yAmount: "0.0000",
          yStatus: 0,
          ecology: "0.0000",
          earned: "0.0000",
          message: ""
        }
        setYMiningBalance(obj);
        setShowEndComponent(false);
        setShowComponent(true);
      }
    }).catch((err) => {
      console.log('err', err);
    });
  }

  const getXYBalancesMy = () => {
    PrivateApi.get('/xy-buy/my', tokens.user).then((res: any) => {
      setMyXYBalance(res);
      console.log('yyyyyyyyyyyyyyyyyyyyyyyyy')
      console.log(res);
      console.log('yyyyyyyyyyyyyyyyyyyyyyyyy')
    }).catch((err) => {
      console.log('err', err);
    });
  }

  useEffect(() => {
    getXYBalancesMy();
    getXYValues();
    startYMiningPageLoad();
    setTimeout(() => {
      setEndAngle(360);
    }, 500);
    setTimeout(() => {
      startYMiningPageLoad();
    }, 60000);
  }, [
    isFocused
  ]);


  let chartBalance = categories.map((item) => item.balance);
  let chartData = categories.map((item) => item.percentage);
  let colorScales = categories.map((item) => item.color);
  // const [totalBalance, setTotalBalance] = useState(chartBalance[0]);
  const [totalBalance, setTotalBalance] = useState('0.000');


  return (
    <>
      <RBSheet
        ref={refRBSheet}
        closeOnDragDown={true}
        height={walletRBSheet === 'withdraw' ? 350 :
          walletRBSheet === 'deposit' ? 470 : 470
        }
        openDuration={300}
        customStyles={{
          wrapper: {
            //backgroundColor: appTheme.modalBackLayer,
          },
          container: {
            backgroundColor: colors.background,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
          },
          draggableIcon: {
            width: 90,
            backgroundColor: colors.borderColor
          }
        }}
      >
        {theme.dark &&
          <View
            colors={["rgba(22,23,36,.7)", "rgba(22,23,36,0)"]}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
            }}
          >
          </View>
        }
        {
          (walletRBSheet === 'withdraw') ? <WithdrawModal /> :
            (walletRBSheet === 'deposit') ? <DepositModal /> : <DepositModal />
        }

      </RBSheet>

      <ImageBackground
        source={IMAGES.bg1}
        style={{
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          overflow: 'hidden',
          marginBottom: 20,
        }}
      >
        {/* <View
              style={{
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'space-between',
                marginBottom:-20,
              }}
            >
              <TouchableOpacity
                onPress={() => navigation.goBack()}
                style={{
                  height:48,
                  width:48,
                  alignItems:'center',
                  justifyContent:'center',
                }}
              >
                <FeatherIcon size={22} color={COLORS.white} name='arrow-left'/>
              </TouchableOpacity>
              <Text style={{...FONTS.font,...FONTS.fontMedium,color:COLORS.white}}>Wallet</Text>
              <TouchableOpacity
                onPress={() => navigation.navigate('search')}
                style={{
                  height:48,
                  width:48,
                  alignItems:'center',
                  justifyContent:'center',
                }}
              >
                <FeatherIcon size={20} color={COLORS.white} name='search'/>
              </TouchableOpacity>
            </View> */}

        {header != false &&
          <View
            style={{
              paddingHorizontal: 15,
              paddingVertical: 10,
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              marginBottom: 5,
            }}
          >
            <Text style={{ ...FONTS.h6, color: COLORS.white, flex: 1 }}>{headerTitle ? headerTitle : "Home"}</Text>
            <TouchableOpacity
              onPress={() => navigation.navigate('Notifications')}
              // onPress={() => navigation.navigate('EarnNotification')}
              style={{
                height: 40,
                width: 40,
                borderRadius: SIZES.radius,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255,255,255,.1)',
                marginRight: 6,
              }}
            >
              <Image
                source={ICONS.bell}
                style={{
                  height: 20,
                  width: 20,
                }}
              />
              <View
                style={{
                  height: 5,
                  width: 5,
                  borderRadius: 6,
                  backgroundColor: "#B94646",
                  position: 'absolute',
                  top: 14,
                  right: 13,
                }}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => navigation.openDrawer()}
              style={{
                height: 40,
                width: 40,
                borderRadius: SIZES.radius,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255,255,255,.1)',
              }}
            >
              <FeatherIcon
                name='grid'
                size={20}
                color={COLORS.white}
              />
            </TouchableOpacity>
          </View>
        }

        <View
          style={{
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <VictoryPie
            events={[{
              target: "data",
              eventHandlers: {
                onPress: () => {
                  return [
                    {
                      target: "data",
                      mutation: (props) => {
                        setTotalBalance(chartBalance[props.index]);
                      }
                    }
                  ];
                }
              }
            }]}
            animate={{
              duration: 2000,
            }}
            endAngle={endAngle}
            height={318}
            padAngle={2}
            cornerRadius={10}
            innerRadius={116}
            data={chartData}
            colorScale={colorScales}
            style={{
              data: {
                stroke: colors.card, strokeOpacity: 0, strokeWidth: 10,
              },
              labels: {
                display: 'none',
              }
            }}


          />
          <View
            style={{
              position: 'absolute',
              alignItems: 'center',
            }}
          >
            <Text style={{ ...FONTS.h2, ...FONTS.fontMedium, color: COLORS.white, marginVertical: 5 }}>{myXYBalance.y_balance}</Text>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              <Text style={{ ...FONTS.fontXs, color: COLORS.white, opacity: .6 }}>FLT: {xyValues.y}</Text>
              <Text style={{ ...FONTS.fontXs, ...FONTS.fontMedium, color: COLORS.success, marginLeft: 5 }}></Text>
            </View>
            <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white, marginVertical: 5 }}>Earned : {Number(YMiningBalance.earned).toFixed(2)}</Text>

          </View>
        </View>

        <View
          style={{
            marginBottom: 15,
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: -20,
          }}
        >
          {/* <Ripple
            onPress={() => { setWalletRBSheet('withdraw'), refRBSheet.current.open() }}
            style={{
              paddingHorizontal: 15,
              paddingVertical: 8,
              borderRadius: SIZES.radius,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: 'rgba(255,255,255,.1)',
                height: 50,
                width: 50,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 16,
                marginBottom: 6,
              }}
            >
              <Image
                source={ICONS.withdrawal}
                style={{
                  height: 20,
                  width: 20,
                  tintColor: COLORS.white,
                }}
              />
            </View>
            <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>Withdraw</Text>
          </Ripple> 
          <Ripple
            onPress={() => { setWalletRBSheet('deposit'), refRBSheet.current.open() }}
            style={{
              paddingHorizontal: 15,
              paddingVertical: 8,
              borderRadius: SIZES.radius,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: 'rgba(255,255,255,.1)',
                height: 50,
                width: 50,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 16,
                marginBottom: 6,
              }}
            >
              <Image
                source={ICONS.wallet2}
                style={{
                  height: 20,
                  width: 20,
                  tintColor: COLORS.white,
                }}
              />
            </View>
            <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>Deposit</Text>
          </Ripple>
          <Ripple
            onPress={() => navigation.navigate('Trade')}
            style={{
              paddingHorizontal: 15,
              paddingVertical: 8,
              borderRadius: SIZES.radius,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: 'rgba(255,255,255,.1)',
                height: 50,
                width: 50,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 16,
                marginBottom: 6,
              }}
            >
              <Image
                source={ICONS.transfer}
                style={{
                  height: 22,
                  width: 22,
                  tintColor: COLORS.white,
                }}
              />
            </View>
            <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>Transfer</Text>
          </Ripple> */}

          {/* <View
            style={{
              marginHorizontal: 15,
              marginVertical: 15,
              width: '50%',
            }}
          >
            <CustomButton title='Start' />
          </View> */}

          <View style={[GlobalStyleSheet.col33, { marginBottom: 10 }]}>
            {showComponent &&
              <ButtonOutline onPress={() => startYMining()} title={'Start'} />}
            {showEndComponent &&
              <ButtonOutline onPress={() => endYMining()} title={'Claim FLT'} />}
          </View>

        </View>

        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            marginTop: -25,
            marginBottom: 5
          }}
        >
          <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            marginVertical: 10,
            flexDirection: 'row',
            alignItems: 'center',
          }}>
            <View
              colors={theme.dark ? ["rgba(255,255,255,0)", "rgba(255,255,255,.1)"] : ["rgba(0,0,0,0)", "rgba(0,0,0,.1)"]}
              start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
              style={{
                flex: 1,
                height: 1,
              }}
            >
            </View>
            {/* <Text style={{
              ...FONTS.fontSm,
              paddingHorizontal: 12,
              color: colors.text
            }}>Claim</Text> */}

            {/* <TouchableOpacity
              onPress={() => navigation.navigate('Trade')}
              style={{
                paddingHorizontal: 15,
                paddingVertical: 8,
                borderRadius: SIZES.radius,
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  backgroundColor: COLORS.primary,
                  height: 50,
                  width: 50,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 16,
                  marginBottom: 6,
                }}
              >
                <Image
                  source={ICONS.fingerprint}
                  style={{
                    height: 40,
                    width: 40,
                    tintColor: COLORS.title,
                  }}
                />
              </View>
              <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>CLAIM FLT</Text>
            </TouchableOpacity> */}

            <View
              colors={theme.dark ? ["rgba(255,255,255,.1)", "rgba(255,255,255,0)"] : ["rgba(0,0,0,.1)", "rgba(0,0,0,0)"]}
              start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
              style={{
                flex: 1,
                height: 1,
              }}
            >
            </View>
          </View>
        </View>

        {/* <View style={{
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: -15,
        }}>
          <Ripple
            onPress={() => navigation.navigate('Trade')}
            style={{
              paddingHorizontal: 15,
              paddingVertical: 8,
              borderRadius: SIZES.radius,
              alignItems: 'center',
            }}
          >
            <View
              style={{
                backgroundColor: COLORS.primary,
                height: 50,
                width: 50,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 16,
                marginBottom: 6,
              }}
            >
              <Image
                source={ICONS.fingerprint}
                style={{
                  height: 40,
                  width: 40,
                  tintColor: COLORS.title,
                }}
              />
            </View>
            <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>CLAIM FAT</Text>
          </Ripple>
        </View> */}

      </ImageBackground>
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
      >
        <View style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          position: 'relative',
        }}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => setModalVisible(false)}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(0,0,0,.3)',
            }}
          />
          <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
        </View>
      </Modal>
    </>
  )
}


export default WalletWidget;