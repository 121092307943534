import React, { useRef, useState } from 'react';
import { View, TextInput, StyleSheet, Button, Image, Text, TouchableOpacity, Platform, Alert, Modal } from 'react-native';
import { SIZES, COLORS, IMAGES, FONTS, ICONS } from '../../constants/theme';
import { useTheme } from '@react-navigation/native';
import RBSheet from 'react-native-raw-bottom-sheet';
import { ScrollView } from 'react-native-gesture-handler';
import * as Animatable from 'react-native-animatable';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import FeatherIcon from 'react-native-vector-icons/Feather';
import CustomButton from '../../components/customButton';
import PublicAPI from '../../api/PublicApi';
import EmailVerifySheet from '../../components/ActionSheet/EmailVerifySheet';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { login } from '../../redux/auth/authActions';

import { useDispatch, useSelector } from 'react-redux';
import HeaderBar from '../../layout/header';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import OptionBarWithdraw from '../../components/modal/OptionBarWithdraw';
import OptionBar from '../../components/modal/OptionBar';
import Withdraw from './Withdraw';



const WithdrawOtp = (props: any) => {

    const dispatch = useDispatch();

    const { navigation } = props;

    const theme = useTheme();
    const { colors } = useTheme();

    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [modleType, setmodleType] = useState(false);

    const [password, setPassword] = useState('');
    const [handlePassword, setHandlePassword] = useState(true);


    const tokens: any = useSelector((state: RootState) => state.auth);
    const [otp, setotp] = useState('');
    const [alertModalVisible, setAlertModalVisible] = useState(false);


    const model = (icon: any, title: any, desc: any, type: boolean) => {
        setmodleType(type);
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 20000);

    }

    const doVerify = async () => {

        let storage = await AsyncStorage.getItem("withdraw")
        if (storage != null) {
            const withdraw = JSON.parse(storage);
            let obj = {
                "amount": withdraw.amount,
                "network": withdraw.network,
                "toAddress": withdraw.toAddress,
                "fee": withdraw.fee,
                "freez": withdraw.freez,
                "otp": otp,
                "password": password
            }

            console.log(obj);

            PrivateApi.post('/withdraw/otpVerify', tokens.user, obj).then((res: any) => {
                console.log(res);
                if (res.error == true) {
                    model('information-circle-sharp', 'Error', res.message, false);
                }
                else {
                    model('checkmark-done', 'Please verify the information is correct', 'Address : ' + res.withdraw.toAddress + " \n \n Amount : " + res.withdraw.amount, true);
                }
            }).catch((err) => {
                console.log('err', err);
            });
        } {
            model('information-circle-sharp', 'Info', "Try again", false);
            // props.navigation.navigate('Withdraw');
        }


    }


    const alertDialog = async (title: string, desc: string) => {
        if (Platform.OS === 'web') {
            alert(desc)
        } else {
            Alert.alert(title, desc, [
                { text: "OK" }
            ])
        }
    }



    return (
        <>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    {
                        modleType
                            ? <OptionBarWithdraw title={modelTitle} description={modelDesc} icon={modelIcon} onPress={() => setModalVisible(false)} />
                            : <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                    }


                </View>
            </Modal>




            <View style={{ backgroundColor: COLORS.secondary, flex: 1 }}>

                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>

                    <HeaderBar title="Withdrawal OTP" leftIcon={'back'} />

                    <Animatable.View
                        animation="fadeInUpBig"
                        duration={1000}
                        style={{ paddingTop: 140, flex: 1 }}
                    >
                        {!theme.dark &&
                            <View
                                style={{
                                    height: 30,
                                    backgroundColor: 'rgba(255,255,255,.2)',
                                    left: 20,
                                    right: 20,
                                    position: 'absolute',
                                    top: 114,
                                    borderRadius: 40,
                                }}
                            />}

                        <View
                            style={{
                                ...styles.container,
                                backgroundColor: colors.background,
                                position: 'relative',
                            }}>
                            {theme.dark &&
                                <View

                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                </View>

                            }

                            <View style={{
                                paddingHorizontal: SIZES.padding,
                                paddingTop: 20,
                                flex: 1,
                            }}>

                                <View style={{ ...GlobalStyleSheet.modalBody, flex: 1 }}>
                                    <View
                                        style={{
                                            backgroundColor: colors.card,
                                            ...GlobalStyleSheet.formControl,
                                            ...GlobalStyleSheet.shadow,
                                        }}
                                    >
                                        <TextInput
                                            style={{ ...GlobalStyleSheet.Input, color: colors.text }}
                                            placeholder="Withdrawal Password"
                                            placeholderTextColor={colors.text}
                                            onChangeText={(text) => setPassword(text)}
                                            value={password.toString()}
                                            secureTextEntry={handlePassword}
                                        />


                                    </View>

                                    <View
                                        style={{
                                            backgroundColor: colors.card,
                                            ...GlobalStyleSheet.formControl,
                                            ...GlobalStyleSheet.shadow,
                                        }}
                                    >


                                        <TextInput
                                            style={{ ...GlobalStyleSheet.Input, color: colors.text }}
                                            placeholder="Email OTP"
                                            placeholderTextColor={colors.text}
                                            onChangeText={(text) => setotp(text)}
                                            value={otp.toString()}
                                        />
                                    </View>

                                    <CustomButton title="Withdraw" onPress={() => { doVerify() }} />
                                </View>

                            </View>
                        </View>
                    </Animatable.View>
                </ScrollView>
            </View >
        </>
    );
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderTopLeftRadius: SIZES.radius_lg,
        borderTopRightRadius: SIZES.radius_lg,
        overflow: 'hidden',
        marginTop: -16,
    },
    inputGroup: {
        position: 'relative',
        marginBottom: 15,
    },
    input: {
        height: 48,
        borderWidth: 1,
        borderColor: 'transparent',
        fontSize: SIZES.font,
        borderRadius: SIZES.radius,
        paddingLeft: 50,
    },
    inputActive: {
        borderColor: COLORS.primary,
    },
    inputGroupActive: {
        shadowColor: COLORS.primary,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    inputIco: {
        position: 'absolute',
        left: 17,
        top: 15,
        tintColor: COLORS.primary,
        height: 18,
        width: 18,
        resizeMode: 'contain',
        zIndex: 1,
    },
    seprator: {
        height: 1,
        width: '100%',
        position: 'absolute',
    },
    eyeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    eyeImg: {
        height: 20,
        width: 20,
    },


    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
})
export default WithdrawOtp;
