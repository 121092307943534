import React, { useState } from 'react';

import {
    View,
    Text,
    StyleSheet,
    Image,
    ScrollView,
    TouchableOpacity,
    TextInput,
    ImageBackground,
    Modal,
    View as LinearGradient,
} from 'react-native';

import { FONTS, SIZES, COLORS, ICONS, IMAGES } from '../../constants/theme';

import HeaderBar from '../../layout/header';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useTheme } from '@react-navigation/native';
import PrivateApi from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import * as Clipboard from 'expo-clipboard';
import OptionBar from '../../components/modal/OptionBar';
import SvgQRCode from 'react-native-qrcode-svg';

const socialLink = [
    {
        icon: ICONS.facebook,
    },
    {
        icon: ICONS.whatsapp,
    },
    {
        icon: ICONS.instagram,
    },
    {
        icon: ICONS.twitter,
    },
]

const tableData = [
    {
        num: '#1',
        split: '8%',
        referrals: '3',
        amount: '(0.01 BTC)',
    },
    {
        num: '#2',
        split: '6%',
        referrals: '13',
        amount: '(0.03 BTC)',
    },
    {
        num: '#3',
        split: '3%',
        referrals: '25',
        amount: '(0.02 BTC)',
    },
    {
        num: '#4',
        split: '2%',
        referrals: '37',
        amount: '(0.05 BTC)',
    },
    {
        num: '#5',
        split: '1%',
        referrals: '59',
        amount: '(0.04 BTC)',
    },
]



const Rewards = () => {

    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        nic_number: null,
        ref: "",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });

    const { colors } = useTheme();
    const tokens: any = useSelector((state: RootState) => state.auth);

    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');

    const copyToClipboard = async () => {
        console.log(profile.ref)
        Clipboard.setStringAsync(profile.ref);
        model('checkmark-done', 'Copy', "Copied to clipboard");
    };

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);

    }

    const copyLinkToClipboard = async () => {
        console.log("https://app.bitzwallet.com")
        // ToastAndroid.show('Request sent successfully!', ToastAndroid.SHORT);
        // Toast.show('Copied to clipboard', Toast.SHORT);
        // alert("Copied to clipboard");
        Clipboard.setStringAsync("https://app.bitzwallet.com");
        model('checkmark-done', 'Copy', "Copied to clipboard");
    };



    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }


    React.useEffect(() => {
        getProfile();
    }, []);

    return (
        <>


            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <View style={{ ...styles.container, backgroundColor: colors.background }}>
                <HeaderBar title="Rewards" leftIcon={'back'} />
                <ScrollView>
                    <View style={{ paddingBottom: 30, paddingTop: 20 }}>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={[{
                                borderRadius: SIZES.radius_lg,
                                paddingHorizontal: 18,
                                paddingVertical: 25,
                                marginHorizontal: 15,
                                borderWidth: 1,
                                borderColor: colors.borderColor,
                                overflow: 'hidden',
                                marginBottom: 20
                            }]}
                        >
                            <Text style={{ ...FONTS.font, ...FONTS.fontMedium, color: COLORS.white, marginBottom: 18 }}>Share your referral link and earn crypto when others trade</Text>
                            <View>
                                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, marginBottom: 6 }}>Referral ID</Text>
                                <LinearGradient
                                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                    colors={["rgba(255,255,255,.05)", "rgba(255,255,255,.1)", "rgba(255,255,255,.05)"]}
                                    style={{ borderColor: colors.borderColor, ...GlobalStyleSheet.formControl }}>
                                    <TextInput
                                        style={{ ...GlobalStyleSheet.Input, color: COLORS.white }}
                                        value={profile.ref}
                                    />
                                    <View style={{
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: 18,
                                        top: 12,
                                    }}>
                                        <TouchableOpacity
                                            onPress={() => copyToClipboard()}
                                        >
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: COLORS.primary
                                                }}
                                                source={ICONS.copy} />
                                        </TouchableOpacity>
                                    </View>
                                </LinearGradient>
                            </View>

                            {profile.ref ? <SvgQRCode backgroundColor='transparent' size={300} color='white' value={profile.ref} /> : <></>}

                            <View style={{ marginTop: 20 }}>
                                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, marginBottom: 6 }}>Download Link </Text>
                                <LinearGradient
                                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                    colors={["rgba(255,255,255,.05)", "rgba(255,255,255,.1)", "rgba(255,255,255,.05)"]}
                                    style={{ borderColor: colors.borderColor, ...GlobalStyleSheet.formControl }}>
                                    <TextInput
                                        style={{ ...GlobalStyleSheet.Input, color: COLORS.white }}
                                        value='https://app.bitzwallet.com'
                                    />
                                    <View style={{
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: 18,
                                        top: 12,
                                    }}>
                                        <TouchableOpacity onPress={() => copyLinkToClipboard()}>
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: COLORS.primary
                                                }}
                                                source={ICONS.copy} />
                                        </TouchableOpacity>
                                    </View>
                                </LinearGradient>
                            </View>

                        </ImageBackground>
                    </View>
                    <View 
                    style={{
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Image
                            source={IMAGES.referrel}
                            style={{
                                height: 240,
                                width: 330,
                                borderRadius: 10,
                            }}
                        /></View>

                </ScrollView>
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    socialIcon: {
        height: 35,
        width: 35,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        marginHorizontal: 4,
    }
})


export default Rewards;