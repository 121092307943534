import React from 'react';
import { Text, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import { FONTS, SIZES } from '../../constants/theme';
// import ButtonSm from '../Button/ButtonSm';

const OptionBar = (props: any) => {

    const { colors } = useTheme();

    return (
        <>
            <View style={{
                alignItems: 'center',
                paddingHorizontal: 30,
                paddingVertical: 20,
                paddingBottom: 30,
                backgroundColor: 'dimgrey',
                borderRadius: SIZES.radius,
                marginHorizontal: 30,
                maxWidth: 340,
            }}>
                <Ionicons name="cog" style={{ marginBottom: 8 }} color={'white'} size={60} />
                <Text style={{ ...FONTS.h5, color: "white", marginBottom: 6 }}>Warning</Text>
                <Text style={{ ...FONTS.font, color: "white", textAlign: 'center' }}>Still maintaining</Text>
                <View style={{ flexDirection: 'row', marginTop: 18 }}>
                    {/* <ButtonSm title={'Cancel'} style={{marginRight:10}} color={'#f75a5b'}/>
                    <ButtonSm title={'Continue'} color={'#704FFE'}/> */}
                </View>
            </View>
        </>
    );
};


export default OptionBar;