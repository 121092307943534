import React from "react";
import { Home, Login, Signup, Forget, ChangePassword, Resonance, Profile, BuyPlan, BuyPlan2, BuyPlan3, Browser, EarnNotification, Trade, Rewards } from '../screens';
import { Platform, StatusBar, StyleSheet, View } from "react-native";
import { useTheme } from "@react-navigation/native";
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
// import DrawerNavigation from "./DrawerNavigation";
import BottomNavigation from "./BottomNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import MainNotification from "../screens/home/mainNotification";
import Performance from "../screens/home/performance";
import NewMembers from "../screens/home/newMembers";
import TeamMembers from "../screens/home/teamMembers";
import Verification from "../screens/home/Verification";
import Trc20Add from "../screens/home/Trc20Add";
import VerificationMain from "../screens/home/VerificationMain";
import VerificationNIC from "../screens/home/VerificationNIC";
import ReInvest from "../screens/home/ReInvest";
import Helpdesk from "../screens/home/Helpdesk";
import AmbassadorPerformance from "../screens/home/AmbassadorPerformance";
import RepurchaseNotice from "../screens/home/RepurchaseNotice";
import FlashExchange from "../screens/home/FlashExchange";
import WithdrawalNotifications from "../screens/home/WithdrawalNotifications";
import Withdraw from "../screens/home/Withdraw";
import Deposit from "../screens/home/Deposit";
import WithdrawOtp from "../screens/home/WithdrawOtp";
import DepositNotification from "../screens/home/DepositNotification";
import SystemNotifications from "../screens/home/SystemNotifications";
import EcologyNotification from "../screens/home/claimNotification";
import WfundNotification from "../screens/home/wfundNotification";
import ListRoi from "../screens/home/listRoi";
import ListDirect from "../screens/home/listDirect";
import ListIndirect from "../screens/home/listIndirect";
import ListTeam from "../screens/home/listTeam";
import ListRanking from "../screens/home/listRanking";
import Settings from "../screens/home/settings";


const MyStatusBar = ({ ...props }) => (
    <View style={[styles.statusBar]}>

        <StatusBar translucent {...props} />

    </View>
);

const Stack = createStackNavigator();

export default function AuthNavigator() {
    const { colors } = useTheme();
    const theme = useTheme();

    const { user, error } = useSelector((state: RootState) => state.auth);

    return (
        <View style={[styles.container, { backgroundColor: colors.background }]}>
            <MyStatusBar barStyle={theme.dark ? "light-content" : "dark-content"} />
            <Stack.Navigator initialRouteName="Login"
                screenOptions={{
                    headerShown: false,
                    cardStyle: { backgroundColor: "transparent" },
                    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
                }}
            >
                {user ?
                    <>
                        <Stack.Screen name="bottomnavigation" component={BottomNavigation} />
                        <Stack.Screen name="Resonance" component={Resonance} />
                        <Stack.Screen name="BuyPlan" component={BuyPlan} />
                        <Stack.Screen name="BuyPlan2" component={BuyPlan2} />
                        <Stack.Screen name="BuyPlan3" component={BuyPlan3} />
                        <Stack.Screen name="Browser" component={Browser} />
                        <Stack.Screen name="Swap" component={Trade} />
                        <Stack.Screen name="EarnNotification" component={EarnNotification} />
                        <Stack.Screen name="Notifications" component={MainNotification} />
                        <Stack.Screen name="Performance" component={Performance} />
                        <Stack.Screen name="Rewards" component={Rewards} />
                        <Stack.Screen name="NewMembers" component={NewMembers} />
                        <Stack.Screen name="TeamMembers" component={TeamMembers} />
                        <Stack.Screen name="Verification" component={Verification} />
                        <Stack.Screen name="VerificationMain" component={VerificationMain} />
                        <Stack.Screen name="VerificationNIC" component={VerificationNIC} />
                        <Stack.Screen name="Trc20Add" component={Trc20Add} />
                        <Stack.Screen name="ReInvest" component={ReInvest} />
                        <Stack.Screen name="Helpdesk" component={Helpdesk} />
                        <Stack.Screen name="AmbassadorPerformance" component={AmbassadorPerformance} />
                        <Stack.Screen name="RepurchaseNotice" component={RepurchaseNotice} />
                        <Stack.Screen name="FlashExchange" component={FlashExchange} />
                        <Stack.Screen name="ecologyNotification" component={EcologyNotification} />
                        <Stack.Screen name="wfundNotification" component={WfundNotification} />
                        <Stack.Screen name="WithdrawalNotifications" component={WithdrawalNotifications} />
                        <Stack.Screen name="Withdraw" component={Withdraw} />
                        <Stack.Screen name="Deposit" component={Deposit} />
                        <Stack.Screen name="WithdrawOtp" component={WithdrawOtp} />
                        <Stack.Screen name="DepositNotification" component={DepositNotification} />
                        <Stack.Screen name="SystemNotifications" component={SystemNotifications} />
                        <Stack.Screen name="settings" component={Settings} />

                        <Stack.Screen name="listROI" component={ListRoi} />
                        <Stack.Screen name="listDirect" component={ListDirect} />
                        <Stack.Screen name="listIndirect" component={ListIndirect} />
                        <Stack.Screen name="listTeam" component={ListTeam} />
                        <Stack.Screen name="listRanking" component={ListRanking} />

                    </> : <>
                        <Stack.Screen name="Login" component={Login} />
                        <Stack.Screen name="Sign Up" component={Signup} />
                        <Stack.Screen name="Forget" component={Forget} />
                        <Stack.Screen name="changePassword" component={ChangePassword} />

                    </>
                }
            </Stack.Navigator>
        </View>
    );
}
const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 35 : StatusBar.currentHeight;

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: STATUSBAR_HEIGHT,
    },
    statusBar: {
        height: 0,
    },
});




