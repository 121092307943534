import { combineReducers } from 'redux';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT } from './authActions';

// Initial state
const initialState = {
    isLoading: false,
    user: null,
    error: '',
};

// Reducer
const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: '',
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                user: action.payload,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                isLoading: false,
                error: '',
                user: null
            };
        default:
            return state;
    }
};

export default authReducer;
