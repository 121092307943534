import React from 'react';

import {
    View,
    Text,
    StyleSheet,
    Image,
    FlatList,
    TouchableOpacity as Ripple,
    TouchableOpacity,
    ImageBackground,
    ScrollView,
    ActivityIndicator,
} from 'react-native';

import { useTheme } from '@react-navigation/native';
import HeaderBar from '../../layout/header';
import { FONTS, SIZES, COLORS, ICONS, IMAGES } from '../../constants/theme';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import moment from 'moment';


const TeamMembers = (props: any) => {

    const userId = props.route.params.userId;

    console.log("PROPS ============================ ", userId);

    interface Team {
        id: number;
        userName: string;
        email: string;
        parent: string;
        profileImage: string | null;
        createdAt: string;
    }

    const tokens: any = useSelector((state: RootState) => state.auth);
    const [team, setTeam] = React.useState<Team[]>([]);

    const [isLoading, setIsLoading] = React.useState(true);

    const { colors } = useTheme();

    const getNotification = () => {
        console.log("------------------- GET Notifications -------------------");
        setIsLoading(true);
        PrivateAPI.post('/user/getClosedTeam', tokens.user, { userId }).then((res: any) => {
            setTeam(modifyEmails(res));
            setIsLoading(false);
            loopTeam();
        }).catch((err: any) => {
            console.log(err);
            setIsLoading(false);
        })
    }

    function modifyEmails(users: Team[]): Team[] {
        return users.map(user => {
            const [username, domain] = user.email.split('@');
            const modifiedEmail = username.charAt(0) + username.charAt(1) + username.charAt(2) + '***' + '@' + domain;
            return { ...user, email: modifiedEmail };
        });
    }

    function loopTeam() {
        team.forEach(element => {
            console.log("------------------- TEAM MEMBERS -------------------");
            console.log(element.profileImage);
        });
    }


    React.useEffect(() => {
        setTeam([]);
        getNotification();
    }, [userId])



    return (
        <>
            <View style={{ ...styles.container, backgroundColor: colors.background }}>

                <HeaderBar title="Team Members" leftIcon={'back'} />

                <ScrollView contentContainerStyle={{ paddingBottom: 100 }}>

                    {isLoading ? <ActivityIndicator size="large" color={colors.primary} style={{ marginTop: SIZES.height * 0.4 }} /> :

                        <View style={{ flex: 1 }}>

                            <FlatList
                                data={team}
                                renderItem={({ item }) => (
                                    <TouchableOpacity
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            padding: 15,
                                            borderBottomWidth: 1,
                                            borderColor: colors.borderColor,
                                        }}
                                        onPress={() => {
                                            setTeam([]);
                                            props.navigation.navigate('TeamMembers', { userId: item.id })
                                        }
                                        }
                                    >
                                        <View
                                            style={{
                                                height: 42,
                                                width: 42,
                                                borderRadius: SIZES.radius,
                                                backgroundColor: COLORS.primaryLight,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                marginRight: 10,
                                            }}
                                        >
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    tintColor: COLORS.primary,
                                                    resizeMode: 'contain',
                                                }}
                                                source={ICONS.profile}
                                            />
                                        </View>
                                        <View
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <Text
                                                ellipsizeMode='tail'
                                                numberOfLines={1}
                                                style={{
                                                    ...FONTS.font,
                                                    ...FONTS.fontMedium,
                                                    color: colors.title,
                                                    marginBottom: 4
                                                }}
                                            >{item.userName}</Text>
                                            <Text
                                                ellipsizeMode='tail'
                                                numberOfLines={1}
                                                style={{
                                                    ...FONTS.fontXs,
                                                    color: colors.text,
                                                }}
                                            >Email : {item.email}</Text>
                                        </View>
                                        <View style={{ alignSelf: 'flex-end', width: 60, alignItems: 'flex-end' }}>
                                            <Text style={{ ...FONTS.fontSm, color: COLORS.primary }}>{item.createdAt ? moment(item.createdAt).format('MM-DD-YY hh:mm') : ""}</Text>
                                        </View>
                                    </TouchableOpacity>
                                )}
                            />

                        </View>
                    }

                    {!isLoading && team.length == 0 ?
                        <View style={{ paddingHorizontal: 15, paddingVertical: SIZES.height * 0.4 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <View style={{ flex: 1 }}>
                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>No Members On this Team</Text>
                                        {/* <TextInput
                                                style={{
                                                    ...FONTS.h3,
                                                    color: COLORS.white,
                                                    ...FONTS.fontMedium,
                                                    padding: 0,
                                                }}
                                                defaultValue='0.0000'
                                            /> */}
                                    </View>
                                    <TouchableOpacity
                                        onPress={() => console.log('first')}
                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >
                                        {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                        {/* <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>0</Text> */}
                                        {/* <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-down' /> */}
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View> : ""
                    }




                </ScrollView>


            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})


export default TeamMembers;