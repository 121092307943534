import React from 'react';

import {
    View,
    Text,
    StyleSheet,
    Image,
    FlatList,
    TouchableOpacity as Ripple,
    ActivityIndicator,
} from 'react-native';

import { useTheme } from '@react-navigation/native';
import HeaderBar from '../../layout/header';
import { FONTS, SIZES, COLORS, ICONS } from '../../constants/theme';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import moment from 'moment';


const NewMembers = () => {

    interface Notification {
        id: number;
        userName: string;
        email: string;
        parent: string;
        profileImage: string | null;
        createdAt: string;
    }

    const tokens: any = useSelector((state: RootState) => state.auth);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    const [isLoading, setIsLoading] = React.useState(true);

    const { colors } = useTheme();

    const getNotification = () => {
        console.log("------------------- GET Notifications -------------------");
        PrivateAPI.post('/user/getMyTeam', tokens.user).then((res: any) => {
            console.log(res);
            setNotifications(modifyEmails(res));
            setIsLoading(false);
        }).catch((err: any) => {
            console.log(err);
            setIsLoading(false);
        })
    }

    function modifyEmails(users: Notification[]): Notification[] {
        return users.map(user => {
            const [username, domain] = user.email.split('@');
            const modifiedEmail = username.charAt(0) + username.charAt(1) + username.charAt(2) + '***' + '@' + domain;
            return { ...user, email: modifiedEmail };
        });
    }

    React.useEffect(() => {
        getNotification();
    }, [])



    return (
        <>
            <View style={{ ...styles.container, backgroundColor: colors.background }}>

                <HeaderBar title="New Members" leftIcon={'back'} />
                {isLoading ? <ActivityIndicator size="large" color={colors.primary} style={{ marginTop: SIZES.height * 0.4 }} /> : ""}

                <View style={{ flex: 1 }}>
                    <FlatList
                        data={notifications}
                        renderItem={({ item }) => (
                            <Ripple
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: 15,
                                    borderBottomWidth: 1,
                                    borderColor: colors.borderColor,
                                }}
                            >
                                <View
                                    style={{
                                        height: 42,
                                        width: 42,
                                        borderRadius: SIZES.radius,
                                        backgroundColor: COLORS.primaryLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: 10,
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            tintColor: COLORS.primary,
                                            resizeMode: 'contain',
                                        }}
                                        source={ICONS.profile}
                                    />
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.font,
                                            ...FONTS.fontMedium,
                                            color: colors.title,
                                            marginBottom: 4
                                        }}
                                    >{item.userName}</Text>
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.fontXs,
                                            color: colors.text,
                                        }}
                                    >Email : {item.email}</Text>
                                </View>
                                <View style={{ alignSelf: 'flex-end', width: 60, alignItems: 'flex-end' }}>
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.primary }}>{item.createdAt ? moment(item.createdAt).format('MM-DD-YY hh:mm') : ""}</Text>
                                </View>
                            </Ripple>
                        )}
                    />
                </View>
            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})


export default NewMembers;