import React, { useState } from 'react';

import {
    View,
    Text,
    StyleSheet,
    ScrollView,
    ImageBackground,
    TouchableOpacity,
    Image,
    Linking,
    Switch
} from 'react-native';

import { TabView, SceneMap, TabBar } from 'react-native-tab-view';
import { useTheme } from '@react-navigation/native';
import { FONTS, SIZES, COLORS, IMAGES, ICONS } from '../../constants/theme';

import HeaderBar from '../../layout/header';
import MarketFavourites from '../../components/markets/marketFavourites';
import MarketAllCryptos from '../../components/markets/marketAllCryptos';
import MarketPairs from '../../components/markets/marketPairs';
import MarketNewListing from '../../components/markets/marketNewListing';
import FeatherIcon from 'react-native-vector-icons/Feather';
import MarketSwiper from '../../components/markets/marketSwiper';


const Markets = () => {
    const { colors } = useTheme();

    const [isEnabled, setIsEnabled] = useState(false);
    const toggleSwitch = () => setIsEnabled(previousState => !previousState);

    const Favourites = () => {
        return (
            <MarketFavourites />
        )
    }
    const AllCryptos = () => {
        return (
            <MarketAllCryptos />
        )
    }
    const Pairs = () => {
        return (
            <MarketPairs />
        )
    }
    const NewListing = () => {
        return (
            <MarketNewListing />
        )
    }
    const renderScene = SceneMap({
        Favourites: Favourites,
        AllCryptos: AllCryptos,
        Pairs: Pairs,
        NewListing: NewListing,
    });

    const [index, setIndex] = React.useState(0);
    const [routes] = React.useState([
        { key: 'Favourites', title: 'Favourites' },
        { key: 'AllCryptos', title: 'All Cryptos' },
        { key: 'Pairs', title: 'Pairs' },
        { key: 'NewListing', title: 'New Listing' },
    ]);

    const renderTabBar = props => {
        return (
            <TabBar
                {...props}
                indicatorStyle={{
                    height: 3,
                    backgroundColor: COLORS.primary,
                }}
                style={{
                    backgroundColor: colors.background,
                    elevation: 0,
                    borderBottomWidth: 1,
                    borderBottomColor: colors.borderColor,
                    marginBottom: 15,
                    paddingHorizontal: 15,
                }}
                indicatorContainerStyle={{
                    marginHorizontal: 15,
                }}
                tabStyle={{
                    width: 'auto',
                }}
                renderLabel={({ focused, route }) => {
                    return (
                        <Text
                            style={{
                                ...FONTS.font,
                                ...FONTS.fontMedium,
                                color: focused ? COLORS.primary : colors.text,
                            }}
                        >
                            {route.title}
                        </Text>
                    );
                }}
            />
        );
    };


    return (
        <>
            <View style={{ ...styles.container, backgroundColor: colors.background }}>
                <HeaderBar leftIcon={'back'} title="Markets" />

                <ScrollView
                    contentContainerStyle={{
                        paddingBottom: 300,
                    }}
                >

                    <View style={{ marginTop: 20 }}>
                        <MarketSwiper />
                    </View>

                    <View
                        style={{
                            height: 510
                        }}
                    >

                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }} >
                            <Switch
                                trackColor={{ false: '#767577', true: '#81b0ff' }}
                                thumbColor={isEnabled ? '#f5dd4b' : '#f4f3f4'}
                                ios_backgroundColor="#3e3e3e"
                                onValueChange={toggleSwitch}
                                value={isEnabled}
                            />
                        </View>



                        {/*  Binance */}
                        <View>
                            <Text style={{ ...FONTS.h4, color: COLORS.text, paddingLeft: 15 }}>Exchenges</Text>
                        </View>
                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 50,
                                            // tintColor: colors.text,
                                            marginRight: 14,
                                        }]}
                                        source={ICONS.binance}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Binance</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://www.binance.com/en").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://www.binance.com/en");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>


                        {/*  Hubei */}
                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 35,
                                            // tintColor: colors.text,
                                            marginRight: 30,
                                        }]}
                                        source={ICONS.huobi}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Huobi</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://www.htx.com").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://www.htx.com");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>

                        {/*  Coin base */}
                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 50,
                                            // tintColor: colors.text,
                                            marginRight: 14,
                                        }]}
                                        source={ICONS.coinbase}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Coinbase</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://www.coinbase.com").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://www.coinbase.com");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>

                        <View>
                            <Text style={{ ...FONTS.h4, color: COLORS.text, paddingLeft: 15 }}>DeFi</Text>
                        </View>
                       

                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 50,
                                            tintColor: "#fe007b",
                                            marginRight: 14,
                                        }]}
                                        source={ICONS.uniswap}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Uniswap</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://uniswap.org").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://uniswap.org");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>

                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 50,
                                            // tintColor: "#fe007b",
                                            marginRight: 14,
                                        }]}
                                        source={ICONS.pancakeswap}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Pancake swap</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://pancakeswap.finance").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://pancakeswap.finance");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>

                        <View>
                            <Text style={{ ...FONTS.h4, color: COLORS.text, paddingLeft: 15 }}>NFTs</Text>
                        </View>

                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 50,
                                            // tintColor: "#fe007b",
                                            marginRight: 14,
                                        }]}
                                        source={ICONS.opensea}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Opensea</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://opensea.io").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://opensea.io");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>

                        <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                            <View>
                                <ImageBackground
                                    source={IMAGES.bg1}
                                    style={{
                                        paddingHorizontal: 15,
                                        paddingVertical: 15,
                                        backgroundColor: COLORS.secondary,
                                        borderRadius: SIZES.radius,
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 50,
                                            width: 50,
                                            // tintColor: "#fe007b",
                                            marginRight: 14,
                                        }]}
                                        source={ICONS.blue}
                                    />
                                    <View style={{ flex: 1 }}>

                                        <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Blue</Text>

                                    </View>
                                    <TouchableOpacity
                                        onPress={() => Linking.canOpenURL("https://blur.io").then(supported => {
                                            if (supported) {
                                                Linking.openURL("https://blur.io");
                                            } else {
                                                console.log("Don't know how to open URI: Binance");
                                            }
                                        })}

                                        style={{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            marginBottom: 5,
                                        }}
                                    >


                                        <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: "blue" }}>Visit</Text>
                                        <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                    </TouchableOpacity>
                                </ImageBackground>
                            </View>
                        </View>








                        {/* <TabView
                            swipeEnabled={false}
                            navigationState={{ index, routes }}
                            renderScene={renderScene}
                            onIndexChange={setIndex}
                            initi
                            alLayout={{ width: SIZES.width }}
                            renderTabBar={renderTabBar}
                        /> */}
                    </View>

                </ScrollView >

            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})

export default Markets;