import React, { useState } from 'react';
import { View, Text, ScrollView, TouchableOpacity, TextInput, StyleSheet, Alert, Modal } from 'react-native';

import * as Animatable from 'react-native-animatable';
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { FONTS, SIZES, COLORS, IMAGES } from '../../constants/theme';
import CustomButton from '../../components/customButton';
import { Image } from 'react-native';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import PublicAPI from '../../api/PublicApi';
import AsyncStorage from '@react-native-async-storage/async-storage';
import OptionBar from '../../components/modal/OptionBar';

const Forget = (props: any) => {

    const { navigation } = props;

    const theme = useTheme();
    const { colors } = useTheme();
    const [isFocused, setisFocused] = useState(false);
    const [email, setEmail] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 5000);
    }

    async function handleForgotPassword() {
        try {
            if (email == '') {
                model('information-circle-sharp', 'Error', 'Please enter email');
                console.log('Please enter email')
            }
            else {
                // await forgotPassword();
                console.log('Email:', email);
                const response = await PublicAPI.post('/user/forgot-password', { email: email });
                console.log(response);
                if (response.status === 200) {
                    console.log(response.message);
                    AsyncStorage.setItem('recoveryEmail', JSON.stringify(email))
                    navigation.navigate('changePassword');
                }
                else if (response.status === 403) {
                    model('information-circle-sharp', 'Error', response.message);
                }
                else {
                    model('information-circle-sharp', 'Error', 'email must be an email');
                }
            }
        } catch (error) {
            model('information-circle-sharp', 'Error', 'Error occured');
        }
    }



    return (
        <View style={{ backgroundColor: COLORS.secondary, flex: 1 }}>
            <View style={{
                height: 140,
                backgroundColor: COLORS.secondary,
                position: 'absolute',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}>

                <Image
                    source={IMAGES.logoFullWhite}
                    style={{
                        width: 180,
                        resizeMode: 'contain',
                        marginBottom: 20,
                    }}
                />
            </View>
            <ScrollView
                contentContainerStyle={{ flexGrow: 1 }}
            >
                <Animatable.View
                    animation="fadeInUpBig"
                    duration={1000}
                    style={{ paddingTop: 140, flex: 1 }}>
                    {!theme.dark &&
                        <View
                            style={{
                                height: 30,
                                backgroundColor: 'rgba(255,255,255,.2)',
                                left: 20,
                                right: 20,
                                position: 'absolute',
                                top: 114,
                                borderRadius: 40,
                            }}
                        />
                    }
                    <View
                        style={{
                            ...styles.container,
                            backgroundColor: colors.background,
                            position: 'relative',
                        }}>

                        <View style={{
                            paddingHorizontal: SIZES.padding,
                            paddingTop: 20,
                            flex: 1,
                        }}>
                            <View style={{ alignItems: 'center', paddingTop: 15, marginBottom: 30 }}>
                                <Animatable.Text
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={700}
                                    style={{ ...FONTS.h3, color: colors.title }}>Forgot password</Animatable.Text>
                                <Animatable.Text
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={700}
                                    style={{ ...FONTS.font, color: colors.text }}>Enter your details below</Animatable.Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1000}
                                    style={[styles.inputGroup]}>
                                    <Text style={{ ...FONTS.fontSm, marginBottom: 6 }}>Email</Text>
                                    <View
                                        style={{
                                            ...GlobalStyleSheet.shadow,
                                            backgroundColor: colors.card,
                                            borderRadius: SIZES.radius,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <View style={styles.inputIco}>
                                            <FeatherIcon name='mail' color={COLORS.primary} size={18} />
                                        </View>
                                        <TextInput
                                            onFocus={() => setisFocused(true)}
                                            onBlur={() => setisFocused(false)}
                                            onChangeText={(text) => setEmail(text)}
                                            style={[
                                                styles.input,
                                                {
                                                    color: colors.title,
                                                    backgroundColor: colors.card
                                                },
                                                // isFocused ? styles.inputActive : ""
                                            ]}
                                            placeholderTextColor={colors.text}
                                            placeholder='Enter your email'
                                        />
                                    </View>
                                </Animatable.View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1500}
                                >
                                    <CustomButton
                                        // onPress={() => { navigation.navigate('changePassword') }}
                                        onPress={handleForgotPassword}
                                        title="Continue"
                                    />
                                </Animatable.View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 30 }}>
                                <Text style={{
                                    ...FONTS.font,
                                    marginRight: 5,
                                    color: colors.text,
                                }}>Back to</Text>
                                <TouchableOpacity
                                    onPress={() => navigation.navigate('Login')}
                                >
                                    <Text style={{ ...FONTS.font, color: COLORS.primary }}>Sign in</Text>
                                </TouchableOpacity>
                            </View>

                        </View>
                    </View>

                </Animatable.View>
            </ScrollView>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

        </View>
    );
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderTopLeftRadius: SIZES.radius_lg,
        borderTopRightRadius: SIZES.radius_lg,
        overflow: 'hidden',
        marginTop: -16,
    },
    inputGroup: {
        position: 'relative',
        marginBottom: 15,
    },
    input: {
        height: 48,
        borderWidth: 1,
        borderColor: 'transparent',
        fontSize: SIZES.font,
        borderRadius: SIZES.radius,
        paddingLeft: 50,
    },
    inputActive: {
        borderColor: COLORS.primary,
    },
    inputGroupActive: {
        shadowColor: COLORS.primary,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    inputIco: {
        position: 'absolute',
        left: 17,
        top: 15,
        tintColor: COLORS.primary,
        height: 18,
        width: 18,
        resizeMode: 'contain',
        zIndex: 1,
    },
    seprator: {
        height: 1,
        width: '100%',
        position: 'absolute',
    },
    eyeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    eyeImg: {
        height: 20,
        width: 20,
    }
})

export default Forget;
