import React, { useState } from "react";
import { NavigationContainer } from '@react-navigation/native';
import AuthNavigator from './navigations/AuthNavigator';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import themeContext from "./constants/themeContext";
import {
  DefaultTheme as NavigationDefaultTheme,
  DarkTheme as NavigationDarkTheme
} from '@react-navigation/native';
import { COLORS } from "./constants/theme";
import { applyMiddleware, createStore } from "redux";
import rootReducer from "./redux/rootReducer";
import thunk from "redux-thunk";
import { Provider } from 'react-redux'


export default function App() {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const authContext = React.useMemo(() => ({
    setDarkTheme: () => {
      setIsDarkTheme(true);
    },
    setLightTheme: () => {
      setIsDarkTheme(false);
    }
  }), []);

  const CustomDefaultTheme = {
    ...NavigationDefaultTheme,
    colors: {
      ...NavigationDefaultTheme.colors,
      background: COLORS.background,
      title: COLORS.title,
      card: COLORS.card,
      text: COLORS.text,
      borderColor: COLORS.border,
    }
  }

  const CustomDarkTheme = {
    ...NavigationDarkTheme,
    colors: {
      ...NavigationDarkTheme.colors,
      background: COLORS.darkBackground,
      title: COLORS.darkTitle,
      card: COLORS.darkCard,
      text: COLORS.darkText,
      borderColor: COLORS.darkBorder,
    }
  }

  const store = createStore(rootReducer, applyMiddleware(thunk));

  // const theme = isDarkTheme ? CustomDarkTheme : CustomDefaultTheme;

  const theme = CustomDarkTheme;
  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <themeContext.Provider value={authContext}>
          <NavigationContainer theme={theme}>
            <AuthNavigator />
          </NavigationContainer>
        </themeContext.Provider>
      </Provider>
    </SafeAreaProvider>
  );
}


