import React from 'react';

import {
    View,
    Text,
    StyleSheet,
    Image,
    FlatList,
    TouchableOpacity as Ripple,
} from 'react-native';

import { useTheme } from '@react-navigation/native';
import HeaderBar from '../../layout/header';
import { FONTS, SIZES, COLORS, ICONS } from '../../constants/theme';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import moment from 'moment';


const WithdrawalNotifications = () => {

    const tokens: any = useSelector((state: RootState) => state.auth);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    interface Notification {
        id: number,
        amount: string
        "ecology": string
        "withdraw": string
        "toAddress": string
        "network": string
        "txid": null,
        "type": null,
        "requested": string
        "confirmed": null,
        "fee": string
        "status": number
        "otp": string
        "userId": number
    }

    const { colors } = useTheme();

    const getNotification = () => {
        console.log("------------------- GET Withdrawal Notifications -------------------");
        PrivateAPI.get('/withdraw/byUser', tokens.user).then(async (res: any) => {
            console.log(res);
            // setNotifications(res);
            const direct = await res.filter((notifications: Notification) => notifications.status != 5);
            setNotifications(direct);
        });
    }

    React.useEffect(() => {
        getNotification();
    }, [])



    return (
        <>
            <View style={{ ...styles.container, backgroundColor: colors.background }}>

                <HeaderBar title="Withdrawal Notifications" leftIcon={'back'} />
                <View style={{ flex: 1 }}>
                    <FlatList
                        data={notifications}
                        renderItem={({ item }) => (
                            <Ripple
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: 15,
                                    borderBottomWidth: 1,
                                    borderColor: colors.borderColor,
                                }}
                            >
                                <View
                                    style={{
                                        height: 42,
                                        width: 42,
                                        borderRadius: SIZES.radius,
                                        backgroundColor: COLORS.primaryLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: 10,
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            tintColor: COLORS.primary,
                                            resizeMode: 'contain',
                                        }}
                                        source={ICONS.withdrawal}
                                    />
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.font,
                                            ...FONTS.fontMedium,
                                            color: colors.title,
                                            marginBottom: 4
                                        }}
                                    >Amount : {item.amount}</Text>
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.font,
                                            ...FONTS.fontMedium,
                                            color: 'red',
                                            marginBottom: 4
                                        }}
                                    >Fee : {item.fee}</Text>
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.fontXs,
                                            color: colors.text,
                                        }}
                                    >{item.toAddress}</Text>
                                     <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.fontXs,
                                            color: colors.text,
                                        }}
                                    >Network - {item.network}</Text>
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.fontXs,
                                            color: colors.text,
                                        }}
                                    >Status - {item.status == 0 ? "Pending" : item.otp}</Text>
                                </View>
                                <View style={{ alignSelf: 'flex-end', width: 60, alignItems: 'flex-end' }}>
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.primary }}>{moment(item.requested).format('MM-DD-YY hh:mm')}</Text>
                                </View>
                            </Ripple>
                        )}
                    />
                </View>
            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})


export default WithdrawalNotifications;