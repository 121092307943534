import React, { useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    PermissionsAndroid,
    Platform,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { GlobalStyleSheet } from '../constants/styleSheet';
// import Ripple from 'react-native-material-ripple';
import * as Animatable from 'react-native-animatable';
import ButtonOutline from '../../components/Button/ButtonOutline';
import CustomButton from '../../components/customButton';


const Resonance = (props: any) => {

    const { colors } = useTheme();
    const [imgUrl, setImgUrl] = useState(null);


    return (
        <View
            style={{
                flex: 1,
                backgroundColor: colors.background,
            }}
        >
            <HeaderBar
                leftIcon={'back'}
                title={"Resonance"}
            />
            <ScrollView
                contentContainerStyle={{
                    paddingBottom: 100,
                }}
            >

                <Animatable.View
                    animation="fadeInLeft"
                    duration={1000}
                    delay={500}
                >
                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('BuyPlan')}
                        style={{
                            padding: 15,
                        }}
                    >
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                borderColor: COLORS.primary,
                                borderWidth: 1,
                                paddingVertical: 18,
                                borderRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 300,
                            }}
                        >
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        // alignItems: 'center',

                                        marginBottom: 3,
                                    }}
                                >
                                    <Text style={{ ...FONTS.h2, color: COLORS.primary }}>Lower Node</Text>
                                    <FeatherIcon style={{ marginLeft: 6 }} color={colors.text} size={18} name='arrow-up-right' />
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h3, color: COLORS.white, opacity: .6 }}>100 BiTC - 599 BiTC</Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h4, color: COLORS.white, opacity: .6 }}>Unlock your financial potential
                                    </Text>
                                </View>


                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>3x leveraged token pool
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>Only access for elite
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>Unfixed ROI untill 20%
                                    </Text>
                                </View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1500}
                                    style={{
                                        marginTop: 20,
                                    }}

                                >
                                    <CustomButton
                                        width={300}
                                        onPress={() => props.navigation.navigate('BuyPlan')}
                                        title="     Get Started     "
                                    />
                                </Animatable.View>



                            </View>

                        </ImageBackground>
                    </TouchableOpacity>
                </Animatable.View>


                <Animatable.View
                    animation="fadeInRight"
                    duration={1000}
                    delay={500}
                >
                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('BuyPlan2')}
                        style={{
                            padding: 15,
                        }}
                    >
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                borderColor: COLORS.white,
                                borderWidth: 1,
                                paddingVertical: 18,
                                borderRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 300,
                            }}
                        >
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 3,
                                    }}
                                >
                                    <Text style={{ ...FONTS.h2, color: COLORS.white }}>Middle node</Text>
                                    <FeatherIcon style={{ marginLeft: 6 }} color={colors.text} size={18} name='arrow-up-right' />
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h3, color: COLORS.white, opacity: .6 }}>600 BiTC - 2999 BiTC</Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h4, color: COLORS.white, opacity: .6 }}>Unlock your financial potential
                                    </Text>
                                </View>


                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>3.25x leveraged token pool
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>Only access for elite and premium
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>Unfixed ROI Untill 33%
                                    </Text>
                                </View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1500}
                                    style={{
                                        marginTop: 20,
                                    }}

                                >
                                    <CustomButton
                                        width={300}
                                        onPress={() => props.navigation.navigate('BuyPlan2')}
                                        title="     Get Started     "
                                    />
                                </Animatable.View>

                            </View>

                        </ImageBackground>
                    </TouchableOpacity>
                </Animatable.View>

                <Animatable.View
                    animation="fadeInLeft"
                    duration={1000}
                    delay={500}
                >
                    <TouchableOpacity
                        onPress={() => props.navigation.navigate('BuyPlan3')}
                        style={{
                            padding: 15,
                        }}
                    >
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                borderColor: COLORS.yellow,
                                borderWidth: 1,
                                paddingVertical: 18,
                                borderRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 300,
                            }}
                        >
                            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, }}>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 3,
                                    }}
                                >
                                    <Text style={{ ...FONTS.h2, color: COLORS.yellow }}>Upper Node</Text>
                                    <FeatherIcon style={{ marginLeft: 6 }} color={colors.text} size={18} name='arrow-up-right' />
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h3, color: COLORS.white, opacity: .6 }}>3000 BiTC and above</Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h4, color: COLORS.white, opacity: .6 }}>Unlock your financial potential
                                    </Text>
                                </View>


                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>4x leveraged token pool
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>Access to all features
                                    </Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='external-link' /> */}
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .6 }}>Unfixed ROI untill 45%
                                    </Text>
                                </View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1500}
                                    style={{
                                        marginTop: 20,
                                    }}

                                >
                                    <CustomButton
                                        width={300}
                                        onPress={() => props.navigation.navigate('BuyPlan3')}
                                        title="     Get Started     "
                                    />
                                </Animatable.View>


                            </View>
                        </ImageBackground>
                    </TouchableOpacity>
                </Animatable.View>



                <Animatable.View
                    animation="fadeInUp"
                    duration={1500}
                    delay={500}
                >
                    <TouchableOpacity style={{
                        padding: 15,
                    }}>
                        <ImageBackground
                            source={IMAGES.Resonance_bg}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                paddingVertical: 18,
                                // borderRadius: SIZES.radius_lg,
                                borderTopLeftRadius: SIZES.radius_lg,
                                borderTopRightRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 150,
                                opacity: .9
                            }}
                        >
                        </ImageBackground>
                        <View style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: 20,
                            backgroundColor: COLORS.primaryLight,
                            height: 80,
                            borderBottomLeftRadius: SIZES.radius_lg,
                            borderBottomRightRadius: SIZES.radius_lg,
                            // ...GlobalStyleSheet.shadow,
                        }}>
                            <View style={{ alignItems: 'center', }}>
                                <ButtonOutline title={'Redeem'}

                                />
                            </View>
                            <View style={{ alignItems: 'center', }}>
                                <ButtonOutline title={'Reinvest'}
                                    onPress={() => props.navigation.navigate('ReInvest')}
                                />
                            </View>
                        </View>
                    </TouchableOpacity>

                </Animatable.View>

            </ScrollView>
        </View>
    )
}

export default Resonance