import React, { useRef, useState } from 'react';
import {
    View,
    StyleSheet,
    Image,
    ScrollView,
    TouchableOpacity,
    Text,
    TextInput,
    ImageBackground,
    Animated,
    ActivityIndicator
} from 'react-native';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';

import HeaderBar from '../../layout/header';
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Swiper from 'react-native-swiper/src';
import RBSheet from 'react-native-raw-bottom-sheet';
import SearchCoin from '../../components/searchCoin';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

const marketData = [
    {
        id: '1',
        title: 'Hot Coin #1',
        icon: IMAGES.s1,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '2',
        title: 'Hot Coin #2',
        icon: IMAGES.s2,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s3,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s4,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s5,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s6,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s7,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s8,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s9,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    {
        id: '3',
        title: 'Hot Coin #3',
        icon: IMAGES.s10,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
]




const Browser = (props: any) => {

    const { colors } = useTheme();
    const refRBSheet = useRef();
    const tokens: any = useSelector((state: RootState) => state.auth);

    const [users, setUsers] = React.useState<any>([]);
    const [x_sum, setX_sum] = React.useState<any>({
        "X_SUM": "0.0000",
        "userId": 0,
        "type": "ROI"
    });

    const [x_direct, setX_direct] = React.useState<any>({
        "X_SUM": "0.0000",
        "userId": 0,
        "type": "ROI"
    });

    const [x_Indirect, setIn_direct] = React.useState<any>({
        "X_SUM": "0.0000",
        "userId": 0,
        "type": "ROI"
    });

    const [x_Team, setX_Team] = React.useState<any>({
        "X_SUM": "0.0000",
        "userId": 0,
        "type": "ROI"
    });

    const [missing, setMissing] = React.useState<any>({
        missing_sum: "0.0000",
        user_id: 0,
    });

    const [totalReward, setTotalReward] = React.useState(0.0000);

    const [tot, setTot] = React.useState(0.0000);

    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        id: 1,
        mobile: null,
        profileImage: "",
        parent: null,
        "ref": "",
        "status": 0,
        "type": "0",
        "verification": ""
    });

    const [rankBedge, setRankBedge] = useState(IMAGES.rank1);

    const [xyBuy, setXyBuy] = React.useState<any>({
        "id": 0,
        "userId": 0,
        "parent_userId": 0,
        "parent_xyBuyId": 0,
        "direct_team_count": 0,
        "total_team_investment": "0.0000",
        "rank": 0,
        "activated_at": "",
        "plan_id": 0,
        "invested_x_value": "0.0000",
        "pool_x_value": "0.0000",
        "released_x_value": "0.0000",
        "claim_x_value": "0.0000",
        "y_balance": "0.0000",
        "ecology": "0.0000",
        "status": 0,
        "ranking": 0,
    });

    const getNotification = () => {
        console.log("------------------- GET Notifications -------------------");
        PrivateAPI.post('/user/getMyTeam', tokens.user).then((res: any) => {
            setUsers(res);
        });
    }

    const getProfile = () => {
        PrivateAPI.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getMyXyBuyRow = () => {
        PrivateAPI.get('/xy-buy/my', tokens.user).then((res: any) => {
            setXyBuy(res);

            const rank = Number(res.ranking);

            switch (rank) {
                case 0:
                    setRankBedge(IMAGES.rank0);
                    break;
                case 1:
                    setRankBedge(IMAGES.rank1);
                    break;
                case 2:
                    setRankBedge(IMAGES.rank2);
                    break;
                case 3:
                    setRankBedge(IMAGES.rank3);
                    break;
                case 4:
                    setRankBedge(IMAGES.rank4);
                    break;
                case 5:
                    setRankBedge(IMAGES.rank5);
                    break;
                case 6:
                    setRankBedge(IMAGES.rank6);
                    break;
                case 7:
                    setRankBedge(IMAGES.rank7);
                    break;
                case 8:
                    setRankBedge(IMAGES.rank8);
                    break;
                case 9:
                    setRankBedge(IMAGES.rank9);
                    break;
                case 10:
                    setRankBedge(IMAGES.rank10);
                    break;
            }

        }).catch((err) => {
            console.log('err', err);
        });
    }

    const xSumByType = (type: string) => {
        PrivateAPI.post('/xy-buy/xSumByType', tokens.user, { type: type }).then((res: any) => {
            console.log('res', res);
            setX_sum(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    const xSumByTypeDirect = (type: string) => {
        PrivateAPI.post('/xy-buy/xSumByType', tokens.user, { type: type }).then((res: any) => {
            console.log('res', res);
            setX_direct(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    const xSumByTypeIndirect = (type: string) => {
        PrivateAPI.post('/xy-buy/xSumByType', tokens.user, { type: type }).then((res: any) => {
            console.log('res', res);
            setIn_direct(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    const xSumByTypeTeam = (type: string) => {
        PrivateAPI.post('/xy-buy/xSumByType', tokens.user, { type: type }).then((res: any) => {
            console.log('res', res);
            setX_Team(res);
        }).catch((err) => {
            console.log(err);
        })
    }

    const totalQumilative = () => {
        const tt = Number(x_sum.X_SUM) + Number(x_direct.X_SUM) + Number(x_Indirect.X_SUM) + Number(x_Team.X_SUM);
        setTot(tt);
    }

    const getMissing = () => {
        console.log("------------------- GET Missing -------------------");
        PrivateAPI.get('/xy-missing/byUser', tokens.user).then((res: any) => {
            console.log('res', res);
            setMissing(res);
        }).catch((err) => {
            console.log(err);
        })
    }


    const getTotalReward = () => {
        console.log("------------------- GET Missing -------------------");
        PrivateAPI.get('/xy-missing/totalRankingReward', tokens.user).then((res: any) => {
            console.log('totalRankingReward  ', res);
            if (res.totReward) {
                setTotalReward(res.totReward);
            }
        }).catch((err) => {
            console.log(err);
        })
    }



    React.useEffect(() => {
        getNotification();
        getProfile();
        getMyXyBuyRow();
        xSumByType("ROI")
        xSumByTypeDirect('Direct Income');
        xSumByTypeIndirect('Indirect Income');
        xSumByTypeTeam('Team Income');
        getMissing();
        getTotalReward();
    }, [])

    React.useEffect(() => {
        totalQumilative();
    }, [
        x_sum, x_direct, x_Indirect, x_Team
    ])

    return (
        <>
            <View style={{ ...styles.container, backgroundColor: colors.background }}>
                <HeaderBar leftIcon={'back'} title="Records" />
                <ScrollView contentContainerStyle={{ paddingBottom: 100 }}>



                    <View style={{ marginTop: 10 }}>
                        <Swiper
                            showsButtons={false}
                            style={{ height: 200 }} loop={true}
                            dotStyle={{
                                backgroundColor: "#DDDDDD",
                                height: 0,
                                width: 0,
                            }}
                            activeDotStyle={{
                                backgroundColor: colors.primary,
                                width: 0,
                                height: 0,
                            }}

                        >
                            {marketData.map((data, index) => {
                                return (
                                    <View
                                        key={index}
                                        start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                        colors={['#00BA87', '#1AA780']}
                                        style={{ padding: 15, borderRadius: SIZES.radius, height: '100%', width: '100%', overflow: 'hidden' }}
                                    >
                                        <ImageBackground
                                            source={data.icon}
                                            style={{
                                                paddingHorizontal: 15,
                                                paddingVertical: 15,
                                                backgroundColor: COLORS.secondary,
                                                borderRadius: SIZES.radius,
                                                flexDirection: 'row',
                                                alignItems: 'flex-end',
                                                overflow: 'hidden',
                                                height: '100%',
                                            }}
                                        ></ImageBackground>
                                    </View>
                                )
                            })}

                        </Swiper>
                    </View>




                    <View style={{ padding: 15 }}>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                paddingVertical: 18,
                                borderRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                            }}
                        >
                            {/* <View style={{ marginRight: 18, borderWidth: 3, borderRadius: 80, borderColor: 'rgba(255,255,255,.1)' }}>
                                <Image
                                    source={IMAGES.pic1}
                                    style={{
                                        height: 80,
                                        width: 80,
                                        borderRadius: 80,
                                    }}
                                />
                                <TouchableOpacity
                                    onPress={() => { }}
                                    activeOpacity={.9}
                                    style={{
                                        height: 28,
                                        width: 28,
                                        position: 'absolute',
                                        backgroundColor: COLORS.primary,
                                        borderRadius: 28,
                                        bottom: 0,
                                        right: 0,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <FeatherIcon size={14} color={COLORS.white} name='edit' />
                                </TouchableOpacity>
                            </View> */}
                            <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                                <Text style={{ ...FONTS.h6, color: COLORS.white, marginBottom: 7 }}>Cumulative (BiTC)</Text>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 3,
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='mail' /> */}
                                    <Text style={{ ...FONTS.h3, color: COLORS.white, opacity: 1 }}>{Number(tot).toFixed(4)}</Text>
                                </View>
                                <TouchableOpacity
                                    onPress={() => { }}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <FeatherIcon style={{ marginRight: 6 }} color={colors.text} size={14} name='copy' /> */}
                                    {/* <Text style={{ ...FONTS.fontSm, color: COLORS.white, opacity: .6 }}>7.2345</Text> */}
                                </TouchableOpacity>
                            </View>
                        </ImageBackground>
                    </View>



                    {/* Ambassador */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Ambassador</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => console.log('first')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    <Image
                                        source={IMAGES.logoICON}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 0,
                                            marginRight: 10,
                                        }}
                                    />
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{xyBuy.direct_team_count}</Text>
                                    {/* <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-down' /> */}
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>

                    {/* Total Community Members */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Total Community Members</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => console.log('first')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{users.length}</Text>
                                    {/* <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' /> */}
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>


                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Business Performance</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => console.log('first')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{xyBuy.total_team_investment} BitC</Text>
                                    {/* <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' /> */}
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>

                    {/* Community Achiever  */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Community Achiever</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => console.log('first')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    <Image
                                        source={rankBedge}
                                        style={{
                                            height: 35,
                                            width: 35,
                                            borderRadius: 0,
                                            marginRight: 10,
                                        }}
                                    />
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{xyBuy.ranking}</Text>
                                    {/* <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' /> */}
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>

                    {/* Community Member Details */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Community Member Details</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => props.navigation.navigate('TeamMembers', { userId: profile.id })}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{users.length}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>


                    {/* Total ROI */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Total ROI</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => props.navigation.navigate('listROI')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{x_sum.X_SUM}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>

                    {/* Direct Income */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Direct Income</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => props.navigation.navigate('listDirect')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{x_direct.X_SUM}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>


                    {/* Indirect Income */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Indirect Income</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => props.navigation.navigate('listIndirect')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{x_Indirect.X_SUM}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>

                    {/* Team Income */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Team Income</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => props.navigation.navigate('listTeam')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{x_Team.X_SUM}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>



                    {/* Missing  Rewards  */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.danger, opacity: .7, marginBottom: 8, marginTop: 5 }}>Missing Rewards</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => console.log('first')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.danger }}>{missing.missing_sum}</Text>
                                    {/* <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' /> */}
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>


                    {/* Ranking Rewards  Rewards  */}
                    <View style={{ paddingHorizontal: 15, paddingVertical: 5 }}>
                        <View>
                            <ImageBackground
                                source={IMAGES.bg1}
                                style={{
                                    paddingHorizontal: 15,
                                    paddingVertical: 15,
                                    backgroundColor: COLORS.secondary,
                                    borderRadius: SIZES.radius,
                                    flexDirection: 'row',
                                    alignItems: 'flex-end',
                                    overflow: 'hidden',
                                }}
                            >
                                <View style={{ flex: 1 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white, opacity: .7, marginBottom: 8, marginTop: 5 }}>Ranking Rewards</Text>
                                    {/* <TextInput
                                        style={{
                                            ...FONTS.h3,
                                            color: COLORS.white,
                                            ...FONTS.fontMedium,
                                            padding: 0,
                                        }}
                                        defaultValue='0.0000'
                                    /> */}
                                </View>
                                <TouchableOpacity
                                    onPress={() => props.navigation.navigate('listRanking')}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBottom: 5,
                                    }}
                                >
                                    {/* <Image
                                        source={IMAGES.logoIcon}
                                        style={{
                                            height: 24,
                                            width: 24,
                                            borderRadius: 24,
                                            marginRight: 5,
                                        }}
                                    /> */}
                                    <Text style={{ ...FONTS.h6, ...FONTS.fontMedium, color: COLORS.white }}>{totalReward}</Text>
                                    <FeatherIcon size={16} style={{ opacity: .6, marginLeft: 5 }} color={COLORS.white} name='chevron-right' />
                                </TouchableOpacity>
                            </ImageBackground>
                        </View>
                    </View>



                </ScrollView>
            </View>


        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})

export default Browser;