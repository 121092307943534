import React from 'react';

import {
    View,
    Text,
    StyleSheet,
    Image,
    ScrollView,
    TouchableOpacity,
    TextInput,
    ImageBackground,
    Modal,
    View as LinearGradient,
    Linking,
} from 'react-native';

import { FONTS, SIZES, COLORS, ICONS, IMAGES } from '../../constants/theme';

import HeaderBar from '../../layout/header';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useTheme } from '@react-navigation/native';
import PrivateApi from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import * as Clipboard from 'expo-clipboard';

const socialLink = [
    {
        icon: ICONS.facebook,
    },
    {
        icon: ICONS.whatsapp,
    },
    {
        icon: ICONS.instagram,
    },
    {
        icon: ICONS.twitter,
    },
]

const tableData = [
    {
        num: '#1',
        split: '8%',
        referrals: '3',
        amount: '(0.01 BTC)',
    },
    {
        num: '#2',
        split: '6%',
        referrals: '13',
        amount: '(0.03 BTC)',
    },
    {
        num: '#3',
        split: '3%',
        referrals: '25',
        amount: '(0.02 BTC)',
    },
    {
        num: '#4',
        split: '2%',
        referrals: '37',
        amount: '(0.05 BTC)',
    },
    {
        num: '#5',
        split: '1%',
        referrals: '59',
        amount: '(0.04 BTC)',
    },
]



const SystemNotifications = () => {

    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        nic_number: null,
        ref: "",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });

    const { colors } = useTheme();
    const tokens: any = useSelector((state: RootState) => state.auth);

    const copyToClipboard = async () => {
        console.log(profile.ref)
        Clipboard.setStringAsync(profile.ref);
    };

    const copyLinkToClipboard = async () => {
        console.log("https://app.bitzwallet.com")
        Clipboard.setStringAsync("https://app.bitzwallet.com");
    };



    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const openLinkInBrowser = () => {
        const url = "https://t.me/Bitzsupport";
        Linking.openURL(url).catch((err) =>
            console.error('An error occurred', err)
        );

    }


    React.useEffect(() => {
        getProfile();
    }, []);

    return (
        <>


            <View style={{ ...styles.container, backgroundColor: colors.background }}>
                <HeaderBar title="System update News" leftIcon={'back'} />
                <ScrollView>

                    <View style={{ paddingBottom: 30, paddingTop: 20 }}>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={[{
                                borderRadius: SIZES.radius_lg,
                                paddingHorizontal: 18,
                                paddingVertical: 25,
                                marginHorizontal: 15,
                                borderWidth: 1,
                                borderColor: colors.borderColor,
                                overflow: 'hidden',
                                marginBottom: 20
                            }]}
                        >
                            <Text style={{ ...FONTS.font, ...FONTS.h3, color: COLORS.white, marginBottom: 18 }} >
                                Announcement (13th may 2024)
                            </Text>
                            <Text style={{ ...FONTS.font, ...FONTS.h6, color: COLORS.white, marginBottom: 18 }}>
                                Bitzwallet project is undergoing an update from 13-05-2024 to 14-05-2024, so if you face any inconvenience during this time, we express our deep regret and hope to provide faster service.
                            </Text>
                            <View>
                                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, marginBottom: 6 }}>Chat Link</Text>
                                <LinearGradient
                                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                    colors={["rgba(255,255,255,.05)", "rgba(255,255,255,.1)", "rgba(255,255,255,.05)"]}
                                    style={{ borderColor: colors.borderColor, ...GlobalStyleSheet.formControl }}>
                                    <TextInput
                                        style={{ ...GlobalStyleSheet.Input, color: COLORS.white }}
                                        value='https://app.bitzwallet.com'
                                    />
                                    <View style={{
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: 18,
                                        top: 12,
                                    }}>
                                        <TouchableOpacity onPress={() => openLinkInBrowser()}>
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: COLORS.primary
                                                }}
                                                source={ICONS.send} />
                                        </TouchableOpacity>
                                    </View>
                                </LinearGradient>
                            </View>
                        </ImageBackground>
                    </View>

                    <View style={{ paddingBottom: 30, paddingTop: 20 }}>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={[{
                                borderRadius: SIZES.radius_lg,
                                paddingHorizontal: 18,
                                paddingVertical: 25,
                                marginHorizontal: 15,
                                borderWidth: 1,
                                borderColor: colors.borderColor,
                                overflow: 'hidden',
                                marginBottom: 20
                            }]}
                        >
                            <Text style={{ ...FONTS.font, ...FONTS.h3, color: COLORS.white, marginBottom: 18 }} >

                                Announcement (8th may 2024)


                            </Text>
                            <Text style={{ ...FONTS.font, ...FONTS.h6, color: COLORS.white, marginBottom: 18 }}>
                                All unverified accounts will be suspended within twenty-eight days from the beginning date.(08/05/2024- 06/06/2024)
                            </Text>
                            <Text style={{ ...FONTS.font, ...FONTS.h6, color: COLORS.white, marginBottom: 18 }}>Stay confident</Text>
                            <Text style={{ ...FONTS.font, ...FONTS.h6, color: COLORS.white, marginBottom: 18 }}>
                                Thank you

                            </Text>


                            <View>
                                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, marginBottom: 6 }}>Chat Link</Text>
                                <LinearGradient
                                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                    colors={["rgba(255,255,255,.05)", "rgba(255,255,255,.1)", "rgba(255,255,255,.05)"]}
                                    style={{ borderColor: colors.borderColor, ...GlobalStyleSheet.formControl }}>
                                    <TextInput
                                        style={{ ...GlobalStyleSheet.Input, color: COLORS.white }}
                                        value='https://app.bitzwallet.com'
                                    />
                                    <View style={{
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: 18,
                                        top: 12,
                                    }}>
                                        <TouchableOpacity onPress={() => openLinkInBrowser()}>
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: COLORS.primary
                                                }}
                                                source={ICONS.send} />
                                        </TouchableOpacity>
                                    </View>
                                </LinearGradient>
                            </View>
                        </ImageBackground>
                    </View>

                    <View style={{ paddingBottom: 30, paddingTop: 20 }}>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={[{
                                borderRadius: SIZES.radius_lg,
                                paddingHorizontal: 18,
                                paddingVertical: 25,
                                marginHorizontal: 15,
                                borderWidth: 1,
                                borderColor: colors.borderColor,
                                overflow: 'hidden',
                                marginBottom: 20
                            }]}
                        >
                            <Text style={{ ...FONTS.font, ...FONTS.h3, color: COLORS.white, marginBottom: 18 }} >

                                Announcement (29th friday 2024)

                            </Text>
                            <Text style={{ ...FONTS.font, ...FONTS.h6, color: COLORS.white, marginBottom: 18 }}>

                                These days fees are determined by the amount of network traffic, supply of validators and demand for transaction verification. The higher the demand and the higher the traffic, the higher the fees will be. Therefore, there will be updates in Bitzwallet on 30/03/2024, 31/03/2024 (UTC) to provide a closer service to users.You will have to face difficulties in depositing and withdrawing. We express our deep regret if you face any inconvenience and kindly seek the assistance of the support team in such cases.

                            </Text>


                            <View>
                                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, marginBottom: 6 }}>Chat Link</Text>
                                <LinearGradient
                                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                    colors={["rgba(255,255,255,.05)", "rgba(255,255,255,.1)", "rgba(255,255,255,.05)"]}
                                    style={{ borderColor: colors.borderColor, ...GlobalStyleSheet.formControl }}>
                                    <TextInput
                                        style={{ ...GlobalStyleSheet.Input, color: COLORS.white }}
                                        value='https://app.bitzwallet.com'
                                    />
                                    <View style={{
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: 18,
                                        top: 12,
                                    }}>
                                        <TouchableOpacity onPress={() => openLinkInBrowser()}>
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: COLORS.primary
                                                }}
                                                source={ICONS.send} />
                                        </TouchableOpacity>
                                    </View>
                                </LinearGradient>
                            </View>
                        </ImageBackground>
                    </View>

                    <View style={{ paddingBottom: 30, paddingTop: 20 }}>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={[{
                                borderRadius: SIZES.radius_lg,
                                paddingHorizontal: 18,
                                paddingVertical: 25,
                                marginHorizontal: 15,
                                borderWidth: 1,
                                borderColor: colors.borderColor,
                                overflow: 'hidden',
                                marginBottom: 20
                            }]}
                        >
                            <Text style={{ ...FONTS.font, ...FONTS.h3, color: COLORS.white, marginBottom: 18 }} >Announcement (15 Mar 2024)
                            </Text>
                            <Text style={{ ...FONTS.font, ...FONTS.h6, color: COLORS.white, marginBottom: 18 }}>
                                Due to the heavy traffic of transactions on the solana blockchain on these days, some exchanges will be interrupted. Therefore, please only transact on the tron ​​blockchain. Transactions on these days will take more than 24 hours. Update on the 17th (UTC) Due to the transactions taking place, there may be difficulties in login to your wallet. We express our regret for that. Thank you for staying with bitzWallet.
                            </Text>


                            <View>
                                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, marginBottom: 6 }}>Chat Link</Text>
                                <LinearGradient
                                    start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                    colors={["rgba(255,255,255,.05)", "rgba(255,255,255,.1)", "rgba(255,255,255,.05)"]}
                                    style={{ borderColor: colors.borderColor, ...GlobalStyleSheet.formControl }}>
                                    <TextInput
                                        style={{ ...GlobalStyleSheet.Input, color: COLORS.white }}
                                        value='https://app.bitzwallet.com'
                                    />
                                    <View style={{
                                        flexDirection: 'row',
                                        position: 'absolute',
                                        right: 18,
                                        top: 12,
                                    }}>
                                        <TouchableOpacity onPress={() => openLinkInBrowser()}>
                                            <Image
                                                style={{
                                                    height: 20,
                                                    width: 20,
                                                    resizeMode: 'contain',
                                                    tintColor: COLORS.primary
                                                }}
                                                source={ICONS.send} />
                                        </TouchableOpacity>
                                    </View>
                                </LinearGradient>
                            </View>


                        </ImageBackground>
                    </View>

                </ScrollView >
            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    socialIcon: {
        height: 35,
        width: 35,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
        marginHorizontal: 4,
    }
})


export default SystemNotifications;