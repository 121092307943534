import React, { useEffect, useState } from 'react';
import { Text, TouchableOpacity, View, Modal } from 'react-native';
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { useTheme } from '@react-navigation/native';
import { COLORS, FONTS } from '../../constants/theme';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import Button from '../Button/Button';
import CustomInput from '../Input/CustomInput';
import PublicAPI from '../../api/PublicApi';
import OptionBar from '../../components/modal/OptionBar';

const EmailVerifySheet = (navigation: any) => {

    const { colors } = useTheme();
    const [verifyCode, setverifyCode] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');

    const [pendingTime, setPendingTime] = useState("");

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }

    const countDownDate = new Date().getTime() + (3 * 60 * 1000);

    const countDown = () => {

        setTimeout(() => {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Output the result in an element with id="demo"
            var text = minutes + " : " + seconds + "";
            setPendingTime(text);

            countDown();
        }, 1000);
    }

    useEffect(() => {
        countDown();
    }, []);

    const checkCode = async () => {

        const result = await PublicAPI.get('/user/email-verify/' + verifyCode)

        if (result.message == 'Email verified successfully') {
            console.log(result);
            // this.setverifyCode('');
            model('information-circle-sharp', 'Confirm', 'Email verified successfully');
            // ShowSnackbarSuccess('Email verified successfully');
            // navigation.navigate('signin');
        }
        else {
            console.log('Verification Failed');
            console.log(result);
            model('information-circle-sharp', 'Error', result.message);
        }
    }


    return (
        <>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <View style={{
                paddingHorizontal: 15,
                borderBottomWidth: 1,
                borderColor: colors.borderColor,
                paddingVertical: 10,
            }}>
                <Text style={{ ...FONTS.h5, color: colors.title }}>Verify email  </Text>
            </View>
            <View style={GlobalStyleSheet.container}>
                {/* <View style={{marginBottom:15}}>
                    <CustomInput
                        icon={<FontAwesome style={{opacity:.6}} name={'user'} size={20} color={colors.text}/> }
                        value={''}    
                        placeholder={'Name'}
                        onChangeText={(value)=> console.log(value)}
                    />
                </View> */}
                <Text style={{ ...FONTS.body4, color: colors.text, marginBottom: 15, textAlign: "right" }}>{pendingTime}</Text>
                <View style={{ marginBottom: 15 }}>
                    <CustomInput
                        icon={<FontAwesome style={{ opacity: .6 }} name={'lock'} size={20} color={colors.text} />}
                        value={''}
                        // type={'password'}
                        placeholder={'verification code'}
                        // onChangeText={(value)=> console.log(value)}
                        onChangeText={(text: any) => setverifyCode(text)}
                    />
                </View>
                <Button title={'Verify'}
                    onPress={checkCode} />
            </View >
        </>
    );
};



export default EmailVerifySheet;