
import React, { useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    ScrollView,
    Image,
    Alert,
    Platform,
    Modal,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import { FONTS, SIZES, COLORS, ICONS, IMAGES } from '../../constants/theme';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import CustomButton from '../../components/customButton';
import * as Animatable from 'react-native-animatable';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Checkbox from 'expo-checkbox';
import PublicAPI from '../../api/PublicApi';
import OptionBar from '../../components/modal/OptionBar';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import HeaderBar from '../../layout/header';


const Verification = (props: any) => {

    const { navigation } = props;

    const theme = useTheme();
    const { colors } = useTheme();

    const tokens: any = useSelector((state: RootState) => state.auth);

    const [isFocused, setisFocused] = useState(false);
    const [isFocused2, setisFocused2] = useState(false);
    const [isFocused3, setisFocused3] = useState(false);
    const [isFocused4, setisFocused4] = useState(false);
    const [isFocused5, setisFocused5] = useState(false);


    const [userName, setUserName] = useState('');
    const [referral, setReferral] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');



    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }

    const handleRegister = async () => {
        // Perform registration logic here

        setIsSubmitting(true);
        console.log('Referral Number:', referral);
        console.log('Mobile Number:', userName);

        try {
            const result = await PrivateAPI.post('/user/addMobileNic', tokens.user, { mobile: userName, nic: referral });
            console.log(result);
            if (result) {
                model('checkmark-done-sharp', 'Confirm', 'Profile updated successfully');
                console.log('User created successfully');
                // navigation.navigate('Profile');
            } else {
                console.log('User creation failed');
            }
            setIsSubmitting(false);
        } catch (error) {
            console.log('Error creating user:', error);
            model('information-circle-sharp', 'Error', 'Something went wrong');
            setIsSubmitting(false);
        }
        setIsSubmitting(false);

    };


    return (
        <View style={{ backgroundColor: COLORS.secondary, flex: 1 }}>

            <HeaderBar
                leftIcon={'back'}
                title={"Profile Verification"}
            />

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <View style={{
                height: 140,
                backgroundColor: COLORS.secondary,
                position: 'absolute',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}>

                <Image
                    source={IMAGES.logoFullWhite}
                    style={{
                        width: 180,
                        resizeMode: 'contain',
                        marginBottom: 20,
                    }}
                />
            </View>

            <ScrollView
                contentContainerStyle={{ flexGrow: 1 }}
            >

                <Animatable.View
                    animation="fadeInUpBig"
                    duration={1000}
                    style={{ paddingTop: 140, flex: 1 }}>

                    {!theme.dark &&
                        <View
                            style={{
                                height: 30,
                                backgroundColor: 'rgba(255,255,255,.2)',
                                left: 20,
                                right: 20,
                                position: 'absolute',
                                top: 114,
                                borderRadius: 40,
                            }}
                        />
                    }

                    <View
                        style={{
                            ...styles.container,
                            backgroundColor: colors.background,
                            position: 'relative',
                        }}>
                        {theme.dark &&
                            <View
                                // colors={["rgba(22,23,36,.7)", "rgba(22,23,36,0)"]}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                            </View>
                        }

                        <View style={{
                            paddingHorizontal: SIZES.padding,
                            paddingTop: 20,
                            flex: 1,
                        }}>

                            <View style={{ alignItems: 'center', paddingTop: 15, marginBottom: 30 }}>
                                <Animatable.Text
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={700}
                                    style={{ ...FONTS.h3, color: colors.text }}>Verification</Animatable.Text>
                                <Animatable.Text
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={700}
                                    style={{ ...FONTS.font, color: colors.text }}>Add Profile Data</Animatable.Text>
                            </View>

                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1000}
                                style={[styles.inputGroup]}>
                                <Text style={{ ...FONTS.fontSm, color: colors.text, marginBottom: 6 }}>Mobile Number</Text>
                                <View
                                    style={{
                                        ...GlobalStyleSheet.shadow,
                                        backgroundColor: colors.card,
                                        borderRadius: SIZES.radius,
                                    }}
                                >
                                    <View style={styles.inputIco}>
                                        <FeatherIcon name='smartphone' color={COLORS.primary} size={18} />
                                    </View>
                                    <TextInput
                                        onFocus={() => setisFocused5(true)}
                                        onBlur={() => setisFocused5(false)}
                                        onChangeText={(text) => setUserName(text)}
                                        style={[
                                            styles.input,
                                            {
                                                color: colors.text,
                                                backgroundColor: colors.card
                                            },
                                            isFocused5 ? styles.inputActive : ""
                                        ]}
                                        placeholderTextColor={colors.text}
                                        placeholder='Enter Mobile Number'
                                    />
                                </View>
                            </Animatable.View>


                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1000}
                                style={[styles.inputGroup]}>
                                <Text style={{ ...FONTS.fontSm, color: colors.text, marginBottom: 6 }}>NIC / Passport / D.L. Number</Text>
                                <View
                                    style={{
                                        ...GlobalStyleSheet.shadow,
                                        backgroundColor: colors.card,
                                        borderRadius: SIZES.radius,
                                    }}
                                >
                                    <View style={styles.inputIco}>
                                        <FeatherIcon name='user-check' color={COLORS.primary} size={18} />
                                    </View>
                                    <TextInput
                                        onFocus={() => setisFocused4(true)}
                                        onBlur={() => setisFocused4(false)}
                                        onChangeText={(text) => setReferral(text)}
                                        style={[
                                            styles.input,
                                            {
                                                color: colors.title,
                                                backgroundColor: colors.card
                                            },
                                            isFocused4 ? styles.inputActive : ""
                                        ]}
                                        placeholderTextColor={colors.text}
                                        placeholder='Enter NIC Number'
                                    />
                                </View>
                            </Animatable.View>


                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1500}
                            >
                                <CustomButton
                                    // onPress={() => { navigation.navigate('signin') }}
                                    onPress={handleRegister} disabled={isSubmitting}
                                    title="Update Data"
                                />
                            </Animatable.View>
                        </View>
                    </View>
                </Animatable.View>
            </ScrollView>
        </View>
    );
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderTopLeftRadius: SIZES.radius_lg,
        borderTopRightRadius: SIZES.radius_lg,
        overflow: 'hidden',
        marginTop: -16,
    },
    inputGroup: {
        position: 'relative',
        marginBottom: 40,
    },
    input: {
        height: 48,
        borderWidth: 1,
        borderColor: 'transparent',
        fontSize: SIZES.font,
        borderRadius: SIZES.radius,
        paddingLeft: 50,
    },
    inputActive: {
        borderColor: COLORS.primary,
    },
    inputGroupActive: {
        shadowColor: COLORS.primary,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    inputIco: {
        position: 'absolute',
        left: 17,
        top: 15,
        tintColor: COLORS.primary,
        height: 18,
        width: 18,
        resizeMode: 'contain',
        zIndex: 1,
    },
    seprator: {
        height: 1,
        width: '100%',
        position: 'absolute',
    },
    eyeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    eyeImg: {
        height: 20,
        width: 20,
    }
})

export default Verification;
