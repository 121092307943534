import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { COLORS, FONTS, SIZES } from "../constants/theme";


const CustomButton = (props: any) => {
  return (
    <TouchableOpacity
      activeOpacity={0.75}
      onPress={() => (props.onPress ? props.onPress() : "")}
    >
      {props.color ? (
        <View
          style={[
            { ...styles.button, backgroundColor: props.color },
            props.btnSm && { height: 40 },
            props.btnRounded && { borderRadius: 30 },
          ]}
        >
          <Text
            style={[
              { ...FONTS.h6, color: COLORS.white },
              props.textColor && { color: props.textColor },
            ]}
          >
            {props.title}
          </Text>
        </View>
      ) : (
        <>
          <View
            // start={{ x: 0, y: 0 }}
            // end={{ x: 1, y: 0 }}
            // colors={[COLORS.primary, "#2ED2A5"]}
            style={[
              { ...styles.button },
              props.btnSm && { height: 40 },
              props.btnRounded && { borderRadius: 30 },
            ]}
          >
            <Text
              style={{ ...FONTS.h6, color: COLORS.white }}
            >
              {props.title}
            </Text>
          </View>
        </>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    height: 48,
    borderRadius: SIZES.radius,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLORS.primary,
  },
});

export default CustomButton;
