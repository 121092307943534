import React, { useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    PermissionsAndroid,
    Platform,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { launchImageLibrary } from 'react-native-image-picker';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import * as Clipboard from 'expo-clipboard';
import { logOut } from '../../redux/auth/authActions';

const MainNotification = (props: any) => {

    const { colors } = useTheme();
    const dispatch = useDispatch();
    const [imgUrl, setImgUrl] = useState(null);
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });

    React.useEffect(() => {
        getProfile();
    }, [isFocused]);

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const copyToClipboard = async () => {
        console.log(profile.ref)
        Clipboard.setStringAsync(profile.ref);
    };

    const handleProfileImage = async () => {


    }

    const navLinks = [
        {
            icon: ICONS.setting,
            title: "System update News",
            navigate: "SystemNotifications",
        },
        {
            icon: ICONS.chartUp,
            title: "Performance",
            navigate: "Performance",
        },
        {
            icon: ICONS.eco,
            title: "Ecology",
            navigate: "ecologyNotification",
        },
        {
            icon: ICONS.withdrawal,
            title: "Withdrawal Fund",
            navigate: "wfundNotification",
        },
        {
            icon: ICONS.profile,
            title: "New Member Registration",
            navigate: "NewMembers",
        },
        {
            icon: ICONS.wallet,
            title: "Ambassador Performance",
            navigate: "AmbassadorPerformance",
        },
        {
            icon: ICONS.badge,
            title: "Rewards",
            navigate: "Rewards",
        },
    ]


    return (
        <View
            style={{
                flex: 1,
                backgroundColor: colors.background,
            }}
        >
            <HeaderBar
                leftIcon={'back'}
                title={"Notifications"}
            />
            <ScrollView
                contentContainerStyle={{
                    paddingBottom: 100,
                }}
            >
                <View
                    style={{
                        padding: 15,
                    }}
                >

                    <View
                        style={{
                            paddingHorizontal: 18,
                            paddingVertical: 15,
                            borderRadius: SIZES.radius_lg,
                            backgroundColor: colors.card,
                            marginTop: 15,
                            ...GlobalStyleSheet.shadow,
                        }}
                    >
                        {navLinks.map((data, index) => {
                            return (
                                <TouchableOpacity
                                    key={index}
                                    onPress={() => props.navigation.navigate(data.navigate)}
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingVertical: 30,
                                    }}
                                >
                                    <Image
                                        style={[{
                                            height: 30,
                                            width: 30,
                                            tintColor: colors.text,
                                            marginRight: 14,
                                        }]}
                                        source={data.icon}
                                    />
                                    <Text style={{ ...FONTS.font, flex: 1, ...FONTS.fontMedium, color: colors.title }}>{data.title}</Text>
                                    <FeatherIcon size={18} color={colors.text} name='chevron-right' />
                                </TouchableOpacity>
                            )
                        })}
                    </View>
                </View>
            </ScrollView>
        </View>
    )
}

export default MainNotification