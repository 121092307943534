import React, { useState } from "react";
import { View, Text, TextInput, Modal, TouchableOpacity } from "react-native";

import { useTheme } from "@react-navigation/native";
import { FONTS } from "../../constants/theme";
import { GlobalStyleSheet } from "../../constants/styleSheet";
import CustomButton from "../customButton";
import OptionBar from '../../components/modal/OptionBar';
import PrivateApi from '../../api/PrivateAPI';
import { useSelector } from "react-redux";
import { RootState } from "../../redux/rootReducer";

const WithdrawCryptoOTP = (props: any) => {
  const { navigation } = props;
  const { colors } = useTheme();
  const tokens: any = useSelector((state: RootState) => state.auth);
  const [otp, setotp] = useState('');
  const [alertModalVisible, setAlertModalVisible] = useState(false);
  const [modelIcon, setmodelIcon] = useState('');
  const [modelTitle, setmodelTitle] = useState('');
  const [modelDesc, setmodelDesc] = useState('');

  const model = (icon: any, title: any, desc: any) => {
    setmodelIcon(icon);
    setmodelTitle(title);
    setmodelDesc(desc);
    setAlertModalVisible(true);
    setTimeout(() => {
      setAlertModalVisible(false);
    }, 3000);

  }

  const doVerify = () => {
    let obj = {
      "otp": otp
    }
    console.log(otp);
    
    PrivateApi.post('/withdraw/otpVerify', tokens.user, obj).then(async (res: any) => {
      console.log(res);
      if (res.error == true) {
        console.log("ERROR ------>")
        model('information-circle-sharp', 'Error', res.message);
      }
      else {
        console.log("DONE ------>")
        setTimeout(() => {
          console.log("DONE ------> OK ")
          props.navigation.navigate('Home');
        }, 2900);
        model('information-circle-sharp', 'Success', 'Successfully send your withdrawal request');
      }
    }).catch((err) => {
      console.log('err', err);
    });
  }



  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        visible={alertModalVisible}
      >
        <View style={{
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          position: 'relative',
        }}>
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => setAlertModalVisible(false)}
            style={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(0,0,0,.3)',
            }}
          />
          <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
        </View>
      </Modal>

      <View
        style={{
          paddingHorizontal: 15,
          marginTop: 15,
        }}
      >
        <Text style={{ ...FONTS.h5, color: colors.title }}>
          Withdraw
        </Text>
      </View>
      <View style={{ ...GlobalStyleSheet.modalBody, flex: 1 }}>
        <View
          style={{
            backgroundColor: colors.card,
            ...GlobalStyleSheet.formControl,
            ...GlobalStyleSheet.shadow,
          }}
        >
          <TextInput
            style={{ ...GlobalStyleSheet.Input, color: colors.text }}
            placeholder="Email OTP"
            placeholderTextColor={colors.text}
            onChangeText={(text) => setotp(text)}
            value={otp.toString()}
          />
        </View>

        <CustomButton title="Withdraw" onPress={() => { doVerify() }} />
      </View>
    </>
  );
};

export default WithdrawCryptoOTP;
