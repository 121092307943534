import React, { useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    PermissionsAndroid,
    Platform,
    ActivityIndicator,
    Modal,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { launchImageLibrary } from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import OptionBar from '../../components/modal/OptionBar';


const VerificationNIC = (props: any) => {

    const { colors } = useTheme();
    const dispatch = useDispatch();
    const [imgUrlF, setImgUrlF] = useState(null);
    const [imgUrlB, setImgUrlB] = useState(null);
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        nic_number: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb",
        nic_front: null,
        nic_back: null,
    });

    const [isLoadingF, setIsLoadingF] = React.useState(true);
    const [isLoadingB, setIsLoadingB] = React.useState(true);

    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');

    React.useEffect(() => {
        getProfile();
    }, [isFocused]);

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            if (res.nic_front) {
                downloadImageF("https://upload.bitzwallet.com/images/" + res.nic_front);
            } else {
                setIsLoadingF(false);
            }
            if (res.nic_back) {
                downloadImageB("https://upload.bitzwallet.com/images/" + res.nic_back);
            } else {
                setIsLoadingB(false);
            }
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const handleNicF = async () => {
        setIsLoadingF(true);
        let result: any = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            quality: 1,
        });
        // console.log(result);
        if (!result.canceled) {
            setImgUrlF(result.assets[0].uri);
            // alert(result);
            uploadImage(result.assets[0].uri, 'front');
        } else {
            alert('You did not select any image.');
        }
    }

    const handleNicB = async () => {
        setIsLoadingB(true);
        let result: any = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            quality: 1,
        });
        // console.log(result);
        if (!result.canceled) {
            setImgUrlB(result.assets[0].uri);
            // alert(result);
            uploadImage(result.assets[0].uri, 'back');
        } else {
            alert('You did not select any image.');
        }
    }

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }


    const uploadImage = async (uri: any, type: string) => {
        try {
            console.log('URL');
            console.log(uri);

            const res = await fetch(Platform.OS === 'ios' ? uri.replace('file://', '') : uri);
            const blob = await res.blob();
            console.log('res');
            console.log(res);

            const formData = new FormData();
            formData.append('file', blob, 'photo.jpg');

            console.log('______FORM DATA______');

            console.log(formData);



            let response = await fetch('https://upload.bitzwallet.com/upload', {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                // Parse the response JSON
                const data = await response.json();
                console.log("------------------------------------------------------>")
                console.log(data);
                const filename = data.filename;
                console.log("File uploaded successfully with filename: " + filename);
                console.log("<------------------------------------------------------")

                model('checkmark-done-sharp', 'Confirm', 'File upload successfully');

                if (type == "front") {
                    setNicFB(filename, "front");
                }

                if (type == "back") {
                    setNicFB(filename, "back");
                }

            } else {
                console.log("Error uploading file. Status: " + response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const setNicFB = async (uri: any, type: string) => {
        let apiUrl = ""
        if (type == "front") {
            apiUrl = "/user/addNicFront";
        }

        if (type == "back") {
            apiUrl = "/user/addNicBack";
        }

        PrivateApi.post(apiUrl, tokens.user, { image: uri }).then((res: any) => {
            console.log('res', res);
            getProfile();
        }
        ).catch((err) => {
            console.log('err', err);
        });
    }

    const downloadImageF = async (uri: any) => {
        try {
            let response = await fetch(uri);
            console.log("-------------->")
            console.log(response);
            // set response image to selectedImage
            if (response.status == 200) {
                setImgUrlF(uri);
                setIsLoadingF(false)
            }
            console.log("<--------------")
        } catch (error) {
            console.error(error);
        }
    }
    const downloadImageB = async (uri: any) => {
        try {
            let response = await fetch(uri);
            console.log("-------------->")
            console.log(response);
            // set response image to selectedImage
            if (response.status == 200) {
                setImgUrlB(uri);
                setIsLoadingB(false)
            }
            console.log("<--------------")
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <View
            style={{
                flex: 1,
                backgroundColor: colors.background,
            }}
        >
            <HeaderBar
                leftIcon={'back'}
                title={"Profile"}
            />
            <ScrollView
                contentContainerStyle={{
                    paddingBottom: 100,
                }}
            >
                <View
                    style={{
                        padding: 15,
                    }}
                >


                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisible}
                    >
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            position: 'relative',
                        }}>
                            <TouchableOpacity
                                activeOpacity={1}
                                onPress={() => setModalVisible(false)}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'rgba(0,0,0,.3)',
                                }}
                            />
                            <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                        </View>
                    </Modal>



                    <Text style={{ fontSize: 18, color: COLORS.white, fontWeight: 'bold' }}>NIC Front Side</Text>
                    <ImageBackground
                        source={IMAGES.bg1}
                        style={{
                            flexDirection: 'row',
                            paddingHorizontal: 18,
                            paddingVertical: 18,
                            borderRadius: SIZES.radius_lg,
                            overflow: 'hidden',
                            alignItems: 'center',
                            marginBottom: 30,
                        }}
                    >


                        <View style={{ marginRight: 18, borderWidth: 3, borderRadius: 80, borderColor: 'rgba(255,255,255,.1)', justifyContent: 'center' }}>


                            <Image
                                source={imgUrlF ? { uri: imgUrlF } : IMAGES.nicf}
                                style={{
                                    height: SIZES.width * 0.5,
                                    width: SIZES.width * 0.8,
                                    borderRadius: 20,
                                }}
                            />
                            {isLoadingF ?
                                <ActivityIndicator size="large" color={colors.primary} style={{ marginTop: SIZES.width * -0.1 }} /> : ""}

                            <TouchableOpacity
                                onPress={() => handleNicF()}
                                activeOpacity={.9}
                                style={{
                                    height: 28,
                                    width: 28,
                                    position: 'absolute',
                                    backgroundColor: COLORS.primary,
                                    borderRadius: 28,
                                    bottom: 0,
                                    right: 0,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <FeatherIcon size={14} color={COLORS.white} name='edit' />
                            </TouchableOpacity>
                        </View>
                    </ImageBackground>


                    <Text style={{ fontSize: 18, color: COLORS.white, fontWeight: 'bold' }}>NIC Back Side</Text>
                    <ImageBackground
                        source={IMAGES.bg1}
                        style={{
                            flexDirection: 'row',
                            paddingHorizontal: 18,
                            paddingVertical: 18,
                            borderRadius: SIZES.radius_lg,
                            overflow: 'hidden',
                            alignItems: 'center',
                        }}
                    >
                        <View style={{ marginRight: 18, borderWidth: 3, borderRadius: 80, borderColor: 'rgba(255,255,255,.1)', justifyContent: 'center' }}>
                            <Image
                                source={imgUrlB ? { uri: imgUrlB } : IMAGES.nicb}
                                style={{
                                    height: SIZES.width * 0.5,
                                    width: SIZES.width * 0.8,
                                    borderRadius: 20,
                                }}
                            />
                            {isLoadingB ?
                                <ActivityIndicator size="large" color={colors.primary} style={{ marginTop: SIZES.width * -0.1 }} /> : ""}
                            <TouchableOpacity
                                onPress={() => handleNicB()}
                                activeOpacity={.9}
                                style={{
                                    height: 28,
                                    width: 28,
                                    position: 'absolute',
                                    backgroundColor: COLORS.primary,
                                    borderRadius: 28,
                                    bottom: 0,
                                    right: 0,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <FeatherIcon size={14} color={COLORS.white} name='edit' />
                            </TouchableOpacity>
                        </View>
                    </ImageBackground>

                </View>
            </ScrollView>
        </View>
    )
}

export default VerificationNIC