import React from 'react';
import { ImageBackground, Text, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import { COLORS, FONTS, SIZES } from '../../constants/theme';
// import ButtonSm from '../Button/ButtonSm';

const OptionBar = () => {

    const { colors } = useTheme();

    return (
        <>
            <View style={{
                alignItems: 'center',
                paddingHorizontal: 10,
                paddingVertical: 10,
                paddingBottom: 10,
                backgroundColor: 'dimgrey',
                borderRadius: SIZES.radius,
                marginHorizontal: 10,
                maxWidth: SIZES.width * 0.40,
            }}>
                <ImageBackground
                    source={require('../../assets/images/background/promo_3.jpeg')}
                    style={{
                        paddingHorizontal: SIZES.width * 0.40,
                        paddingVertical: SIZES.width * 0.40,
                        backgroundColor: COLORS.secondary,
                        borderRadius: SIZES.radius,
                        flexDirection: 'row',
                        alignItems: 'flex-end',
                        overflow: 'hidden',
                        height: '100%',
                    }}
                ></ImageBackground>
            </View>
        </>
    );
};



export default OptionBar;