import React, { useEffect, useRef, useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    PermissionsAndroid,
    Platform,
    TextInput,
    Modal,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import { useIsFocused, useRoute } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import theme, { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { launchImageLibrary } from 'react-native-image-picker';
import * as ImagePicker from 'expo-image-picker';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import * as Clipboard from 'expo-clipboard';
import { logOut } from '../../redux/auth/authActions';
import CustomButton from '../../components/customButton';
import OptionBar from '../../components/modal/OptionBar';
import RBSheet from 'react-native-raw-bottom-sheet';
import WithdrawCryptoQr from '../../components/modal/withdrawCryptoQr';
import WithdrawCryptoOTP from '../../components/modal/withdrawCryptoOTP';
import Checkbox from 'expo-checkbox';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CoinItem = [
    {
        icon: IMAGES.trc20,
        coin: 'TRC20',
        sortName: 'TRC20 Network',
    },
    // {
    //     icon: IMAGES.erc20,
    //     coin: 'ERC20',
    //     sortName: 'ERC',
    // },
    // {
    //     icon: IMAGES.solana,
    //     coin: 'SOLANA',
    //     sortName: 'SOL',
    // },

];

const Withdraw = (props: any) => {

    const { colors } = useTheme();
    const theme = useTheme();
    const refRBSheet = useRef();
    const dispatch = useDispatch();
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);

    const [planId, setPlanId] = useState(0);

    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        nic_number: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });

    React.useEffect(() => {

    }, [isFocused]);




    const [ItemValue, setItemValue] = useState(CoinItem[0]);
    const [modalVisible, setModalVisible] = useState(false);
    const [withdrawRBSheet, setWithdrawRBSheet] = useState('');
    const [WithdrawAddress, setWithdrawAdress] = useState('');
    const [WithdrawAmount, setWithdrawAmount] = useState(0);
    const [WithdrawTotal, setWithdrawTotal] = useState(0);
    const [withdrawFee, setWithdrawFee] = useState(0);
    const [freezeAmount, setFreezeAmount] = useState(0);
    const [alertModalVisible, setAlertModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [USDTValue, setUSDTValue] = useState('');

    const [toggleCheckBox, setToggleCheckBox] = useState(false);

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setAlertModalVisible(true);
        setTimeout(() => {
            setAlertModalVisible(false);
        }, 3000);

    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            setUSDTValue(res1.usdt_balance);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const saveWithRequest = () => {
        var dayOfWeek = new Date().getDay();

        let minimum_bitc = 100.00; // minimum amount of bitc to reinvest

        switch (planId) {
            case 0: {
                console.log('You Have Not a Plan >', planId);
                return;
            }
                break;
            case 1: {
                console.log('You Have Plan 1 >', planId);
                minimum_bitc = 5.00;
            }
                break;
            case 2: {
                console.log('You Have Plan 1 >', planId);
                minimum_bitc = 50.00;
            }
                break;
            case 3: {
                console.log('You Have Plan 1 >', planId);
                minimum_bitc = 100.00;
            }
                break;
        }


        if (WithdrawAddress) {

            console.log(WithdrawTotal)

            if (Number(WithdrawAmount)+ Number(withdrawFee) < minimum_bitc) {
                console.log(WithdrawAmount);
                model('information-circle-sharp', 'Error', 'Minimum withdraw amount is '+minimum_bitc+' USDT');
            }
            else {
                if (Number(WithdrawAmount) > Number(USDTValue)) {
                    model('information-circle-sharp', 'Error', 'Insufficient balance');
                }
                else {
                    let obj = {
                        "amount": WithdrawAmount,
                        "network": ItemValue.coin,
                        "toAddress": WithdrawAddress,
                        "fee": withdrawFee,
                        "freez": freezeAmount,
                    }

                    AsyncStorage.setItem("withdraw", JSON.stringify(obj));

                    PrivateApi.post('/withdraw/request', tokens.user, obj).then((res: any) => {
                        console.log('res', res);
                        //model('information-circle-sharp', 'Success', 'Please check your email and get verification code');
                        getMyBalance();
                        setWithdrawAmount(0.0000);
                        setWithdrawAdress('');
                        setWithdrawTotal(0.0000);
                        // setWithdrawRBSheet('otp'); refRBSheet.current.open();
                        props.navigation.navigate('WithdrawOtp');
                    }).catch((err) => {
                        console.log('err', err);
                        model('information-circle-sharp', 'Error', 'Unsuccessfully withdraw');
                    });

                }
            }
        }
        else {
            model('information-circle-sharp', 'Error', 'Please enter wallet address');
        }

    }

    const calwithdrawAmount = (txtAmunt: any) => {
        console.log(txtAmunt);
        setWithdrawTotal(0);

        let wa = Number(txtAmunt);

        var dayOfWeek = new Date().getDay();
        console.log(dayOfWeek);
        var isSaterday = (dayOfWeek === 6);
        // var isSaterday = (dayOfWeek === 1);

        console.log(isSaterday);

        if (isSaterday) {

            if (wa <= 50.00) {
                let tot = Number(txtAmunt) - 1.00;
                setWithdrawFee(1.00);
                if (tot < 0) {
                    setWithdrawTotal(0);
                    setWithdrawAmount(0);
                    setFreezeAmount(0);
                }
                else {
                    setWithdrawTotal(tot);
                    setWithdrawAmount(tot);
                    setFreezeAmount(0);
                }
            } else if (wa <= 100.00) {
                let tot = Number(txtAmunt) - 2.00;
                setWithdrawFee(2.00);
                if (tot < 0) {
                    setWithdrawTotal(0);
                    setWithdrawAmount(0);
                    setFreezeAmount(0);
                }
                else {
                    setWithdrawTotal(tot);
                    setWithdrawAmount(tot);
                    setFreezeAmount(0);
                }
            } else if (wa <= 120.00) {
                let tot = Number(txtAmunt) - 4;
                setWithdrawFee(4);
                if (tot < 0) {
                    setWithdrawTotal(0);
                    setWithdrawAmount(0);
                    setFreezeAmount(0);
                }
                else {
                    setWithdrawTotal(tot);
                    setWithdrawAmount(tot);
                    setFreezeAmount(0);
                }
            } else {
                let fee = (wa * 0.06);
                let tot = ((wa - fee) / 100) * 100;
                setWithdrawFee(fee);
                if (tot < 0) {
                    setWithdrawTotal(0);
                    setWithdrawAmount(0);
                    setFreezeAmount(0);
                }
                else {
                    setWithdrawTotal(tot);
                    setWithdrawAmount(tot);
                    setFreezeAmount(0);
                }
            }
        } else if (dayOfWeek === 0) { // is Sunday
            // can't withdraw today

            model('information-circle-sharp', 'info', 'You can not withdraw today');

        } else { // is week day
            let freezeAmount = wa * 0.35;
            let tot = wa - freezeAmount;
            let fee = 0;
            if (tot > 4) {
                if (tot > 120) {
                    fee = (tot * 0.06);
                } else {
                    fee = 4;
                }
                tot = tot - fee;
                setFreezeAmount(freezeAmount);
                setWithdrawFee(fee);
                setWithdrawTotal(tot);
                setWithdrawAmount(tot);
            } else {
                setWithdrawTotal(0);
                setWithdrawAmount(0);
                setFreezeAmount(0);
                setWithdrawFee(0);
            }
        }
    }

    const [verified, setVerified] = useState(false);

    const checkIsVerifyed = () => {
        PrivateApi.post('/user/checkIsVefifiedComplete', tokens.user).then((res: any) => {
            console.log('checkIsVefifiedComplete **********  -> ', res);
            if (res == true) {
                setVerified(true);
            }
            else {
                setVerified(false);
            }
            return res;

        }).catch((err) => {
            console.log('err', err);
            return false
        });
    }

    const getMyXyBuy = () => {
        PrivateApi.get('/xy-buy/my', tokens.user).then((res: any) => {
            console.log('res', res);
            if (res) {
                setPlanId(res.plan_id);
            }
            // setXYPlans(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }


    useEffect(() => {
        getMyBalance();
        checkIsVerifyed();
        getMyXyBuy();

    }, []);


    return (

        <>

            <Modal
                animationType="slide"
                transparent={true}
                visible={alertModalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setAlertModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                height={withdrawRBSheet === 'Qrcode' ? 650 : withdrawRBSheet === 'otp' ? 240 : 240}
                openDuration={300}
                customStyles={{
                    container: {
                        backgroundColor: colors.background,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                    },
                    draggableIcon: {
                        marginTop: 5,
                        marginBottom: 0,
                        height: 5,
                        width: 90,
                        backgroundColor: colors.borderColor,
                    }
                }}
            >
                {theme.dark &&
                    <View
                        // colors={["rgba(22,23,36,.7)","rgba(22,23,36,0)"]}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                    </View>
                }
                {
                    (withdrawRBSheet === 'Qrcode') ? <WithdrawCryptoQr /> :
                        (withdrawRBSheet === 'otp') ? <WithdrawCryptoOTP /> : <WithdrawCryptoOTP />
                }

            </RBSheet>


            <View
                style={{
                    flex: 1,
                    backgroundColor: colors.background,
                }}
            >
                <HeaderBar
                    leftIcon={'back'}
                    title={"Withdraw"}
                />
                <ScrollView
                    contentContainerStyle={{
                        paddingTop: 20,
                        paddingBottom: 100,
                        paddingHorizontal: 20,
                    }}
                >
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'flex-end',  // Align children to the right
                        alignItems: 'center',       // Center children vertically
                        marginBottom: 20,
                        marginTop: 20,
                        height: 50,
                        borderRadius: 10,

                    }}>
                        <Text style={{ color: colors.text }} >Records</Text>
                        <TouchableOpacity
                            onPress={() => { props.navigation.navigate("WithdrawalNotifications") }}
                            style={{
                                height: '100%',
                                width: 50,  // Adjust width as needed
                                alignItems: 'center',  // Center content horizontally within TouchableOpacity
                                justifyContent: 'center',  // Center content vertically within TouchableOpacity
                            }}
                        >

                            <FeatherIcon name='list' size={22} color={colors.title} />
                        </TouchableOpacity>

                    </View>

                    <View
                        style={[{ flex: 1 }, Platform.OS === "ios" && { paddingTop: 100 }]}
                    >

                        <ScrollView >

                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ ...FONTS.font, color: colors.text, marginRight: 5 }}>Available:</Text>
                                    <Text style={{ ...FONTS.font, color: COLORS.primary }}>{USDTValue} USDT</Text>
                                </View>
                            </View>


                            <Text style={{ ...FONTS.font, ...FONTS.fontMedium, color: colors.title, flex: 1 }}>Default Network </Text>
                            {CoinItem.map((data, index) => (
                                <View
                                    key={index}
                                >
                                    <TouchableOpacity
                                        onPress={() => { setItemValue(data); setModalVisible(false) }}
                                        style={[{
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            paddingVertical: 12,
                                            paddingHorizontal: 15,
                                        }, ItemValue.coin === data.coin && {

                                        }]}
                                    >
                                        <Image
                                            style={{
                                                height: 30,
                                                width: 30,
                                                borderRadius: 30,
                                                marginRight: 10,
                                            }}
                                            source={data.icon}
                                        />
                                        <Text style={{ ...FONTS.font, ...FONTS.fontMedium, color: colors.title, flex: 1 }}>{data.coin}</Text>
                                        <Text style={{ ...FONTS.fontSm, color: colors.text }}>{data.sortName}</Text>
                                    </TouchableOpacity>
                                    <View
                                        // start={{x: 0, y: 0}} end={{x: 1, y: 0}}
                                        // colors={theme.dark ? ["rgba(255,255,255,0)","rgba(255,255,255,.1)","rgba(255,255,255,0)"] : ["rgba(0,0,0,0)","rgba(0,0,0,.1)","rgba(0,0,0,0)"]}
                                        style={{
                                            height: 1,
                                            width: '100%',
                                        }}
                                    >
                                    </View>
                                </View>
                            ))}


                            <View
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <TextInput
                                    style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                    placeholder='Wallet address'
                                    placeholderTextColor={colors.text}
                                    onChangeText={(text) => setWithdrawAdress(text)}
                                    value={WithdrawAddress.toString()}
                                //value='0xbc6b1972ea764159a4cf1c0'
                                />
                            </View>



                            <View
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >




                                <TextInput
                                    style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                    keyboardType="numeric"
                                    inputMode='numeric'
                                    placeholder='Amount'
                                    onChangeText={(text) => calwithdrawAmount(text)}
                                    placeholderTextColor={colors.text}
                                />
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
                                <View style={{ flexDirection: 'row' }}>
                                    <Text style={{ ...FONTS.font, color: colors.text, marginRight: 5 }}>Fee:</Text>
                                    <Text style={{ ...FONTS.font, color: COLORS.primary }}>{withdrawFee.toFixed(2)} USDT</Text>
                                </View>
                            </View>

                            <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>
                                <Text style={{ ...FONTS.h2, color: colors.title }}>{WithdrawTotal.toFixed(2)} USDT</Text>
                                <Text style={{ ...FONTS.font, color: colors.text }}>Receive amount</Text>
                                {freezeAmount > 0 && <Text style={{ ...FONTS.h4, color: "red" }}>Freeze amount : {freezeAmount.toFixed(2)}</Text>}

                            </View>

                            <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>

                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    1.After the update on 01/08/2024, the withdrawal limits have been changed. But all the facilities are valid only for those registered on and after 01/08/2024. to ensure the safety of your funds,your withdrawal request will be manually reviewed if your security strategy or password is changed.please wait for phone calls or email .</Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    2.Est.arrival within 24hr to your requested wallet address,unless not received within 24hr please contact customer support team.</Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    3.if you withdraw crypto to ponzi scheme and other untrusted platforms,your assets will not be withdrawn from the platform any more.</Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    4.users could be held accountable by justice if they are entrusted or enticed by others to assist buying and withdrawing assets.
                                </Text>
                                <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                    5.withdrawal request can be made on all saturdays(UTC),Requests made on all other days will be subject to a freeze of 35% of the total assets balance will be process.if anyone try on sunday 100% of the asset will be freeze or refund.
                                    (Should be considered-when using DEX wallets,you have to pay higher fees when using CEX wallets )</Text>

                            </View>

                            <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 10 }}>
                                <View
                                    style={[Platform.OS === 'ios' && {
                                        transform: [{
                                            scale: .8
                                        }],
                                        marginRight: 5,
                                    }]}
                                >
                                    <Checkbox
                                        // tintColors={{ true: COLORS.primary, false: colors.text }}
                                        // onCheckColor={COLORS.primary}
                                        // onTintColor={COLORS.primary}
                                        value={toggleCheckBox}
                                        onValueChange={(newValue) => setToggleCheckBox(newValue)}
                                    />
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.text, marginLeft: 8, paddingBottom: 10 }}>I have read and agree to
                                    Risk Reminder on withdrawing crypto assets</Text>
                            </View>

                            {toggleCheckBox ?

                                verified ?
                                    <CustomButton
                                        onPress={() => { saveWithRequest() }}
                                        title='Continue'
                                    /> :
                                    <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>
                                        <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                            Your withdrawal request will be manually reviewed If there are any problems with the information you have entered. please wait for phone calls or emails from our staff. If everything is correct it will be verified within 48 hours
                                        </Text>
                                    </View> :
                                ""
                            }




                        </ScrollView>
                    </View>


                </ScrollView>
            </View>
        </>
    )
}


export default Withdraw;