// authActions.ts

import { Dispatch } from 'redux';
import { RootState } from '../rootReducer';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import API from '../../api/PublicApi';

// Action Types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

// Action Creators
export const loginRequest = () => ({
    type: LOGIN_REQUEST,
});

export const loginSuccess = (user: string) => ({
    type: LOGIN_SUCCESS,
    payload: user,
});

export const loginFailure = (error: string) => ({
    type: LOGIN_FAILURE,
    payload: error,
});

export const logOut = () => ({
    type: LOGOUT
});

// Async Action Creator
export const login = (
    success: boolean,
    error: string,
    user: any
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        dispatch(loginRequest());
        try {
            // Simulate an API call for authentication
            if (success) {
                dispatch(loginSuccess(user));
            } else {
                dispatch(loginFailure(error));
                alert("Login Fail");
            }
        } catch (error) {
            dispatch(loginFailure('An error occurred.'));
        }
    };
};



// Async Action Creator
export const updateToken = (
    user: any,
    error: string
): ThunkAction<void, RootState, unknown, AnyAction> => {
    return async (dispatch: Dispatch, getState: () => RootState) => {
        try {
            if (user) {
                dispatch(loginSuccess(user));
            } else {
                dispatch(loginFailure(error));
            }
        } catch (error) {
            dispatch(loginFailure('Refresh Token Error'));
        }
    };
};






// Simulated API function
const authenticate = async (username: string, password: string): Promise<any> => {
    // Simulate a delay
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    const user: any = await API.post('/auth/login', { email: username, password: password });
    console.log(user);
    if (user.accessToken) {
        console.log("Login Success");
        return { success: true, error: '', user: user }
    } else {
        console.log("Login Fail");
        return { success: false, error: 'Invalid credentials.', user: null };
    }
};
