import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    Image,
    TextInput,
    TouchableOpacity,
    ScrollView,
    Button,
    StyleSheet,
} from 'react-native';

import { useTheme } from '@react-navigation/native';
import { COLORS, FONTS, ICONS, IMAGES } from '../../constants/theme';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import CustomButton from '../customButton';
import { BarCodeScanner } from 'expo-barcode-scanner';


const WithdrawCryptoQr = (props: any) => {

    const { colors } = useTheme();
    const { navigation } = props;
    const [hasPermission, setHasPermission] = useState(false);
    const [scanned, setScanned] = useState(false);

    useEffect(() => {
        const getBarCodeScannerPermissions = async () => {
            const { status } = await BarCodeScanner.requestPermissionsAsync();
            setHasPermission(status === 'granted');
        };

        getBarCodeScannerPermissions();
    }, []);

    const handleBarCodeScanned = ({ type, data }: any) => {
        setScanned(true);
        const WithdrawData = {
            toAddress: data
        }
        console.log(WithdrawData);
    };

    if (hasPermission === null) {
        return <Text>Requesting for camera permission</Text>;
    }

    if (hasPermission === false) {
        return <Text>No access to camera</Text>;
    }

    return (
        <>
            <View
                style={{
                    paddingHorizontal: 15,
                    marginTop: 15,
                }}
            >
                <Text style={{ ...FONTS.h5, color: colors.title }}>Withdraw Qr Reader</Text>
            </View>
            <View style={{ flex: 1 }}>
                <ScrollView contentContainerStyle={{ padding: 15 }} showsVerticalScrollIndicator={true}>
                    <TouchableOpacity activeOpacity={1}>

                        <View style={{ flex: 1, height: 100, width: '100%' }}>
                            <BarCodeScanner
                                onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                                style={StyleSheet.absoluteFillObject}
                            />
                            {scanned && <Button title={'Tap to Scan Again'} onPress={() => setScanned(false)} />}
                        </View>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                //value='0xbc6b1972ea764159a4cf1c0'
                            />
                            <View style={{
                                flexDirection: 'row',
                                position: 'absolute',
                                right: 18,
                                top: 12,
                            }}>
                                <TouchableOpacity>
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary
                                        }}
                                        source={ICONS.copy} />
                                </TouchableOpacity>
                            </View>
                        </View>

                        <CustomButton title='Share Address' />
                    </TouchableOpacity>
                </ScrollView>
            </View>

        </>
    )
}


export default WithdrawCryptoQr;