import React, { useEffect, useRef, useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    ScrollView,
    LogBox,
    Platform,
    Image,
    TouchableOpacity,
    Modal,
    ImageBackground,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
LogBox.ignoreLogs([
    "[react-native-gesture-handler] Seems like you\'re using an old API with gesture components, check out new Gestures system!",
]);
import { COLORS, FONTS, IMAGES, SIZES, ICONS } from '../../constants/theme';
// import CheckBox from '@react-native-community/checkbox';
import WidgetPieChart from '../../components/WidgetPieChart';
// import WalletBalanceList from '../components/wallet/walletBalanceList';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import BasicBarChart from '../../components/Charts/BarChart';
import * as Animatable from 'react-native-animatable';
import TopGainersLosers from '../../components/topGainersLosers';
import Swiper from 'react-native-swiper/src';

import RBSheet from "react-native-raw-bottom-sheet";
import DepositCryptoModal from '../../components/modal/depositCryptoModal';
import WithdrawCryptoModal from '../../components/modal/withdrawCryptoModal';
import CommingSoon from '../../components/modal/CommingSoon';

import TopGainers from '../../components/topgainers';
import ImagePopup from '../../components/modal/ImagePopup';
import PrivateApi from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';



const marketData = [
    // {
    //     id: '1',
    //     title: 'Hot Coin #1',
    //     icon: IMAGES.homeSlid5,
    //     coinTitle: 'GXS',
    //     amount: '1,8098',
    //     change: '+47.23%',
    //     data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    // },
    {
        id: '1',
        title: 'Hot Coin #1',
        icon: IMAGES.homeSlid6,
        coinTitle: 'GXS',
        amount: '1,8098',
        change: '+47.23%',
        data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    },
    // {
    //     id: '2',
    //     title: 'Hot Coin #2',
    //     icon: IMAGES.homeSlid7,
    //     coinTitle: 'GXS',
    //     amount: '1,8098',
    //     change: '+47.23%',
    //     data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    // },
    // {
    //     id: '3',
    //     title: 'Hot Coin #3',
    //     icon: IMAGES.homeSlid3,
    //     coinTitle: 'GXS',
    //     amount: '1,8098',
    //     change: '+47.23%',
    //     data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    // },
    // {
    //     id: '3',
    //     title: 'Hot Coin #3',
    //     icon: IMAGES.homeSlid4,
    //     coinTitle: 'GXS',
    //     amount: '1,8098',
    //     change: '+47.23%',
    //     data: [20, 30, 20, 60, 40, 70, 50, 60, 40, 65, 70, 45, 70, 60, 75, 80, 75, 70, 75, 60, 40, 70, 65, 75, 60, 70, 45, 70, 50]
    // },
]




const WalletScreen = (props: any) => {

    const { colors } = useTheme();
    const refRBSheet = useRef();
    const theme = useTheme();
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [pmodalVisible, setpModalVisible] = useState(false); // pop up banner modal
    const [state, setState] = useState('');
    const [pendingTime, setPendingTime] = useState("");
    const tokens: any = useSelector((state: RootState) => state.auth);


    const getMarketData = () => {
        fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false&price_change_percentage=7d&locale=en')
            .then((response) => response.json())
            .then((json) => {
                console.log(json)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const countDown = () => {
        const countDownDate = new Date("2024-08-05 00:00:00").getTime();
        setTimeout(() => {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Output the result in an element with id="demo"
            var text = days + " Days " + hours + " : " + minutes + " : " + seconds + "";
            setPendingTime(text);

            countDown();
        }, 1000);
    }

    const checkIsVefifiedComplete = () => {
        PrivateApi.post('/user/checkIsVefifiedComplete', tokens.user).then((res: any) => {
            console.log('checkIsVefifiedComplete **********  -> ', res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    useEffect(() => {
        countDown();
        checkIsVefifiedComplete();
    }, []);


    return (
        <>

            <View style={{ ...styles.container, backgroundColor: colors.background }}>
                <ScrollView
                    contentContainerStyle={{
                        paddingTop: 30,
                        paddingBottom: 100,
                    }}
                >
                    <WidgetPieChart />

                    <View style={{ marginTop: 10 }}>
                        <Swiper
                            showsButtons={false}
                            style={{ height: 140 }} loop={true}
                            dotStyle={{
                                backgroundColor: "#DDDDDD",
                                height: 0,
                                width: 0,
                            }}
                            activeDotStyle={{
                                backgroundColor: colors.primary,
                                width: 0,
                                height: 0,
                            }}

                        >
                            {marketData.map((data, index) => {
                                return (
                                    <View
                                        key={index}
                                        start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                        colors={['#00BA87', '#1AA780']}
                                        style={{ padding: 15, borderRadius: SIZES.radius, height: '100%', width: '100%', overflow: 'hidden' }}
                                    >
                                        <ImageBackground
                                            source={data.icon}
                                            style={{
                                                paddingHorizontal: 15,
                                                paddingVertical: 15,
                                                backgroundColor: COLORS.secondary,
                                                borderRadius: SIZES.radius,
                                                flexDirection: 'row',
                                                alignItems: 'flex-end',
                                                overflow: 'hidden',
                                                height: '100%',
                                            }}
                                        ></ImageBackground>
                                    </View>
                                )
                            })}

                        </Swiper>
                    </View>



                    {/* <View style={{ marginTop: 10, alignItems: 'center' }}>
                        <Text style={{ ...FONTS.h6, lineHeight: 18, marginBottom: 5, color: colors.title }}>CASH BONANZA</Text>
                        <Text style={{ ...FONTS.h3, fontWeight: 'bold', lineHeight: 18, marginBottom: 5, color: colors.text }}>{pendingTime}</Text>
                        <Text style={{ ...FONTS.h3, fontWeight: 'bold', lineHeight: 18, marginBottom: 5, color: colors.text }}>0 Days 00:00:00</Text> 
                    </View> */}



                    {/* <View
                        style={{
                            ...GlobalStyleSheet.card,
                            backgroundColor: colors.card,
                            ...GlobalStyleSheet.shadow,
                            margin: 15
                        }}
                    >
                        <View style={{ marginBottom: 15 }}>
                            <Text style={{ ...FONTS.h6, lineHeight: 18, marginBottom: 2, color: colors.title }}>Mining Graph</Text>
                        </View>

                        <BasicBarChart />
                    </View> */}

                    {/* <View style={{ ...GlobalStyleSheet.row, paddingHorizontal: 10, marginBottom: 35 }}>
                        <View style={{ ...GlobalStyleSheet.col50 }}>
                            <View
                                style={{
                                    borderRadius: SIZES.radius,
                                    padding: 20,
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <View
                                    style={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: SIZES.radius,
                                        backgroundColor: COLORS.primaryLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginBottom: 10
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary,
                                        }}
                                        source={ICONS.customer}
                                    />
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.title }}>Your Community</Text>
                                <Text style={{ ...FONTS.h2, color: COLORS.primary, lineHeight: 37 }}>99</Text>
                                <Text style={{ ...FONTS.fontSm, color: colors.text }}>Referrals</Text>
                            </View>
                        </View>
                        <View style={{ ...GlobalStyleSheet.col50 }}>
                            <View
                                style={{
                                    borderRadius: SIZES.radius,
                                    padding: 20,
                                    position: 'relative',
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.shadow,
                                }}
                            >
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                                    <View
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: SIZES.radius,
                                            backgroundColor: COLORS.primaryLight,
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Image
                                            style={{
                                                height: 22,
                                                width: 22,
                                                resizeMode: 'contain',
                                                tintColor: COLORS.primary,
                                            }}
                                            source={ICONS.dollor}
                                        />
                                    </View>
                                </View>
                                <Text style={{ ...FONTS.font, color: colors.title }}>Lifetime Reward</Text>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Text style={{ ...FONTS.h5, ...FONTS.fontMedium, lineHeight: 37, color: colors.title }}>75.33</Text>
                                </View>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 16,
                                            width: 16,
                                            borderRadius: 16,
                                            resizeMode: 'contain',
                                            marginRight: 5,
                                        }}
                                        source={IMAGES.bitcoin}
                                    />
                                    <Text style={{ ...FONTS.fontSm, color: colors.text }}>0.015 BTC</Text>
                                </View>
                            </View>
                        </View>
                    </View> */}

                    <View
                        style={{
                            marginBottom: 15,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 5,
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => { setState('withdraw'), props.navigation.navigate("Withdraw") }}
                            style={{
                                paddingHorizontal: 15,
                                paddingVertical: 8,
                                borderRadius: SIZES.radius,
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    // backgroundColor: 'rgba(255,255,255,.1)',
                                    backgroundColor: COLORS.primaryLight,
                                    height: 60,
                                    width: 60,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 16,
                                    marginBottom: 6,
                                }}
                            >
                                <Image
                                    source={ICONS.withdrawal}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        tintColor: COLORS.primary,
                                    }}
                                />
                            </View>
                            <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>Withdraw</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => { setState('deposit'), props.navigation.navigate("Deposit") }}
                            style={{
                                paddingHorizontal: 15,
                                paddingVertical: 8,
                                borderRadius: SIZES.radius,
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: COLORS.primaryLight,
                                    height: 60,
                                    width: 60,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 16,
                                    marginBottom: 6,
                                }}
                            >
                                <Image
                                    source={ICONS.wallet2}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        tintColor: COLORS.primary,
                                    }}
                                />
                            </View>
                            <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>Deposit</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => props.navigation.navigate('Swap')}
                            style={{
                                paddingHorizontal: 15,
                                paddingVertical: 8,
                                borderRadius: SIZES.radius,
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: COLORS.primaryLight,
                                    height: 60,
                                    width: 60,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 16,
                                    marginBottom: 6,
                                }}
                            >
                                <Image
                                    source={ICONS.transfer}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        tintColor: COLORS.primary,
                                    }}
                                />
                            </View>
                            <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>Transfer</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => setModalVisible(true)}
                            style={{
                                paddingHorizontal: 15,
                                paddingVertical: 8,
                                borderRadius: SIZES.radius,
                                alignItems: 'center',
                            }}
                        >
                            <View
                                style={{
                                    backgroundColor: COLORS.primaryLight,
                                    height: 60,
                                    width: 60,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    borderRadius: 16,
                                    marginBottom: 6,
                                }}
                            >
                                <Image
                                    source={ICONS.chart}
                                    style={{
                                        height: 30,
                                        width: 30,
                                        tintColor: COLORS.primary,
                                    }}
                                />
                            </View>
                            <Text style={{ ...FONTS.fontSm, ...FONTS.fontMedium, color: colors.title, textAlign: 'center', marginBottom: 8 }}>P2P</Text>
                        </TouchableOpacity>

                    </View>

                    <TopGainers />

                    {/* <Animatable.View
                        animation="fadeIn"
                        duration={1000}
                        delay={2500}
                    >
                        <TopGainersLosers />
                    </Animatable.View> */}


                    {/* <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            paddingHorizontal: 15,
                            marginBottom: 12,
                        }}
                    >
                        <Text style={{ ...FONTS.h6, color: colors.title }}>Wallet Balance</Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 'auto', marginRight: 8 }}>
                            <View
                                style={[Platform.OS === 'ios' && {
                                    transform: [{
                                        scale: .8
                                    }],
                                    marginRight: 5,
                                }]}
                            >
                                <CheckBox
                                    tintColors={{ true: COLORS.primary, false: colors.text }}
                                    onCheckColor={COLORS.primary}
                                    onTintColor={COLORS.primary}
                                    value={toggleCheckBox}
                                    onValueChange={(newValue) => setToggleCheckBox(newValue)}
                                />
                            </View>
                            <Text style={{ ...FONTS.font, color: colors.text }}>Hide Balance</Text>
                        </View>
                    </View>
                    <WalletBalanceList
                        navigate={props.navigation.navigate}
                        destination="Trade"
                        data={BalanceList}
                        theme={theme}
                    /> */}
                </ScrollView>

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={modalVisible}
                >
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        position: 'relative',
                    }}>
                        <TouchableOpacity
                            activeOpacity={1}
                            onPress={() => setModalVisible(false)}
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,.3)',
                            }}
                        />
                        <CommingSoon />

                    </View>
                </Modal>

                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={pmodalVisible}
                >
                    <View style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        flex: 1,
                        position: 'relative',
                    }}>
                        <TouchableOpacity
                            activeOpacity={1}
                            onPress={() => setpModalVisible(false)}
                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                backgroundColor: 'rgba(0,0,0,.3)',
                            }}
                        />

                        <ImagePopup />
                    </View>
                </Modal>

                <RBSheet
                    ref={refRBSheet}
                    closeOnDragDown={true}
                    height={state === "deposit" ? 300 : state === "withdraw" ? 500 : 430}
                    openDuration={300}
                    customStyles={{
                        wrapper: {
                            // backgroundColor: this.props.theme.colors.modalBackLayer,
                        },
                        container: {
                            // backgroundColor: theme.colors.background,
                            backgroundColor: "#3F3F46",
                            borderTopLeftRadius: 15,
                            borderTopRightRadius: 15,
                        },
                        draggableIcon: {
                            width: 90,
                            backgroundColor: theme.colors.borderColor,
                        }
                    }}
                >
                    {theme.dark &&
                        <View
                            // colors={["rgba(22,23,36,.7)","rgba(22,23,36,0)"]}

                            style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                            }}
                        >
                        </View>
                    }
                    {state === "deposit" ?
                        <DepositCryptoModal />
                        :
                        state === "withdraw" ?
                            <WithdrawCryptoModal />
                            :
                            <DepositCryptoModal />
                    }
                </RBSheet>


            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})

export default WalletScreen;