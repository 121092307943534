import React, { useRef, useState } from 'react';
import { View, TextInput, StyleSheet, Button, Image, Text, TouchableOpacity, Platform, Alert, Modal } from 'react-native';
import { SIZES, COLORS, IMAGES, FONTS, ICONS } from '../../constants/theme';
import { useTheme } from '@react-navigation/native';
import RBSheet from 'react-native-raw-bottom-sheet';
import { ScrollView } from 'react-native-gesture-handler';
import * as Animatable from 'react-native-animatable';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import FeatherIcon from 'react-native-vector-icons/Feather';
import CustomButton from '../../components/customButton';
import PublicAPI from '../../api/PublicApi';
import EmailVerifySheet from '../../components/ActionSheet/EmailVerifySheet';
import OptionBar from '../../components/modal/OptionBar';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { login } from '../../redux/auth/authActions';

import { useDispatch, useSelector } from 'react-redux';

const logins = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="svg-main-icon">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect x="0" y="0" width="24" height="24"/>
    <rect fill="#3899f3" opacity="0.3" transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) " x="8" y="6" width="2" height="12" rx="1"/>
    <path d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z" fill="#3899f3" fill-rule="nonzero" opacity="0.3" transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "/>
    <path d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z" fill="#3899f3" fill-rule="nonzero" transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "/>
</g>
</svg>`;


const Login = (props: any) => {

    const dispatch = useDispatch();

    const { navigation } = props;

    const theme = useTheme();
    const { colors } = useTheme();
    const [handlePassword, setHandlePassword] = useState(true);
    const [toggleCheckBox, setToggleCheckBox] = useState(false);
    const [isFocused, setisFocused] = useState(false);
    const [isFocused2, setisFocused2] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const appLogo = IMAGES.logoFullWhite;

    const refRBSheet = useRef();
    const [activeSheet, setActiveSheet] = useState('');

    // const alertDialog = (title: string, desc: string) =>
    //     Alert.alert(
    //         title,
    //         desc,
    //         [
    //             { text: "OK" }
    //         ]
    //     );

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);

    }


    const alertDialog = async (title: string, desc: string) => {
        if (Platform.OS === 'web') {
            alert(desc)
        } else {
            Alert.alert(title, desc, [
                { text: "OK" }
            ])
        }
    }


    const logged = async () => {

        console.log("-------------------------");
        console.log(email + ' 00X00 ' + password);
        const result = await PublicAPI.post('/auth/login', { email: email, password: password })
        console.log(result);
        if (result.accessToken) {
            // console.log(result);
            AsyncStorage.setItem('accessToken', result.accessToken).then(() => {
                console.log('Item saved to accessToken');
                AsyncStorage.setItem('refreshToken', result.refreshToken).then(() => {
                    console.log('Item saved to refreshToken');
                    console.log('Login Success');
                    const obj = { success: true, error: '', user: result }
                    dispatch(login(true, '', result));
                    // navigation.navigate('drawernavigation');
                }).catch(error => {
                    console.error('Error saving item to refreshToken:', error);
                });
            }).catch(error => {
                console.error('Error saving item to accessToken:', error);
            });
        } else if (result.message == 'Email not verified') {
            refRBSheet.current.open();
        }
        else {
            console.log('Login Fail');
            console.log(result);
            model('information-circle-sharp', 'Error', result.message);
        }

    }

    const ActionData = [
        {
            icon: logins,
            title: "Login",
            sheet: 'login',
        }
    ]


    return (
        <>
            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                height={260}
                openDuration={100}
                customStyles={{
                    wrapper: {
                    },
                    container: {
                        backgroundColor: "#3F3F46",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                    },
                    draggableIcon: {
                        marginTop: 5,
                        marginBottom: 0,
                        height: 5,
                        width: 90,
                        backgroundColor: colors.background,
                    }
                }}
            >
                {theme.dark &&
                    // <LinearGradien

                    <View style={{ paddingHorizontal: SIZES.padding, paddingTop: 20 }}

                        // colors={["rgba(22,23,36,.7)", "rgba(22,23,36,0)"]}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                    </View>

                }
                <EmailVerifySheet />
            </RBSheet>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>


            <View style={{ backgroundColor: COLORS.secondary, flex: 1 }}>


                <View style={{
                    height: 140,
                    backgroundColor: COLORS.secondary,
                    position: 'absolute',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>

                    <Image
                        source={IMAGES.logoFullWhite}
                        style={{
                            width: 180,
                            resizeMode: 'contain',
                            marginBottom: 20,
                        }}
                    />
                </View>

                <ScrollView contentContainerStyle={{ flexGrow: 1 }}>

                    <Animatable.View
                        animation="fadeInUpBig"
                        duration={1000}
                        style={{ paddingTop: 140, flex: 1 }}
                    >
                        {!theme.dark &&
                            <View
                                style={{
                                    height: 30,
                                    backgroundColor: 'rgba(255,255,255,.2)',
                                    left: 20,
                                    right: 20,
                                    position: 'absolute',
                                    top: 114,
                                    borderRadius: 40,
                                }}
                            />}

                        <View
                            style={{
                                ...styles.container,
                                backgroundColor: colors.background,
                                position: 'relative',
                            }}>
                            {theme.dark &&
                                <View
                                    // <LinearGradient
                                    // colors={["rgba(22,23,36,.7)", "rgba(22,23,36,0)"]}
                                    style={{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                    }}
                                >
                                </View>

                            }

                            <View style={{
                                paddingHorizontal: SIZES.padding,
                                paddingTop: 20,
                                flex: 1,
                            }}>

                                <View style={{ alignItems: 'center', paddingTop: 15, marginBottom: 30 }}>
                                    <Animatable.Text
                                        animation="fadeInUp"
                                        duration={1000}
                                        delay={700}
                                        style={{ ...FONTS.h3, color: colors.title }}>Sign in bitz wallet</Animatable.Text>
                                    <Animatable.Text
                                        animation="fadeInUp"
                                        duration={1000}
                                        delay={700}
                                        style={{ ...FONTS.font, color: colors.text }}>Enter your details below</Animatable.Text>
                                </View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1000}
                                    style={[styles.inputGroup]}>
                                    <Text style={{ ...FONTS.fontSm, color: colors.title, marginBottom: 6 }}>Email</Text>
                                    <View
                                        style={{
                                            ...GlobalStyleSheet.shadow,
                                            backgroundColor: colors.card,
                                            borderRadius: SIZES.radius,
                                        }}
                                    >
                                        <View style={styles.inputIco}>
                                            <FeatherIcon name='mail' color={COLORS.primary} size={18} />
                                        </View>
                                        <TextInput
                                            onFocus={() => setisFocused(true)}
                                            onBlur={() => setisFocused(false)}
                                            onChangeText={(text) => setEmail(text)}
                                            style={[
                                                styles.input,
                                                {
                                                    color: colors.text,
                                                    backgroundColor: colors.card
                                                },
                                                isFocused ? styles.inputActive : ""
                                            ]}
                                            placeholderTextColor={colors.text}
                                            placeholder='Enter your email'
                                        />
                                    </View>
                                </Animatable.View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1200}
                                    style={styles.inputGroup}>
                                    <Text style={{ color: colors.title, marginBottom: 6 }}>Password</Text>
                                    <View
                                        style={{
                                            ...GlobalStyleSheet.shadow,
                                            backgroundColor: colors.card,
                                            borderRadius: SIZES.radius,
                                        }}
                                    >
                                        <View style={styles.inputIco}>
                                            <FeatherIcon name='lock' color={COLORS.primary} size={18} />
                                        </View>
                                        <TextInput
                                            onFocus={() => setisFocused2(true)}
                                            onBlur={() => setisFocused2(false)}
                                            onChangeText={(text) => setPassword(text)}
                                            style={[
                                                styles.input,
                                                {
                                                    color: colors.title,
                                                    backgroundColor: colors.card
                                                },
                                                isFocused2 ? styles.inputActive : ""
                                            ]}
                                            placeholderTextColor={colors.text}
                                            placeholder='Enter password'
                                            secureTextEntry={handlePassword}
                                        />
                                        {(handlePassword) ?
                                            <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandlePassword(false)}>
                                                <FeatherIcon name='eye' color={COLORS.primary} size={18} />
                                            </TouchableOpacity>
                                            :
                                            <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandlePassword(true)}>
                                                <FeatherIcon name='eye-off' color={COLORS.primary} size={18} />
                                            </TouchableOpacity>
                                        }
                                    </View>
                                </Animatable.View>

                                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>

                                    <Animatable.View
                                        animation="fadeInRight"
                                        duration={1000}
                                        delay={1500}
                                    >
                                        <TouchableOpacity onPress={() => { navigation.navigate('Forget') }}>
                                            <Text style={{ color: COLORS.primary }}>Forgot Passwrod</Text>
                                        </TouchableOpacity>
                                    </Animatable.View>
                                </View>


                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1500}
                                >
                                    <CustomButton
                                        // onPress={() => { navigation.navigate('drawernavigation') }}
                                        onPress={logged}
                                        title="Sign In"
                                    />
                                </Animatable.View>

                                <Animatable.View
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={1500}
                                >
                                    <View style={{ flexDirection: 'row', justifyContent: 'center', marginVertical: 15 }}>
                                        <Text style={{
                                            ...FONTS.font,
                                            marginRight: 5,
                                            color: colors.text,
                                        }}>Don't have an account?</Text>
                                        <TouchableOpacity onPress={() => navigation.navigate('Sign Up')}>
                                            <Text style={{ ...FONTS.font, color: COLORS.primary }}>Sign up</Text>
                                        </TouchableOpacity>
                                    </View>
                                </Animatable.View>

                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <View style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                        marginVertical: 10,
                                        flexDirection: 'row',
                                    }}>
                                        <View
                                            colors={theme.dark ? ["rgba(255,255,255,0)", "rgba(255,255,255,.1)"] : ["rgba(0,0,0,0)", "rgba(0,0,0,.1)"]}
                                            start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                            style={{
                                                flex: 1,
                                                height: 1,
                                            }}
                                        >
                                        </View>
                                        <Text style={{
                                            ...FONTS.fontSm,
                                            paddingHorizontal: 12,
                                            color: colors.text
                                        }}>Or sign in with</Text>
                                        <View
                                            colors={theme.dark ? ["rgba(255,255,255,.1)", "rgba(255,255,255,0)"] : ["rgba(0,0,0,.1)", "rgba(0,0,0,0)"]}
                                            start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }}
                                            style={{
                                                flex: 1,
                                                height: 1,
                                            }}
                                        >
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={[GlobalStyleSheet.row, { marginBottom: 20, marginTop: 15 }]}
                                >
                                    <View style={GlobalStyleSheet.col50}>
                                        <TouchableOpacity
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                borderWidth: 1,
                                                justifyContent: 'center',
                                                paddingHorizontal: 15,
                                                paddingVertical: 12,
                                                borderRadius: SIZES.radius,
                                                borderColor: colors.borderColor,
                                                backgroundColor: colors.card,
                                                ...GlobalStyleSheet.shadow,
                                            }}
                                        >
                                            <Image
                                                source={ICONS.google}
                                                style={{
                                                    height: 18,
                                                    width: 18,
                                                    marginRight: 12,
                                                }}
                                            />
                                            <Text style={{ ...FONTS.font, color: colors.title, ...FONTS.fontMedium }}>Google</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <View style={GlobalStyleSheet.col50}>
                                        <TouchableOpacity
                                            style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                borderWidth: 1,
                                                justifyContent: 'center',
                                                paddingHorizontal: 15,
                                                paddingVertical: 12,
                                                borderRadius: SIZES.radius,
                                                borderColor: colors.borderColor,
                                                backgroundColor: colors.card,
                                                ...GlobalStyleSheet.shadow,
                                            }}
                                        >
                                            <Image
                                                source={ICONS.facebook}
                                                style={{
                                                    height: 18,
                                                    width: 18,
                                                    marginRight: 12,
                                                }}
                                            />
                                            <Text style={{ ...FONTS.font, color: colors.title, ...FONTS.fontMedium }}>Facebook</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>

                            </View>
                        </View>
                    </Animatable.View>
                </ScrollView>
            </View >
        </>
    );
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderTopLeftRadius: SIZES.radius_lg,
        borderTopRightRadius: SIZES.radius_lg,
        overflow: 'hidden',
        marginTop: -16,
    },
    inputGroup: {
        position: 'relative',
        marginBottom: 15,
    },
    input: {
        height: 48,
        borderWidth: 1,
        borderColor: 'transparent',
        fontSize: SIZES.font,
        borderRadius: SIZES.radius,
        paddingLeft: 50,
    },
    inputActive: {
        borderColor: COLORS.primary,
    },
    inputGroupActive: {
        shadowColor: COLORS.primary,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    inputIco: {
        position: 'absolute',
        left: 17,
        top: 15,
        tintColor: COLORS.primary,
        height: 18,
        width: 18,
        resizeMode: 'contain',
        zIndex: 1,
    },
    seprator: {
        height: 1,
        width: '100%',
        position: 'absolute',
    },
    eyeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    eyeImg: {
        height: 20,
        width: 20,
    },


    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonOpen: {
        backgroundColor: '#F194FF',
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
})
export default Login;
