import React from 'react';

import {
    View,
    Text,
    StyleSheet,
    Image,
    FlatList,
    TouchableOpacity as Ripple,
} from 'react-native';

import { useTheme } from '@react-navigation/native';
import HeaderBar from '../../layout/header';
import { FONTS, SIZES, COLORS, ICONS } from '../../constants/theme';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import moment from 'moment';


const ListIndirect = () => {

    const tokens: any = useSelector((state: RootState) => state.auth);
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    interface Notification {
        id: number
        userId: number
        xyBuyId: number,
        from_userId: null,
        from_level: null,
        type: string,
        x_amount: string,
        y_amount: string,
        x_burn: string,
        y_burn: string,
        x_pool: string,
        x_release: string,
        x_claim: string,
        y_balance: string,
        created_at: string,
        ecology: string,
        withdrawal_fund: string,
    }

    const { colors } = useTheme();

    const getNotification = () => {
        console.log("------------------- GET Notifications -------------------");
        PrivateAPI.get('/xy-transaction/findAllByUserAndType/Indirect Income', tokens.user).then(async (res: any) => {
           const direct = await res.filter((notifications: Notification) => notifications.type == "Indirect Income")
            setNotifications(direct);
        });
    }

    React.useEffect(() => {
        getNotification();
    }, [])



    return (
        <>
            <View style={{ ...styles.container, backgroundColor: colors.background }}>

                <HeaderBar title="Indirect Income" leftIcon={'back'} />
                <View style={{ flex: 1 }}>
                    <FlatList
                        data={notifications}
                        renderItem={({ item }) => (
                            <Ripple
                                style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    padding: 15,
                                    borderBottomWidth: 1,
                                    borderColor: colors.borderColor,
                                }}
                            >
                                <View
                                    style={{
                                        height: 42,
                                        width: 42,
                                        borderRadius: SIZES.radius,
                                        backgroundColor: COLORS.primaryLight,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginRight: 10,
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            tintColor: COLORS.primary,
                                            resizeMode: 'contain',
                                        }}
                                        source={ICONS.chartUp}
                                    />
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                    }}
                                >
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.font,
                                            ...FONTS.fontMedium,
                                            color: colors.title,
                                            marginBottom: 4
                                        }}
                                    >{item.type}</Text>
                                    <Text
                                        ellipsizeMode='tail'
                                        numberOfLines={1}
                                        style={{
                                            ...FONTS.fontXs,
                                            color: colors.text,
                                        }}
                                    >BiTC : {item.x_amount} </Text>
                                   
                                </View>
                                <View style={{ alignSelf: 'flex-end', width: 110, alignItems: 'flex-end' }}>
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.primary }}>{moment(item.created_at).format('MM-DD-YY hh:mm')}</Text>
                                </View>
                            </Ripple>
                        )}
                    />
                </View>
            </View >
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    }
})


export default ListIndirect;