import React from 'react';
import { Text, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { useTheme } from '@react-navigation/native';
import { FONTS, SIZES } from '../../constants/theme';
import ButtonSm from '../Button/ButtonSm';
// import ButtonSm from '../Button/ButtonSm';

const OptionBarWithdraw = (props: any) => {

    const { colors } = useTheme();

    return (
        <>
            <View style={{
                alignItems: 'center',
                paddingHorizontal: 30,
                paddingVertical: 20,
                paddingBottom: 30,
                backgroundColor: "dimgrey",
                borderRadius: SIZES.radius,
                marginHorizontal: 30,
                maxWidth: 340,
            }}>
                <Ionicons name={props.icon} style={{ marginBottom: 8 }} color={'white'} size={32} />
                <Text style={{ ...FONTS.h5, color: "white", marginBottom: 6 }}>{props.title}</Text>
                <Text style={{ ...FONTS.font, color: "white", textAlign: 'center' }}>{props.description}</Text>
                <View style={{ flexDirection: 'row', marginTop: 18 }}>
                    <ButtonSm title={'Cancel'} style={{ marginRight: 10 }} color={'#f75a5b'} onPress={props.onPress} />
                    <ButtonSm title={'Confirm'} color={'#704FFE'} onPress={props.onPress} />
                </View>
            </View>
        </>
    );
};


export default OptionBarWithdraw;