import React, { useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    Modal,
    PermissionsAndroid,
    Platform,
    ActivityIndicator,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { GlobalStyleSheet } from '../constants/styleSheet';
import * as Animatable from 'react-native-animatable';
import ButtonOutline from '../../components/Button/ButtonOutline';
import CustomButton from '../../components/customButton';
import CustomInput from '../../components/Input/CustomInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import { useIsFocused, useRoute } from '@react-navigation/native';
import OptionBar from '../../components/modal/OptionBar';

const ReInvest = (props: any) => {

    const { colors } = useTheme();
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [availableAmount, setAvailableAmount] = useState('0.0000');
    const [entereAmount, setEntereAmount] = useState('0.0000');
    const [planId, setPlanId] = useState(0);

    const [isLoading, setIsLoading] = useState(true);

    const [xyValues, setXYValues] = useState({
        id: 0,
        x: "0",
        y: "0",
        at: "2023-11-27T03:52:08.000Z",
    })
    const [xYPlans, setXYPlans] = useState([{
        id: 0,
        name: "Lower Node",
        description: " ",
        x_min: "0.000000",
        x_max: "0.000000",
        leverage: "0.000000",
        duration: 0,
        daily_roi: "0.000000",
        monthly_roi: "0.000000",
        status: 1,
        created_at: "2023-11-23T13:48:02.000Z",
        updated_at: "2023-11-23T13:48:09.000Z"
    }]);
    const [myBalances, setMyBalances] = useState({
        id: 1,
        user: 1,
        usdt_balance: "0.0000",
        energy_balance: "0.0000",
        btc_balance: "0.0000",
        eth_balance: "0.0000",
        solana_balance: "0.0000",
        usdt_processing: "0.0000",
        energy_processing: "0.0000",
        btc_processing: "0.0000",
        eth_processing: "0.0000",
        solana_processing: "0.0000",
        created_at: null,
        updated_at: null,
        updated_by: null
    });
    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        "ref": "",
        "status": 0,
        "type": "0",
        "verification": ""
    });

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res: any) => {
            // console.log("Balance --------->");
            // console.log('res', res);
            setMyBalances(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getXYValues = () => {
        PrivateApi.get('/xy-value/value', tokens.user).then((res: any) => {
            // console.log('res', res);
            setXYValues(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getXYPlans = () => {
        PrivateApi.get('/xy-plans', tokens.user).then((res: any) => {
            // console.log('res', res);
            setXYPlans(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getMyXyBuy = () => {
        PrivateApi.get('/xy-buy/my', tokens.user).then((res: any) => {
            console.log('res', res);
            if (res) {
                setPlanId(res.plan_id);
            }
            // setXYPlans(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const setAvailableBalance = (value: any) => {
        if (value <= '0' || value == null) {
            setAvailableAmount('0.0000');
        }
        else {

            console.log(value)
            let calBalace = parseFloat(value) / parseFloat(xyValues.x);
            setEntereAmount(value);
            setAvailableAmount(calBalace.toString());
            console.log(calBalace)
        }
    }

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            // console.log('res', res);
            setProfile(res);
            setIsLoading(false);
        }).catch((err) => {
            console.log('err', err);
            setIsLoading(false);
        });
    }

    const reInvest = () => {
        setIsLoading(true);
        let minimum_bitc = 50.0000; // minimum amount of bitc to reinvest

        console.log("------------ PALAN ID ------------");
        console.log(planId);

        switch (planId) {
            case 0: {
                console.log('You Have Not a Plan >', planId);
                return;
            }
                break;
            case 1: {
                console.log('You Have Plan 1 >', planId);
                minimum_bitc = 10.0000;
            }
                break;
            case 2: {
                console.log('You Have Plan 1 >', planId);
                minimum_bitc = 50.0000;
            }
                break;
            case 3: {
                console.log('You Have Plan 1 >', planId);
                minimum_bitc = 100.0000;
            }
                break;
        }




        if (Number(myBalances.usdt_balance) < Number(entereAmount)) {
            model('information-circle-sharp', 'Error', 'Not Enough usdt amount' + myBalances.usdt_balance);
            setIsLoading(false);
            return;
        }
        if (minimum_bitc > Number(availableAmount)) {
            model('information-circle-sharp', 'Error', 'The minimum amount of reinvest is ' + minimum_bitc);
            setIsLoading(false);
            return;
        }
        PrivateApi.post('/xy-buy/reinvest', tokens.user, { usdt: entereAmount, x: availableAmount }).then(res => {
            console.log("RESPONSE ---------> ")
            console.log(res);
            setIsLoading(false);
            getMyBalance();
            setAvailableBalance(0)
            setEntereAmount("00");
            model('checkmark-done-sharp', 'Success', 'Successfully Reinvestment');
        }).catch(error => {
            console.log(error);
            setIsLoading(false);
        })
    }


    React.useEffect(() => {
        getXYValues();
        getXYPlans();
        getMyBalance();
        getProfile();
        getMyXyBuy();
    }, [
        isFocused
    ]);


    return (

        <View
            style={{
                flex: 1,
                backgroundColor: colors.background,
            }}
        >
            <HeaderBar
                leftIcon={'back'}
                title={"Reinvest"}
            />
            <ScrollView
                contentContainerStyle={{
                    paddingBottom: 100,
                }}
            >

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',  // Align children to the right
                    alignItems: 'center',       // Center children vertically
                    marginBottom: 20,
                    marginTop: 20,
                    height: 50,
                    borderRadius: 10,

                }}>
                    <Text style={{ color: colors.text }} >See all</Text>
                    <TouchableOpacity
                        onPress={() => { props.navigation.navigate('RepurchaseNotice') }}
                        style={{
                            height: '100%',
                            width: 50,  // Adjust width as needed
                            alignItems: 'center',  // Center content horizontally within TouchableOpacity
                            justifyContent: 'center',  // Center content vertically within TouchableOpacity
                        }}
                    >

                        <FeatherIcon name='list' size={22} color={colors.title} />
                    </TouchableOpacity>

                </View>

                <Animatable.View
                    animation="fadeInUp"
                    duration={1000}
                    delay={500}
                >
                    <View
                        style={{
                            padding: 15,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                marginLeft: 10,
                                marginBottom: 10,
                            }}
                        >
                            <Text style={{ ...FONTS.font, color: COLORS.white }}>Minimum Investing Amount 50 USDT </Text>
                        </View>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                paddingVertical: 18,
                                borderRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 300,
                            }}
                        >




                            <View style={{ flex: 1, }}>
                                <View style={{ marginBottom: 20 }}>
                                    <Text style={{ ...FONTS.h5, color: COLORS.text }}>Available USDT {myBalances.usdt_balance}</Text>
                                </View>
                                <View style={{ marginBottom: 10 }}>
                                    <CustomInput
                                        inputBorder
                                        value={''}
                                        keyboardType='number-pad'
                                        placeholder="Enter the amount of reinvest"
                                        onChangeText={(value: any) => setAvailableBalance(value)}
                                    />
                                </View>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 3,
                                    }}
                                >
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.text, opacity: 0.8 }}>Account available BiTC</Text>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white }}>{availableAmount}</Text>
                                </View>
                            </View>
                        </ImageBackground>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                marginLeft: 10,
                                marginTop: 10,
                                marginBottom: 30,
                            }}
                        >
                            <Text style={{ ...FONTS.fontSm, color: COLORS.light }}> All repurchases are included to increase the value of the Bitc token. All users can use USDT/BTC/ETH/SOL for repurchasing. </Text>
                        </View>

                        <Animatable.View
                            animation="fadeInUpBig"
                            duration={1000}
                            delay={500}
                        >

                            {isLoading ?
                                <ActivityIndicator size="large" color={colors.primary} />
                                :
                                <CustomButton
                                    onPress={reInvest}
                                    title="Reinvest"
                                />
                            }



                        </Animatable.View>

                    </View>
                </Animatable.View>



            </ScrollView>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

        </View>
    )
}

export default ReInvest

