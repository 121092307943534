import React from "react";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Home, Login, Profile, Trade, Markets, Browser } from '../screens';
import WalletScreen from "../screens/home/wallet";
// import tradeBasic from "../screens/tradeBasic";
// import markets from "../screens/markets";
import CustomTabBar from './CustomTabBar';
// import TradeBasic from "../screens/tradeBasic";
// import markets from "../screens/markets";
// import Profile from "../screens/Profile";


const Tab = createBottomTabNavigator();

const BottomNavigation = () => {
  return (
    <>

      <Tab.Navigator
        initialRouteName="Home"
        tabBar={props => <CustomTabBar {...props} />}
        screenOptions={{
          headerShown: false,
        }}
      >
        <Tab.Screen
          name="Home"
          // component={Home}
          component={WalletScreen}
        />
        <Tab.Screen
          name="Wallet"
          // component={WalletScreen} 
          component={Home}
        />
        <Tab.Screen
          name="Trade"
          // component={Trade}
          component={Browser}
        />
        <Tab.Screen
          name="Browser"
          component={Markets}
        />
        <Tab.Screen
          name="Profile"
          component={Profile}
        />
      </Tab.Navigator>
    </>
  );
};



export default BottomNavigation;
