import React, { useRef, useState, useEffect } from "react";
import {
    View,
    Text,
    Image,
    TextInput,
    TouchableOpacity,
    Modal,
    ScrollView,
    Platform,
} from "react-native";

import { useTheme } from "@react-navigation/native";
import FeatherIcon from "react-native-vector-icons/Feather";
import RBSheet from "react-native-raw-bottom-sheet";
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from "../../constants/theme";
import { GlobalStyleSheet } from "../../constants/styleSheet";
import AsyncStorage from "@react-native-async-storage/async-storage";
import PrivateApi from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import * as Clipboard from 'expo-clipboard';
import DepositCryptoQr from "../../components/modal/depositCryptoQr";
import HeaderBar from "../../layout/header";
import SvgQRCode from 'react-native-qrcode-svg';
import OptionBar from "../../components/modal/OptionBar";
import Checkbox from "expo-checkbox";
import CustomButton from "../../components/customButton";
import PrivateAPI from "../../api/PrivateAPI";

const CoinItem = [
    // {
    //     icon: IMAGES.bitcoin,
    //     coin: "Bitcoin",
    //     sortName: "BTC",
    // },
    // {
    //     icon: IMAGES.ethereum,
    //     coin: "Etherium",
    //     sortName: "ETH",
    // },
    {
        icon: IMAGES.usdt,
        coin: "Tether",
        sortName: "USDT",
    },
    // {
    //     icon: IMAGES.gdb,
    //     coin: "Own Token",
    //     sortName: "BITZ",
    // },

];




const Deposit = (props: any) => {


    const { colors } = useTheme();
    const theme = useTheme();

    const tokens: any = useSelector((state: RootState) => state.auth);

    const refRBSheet = useRef();
    const [ItemValue, setItemValue] = useState(CoinItem[0]);

    const [toggleCheckBox, setToggleCheckBox] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);
    const [BCTModalVisible, setBCTModalVisible] = useState(false);
    // const [depAddress, setdepAddress] = useState("");
    const [depAddress, setdepAddress] = useState('');

    const [BlockChainType, setBlockChainType] = useState([

        {
            icon: IMAGES.erc20,
            coin: "ERC20",
            sortName: "ERC",
            address: "",
        },
        {
            icon: IMAGES.trc20,
            coin: "TRC20",
            sortName: "TRC",
            address: "",
        },
        {
            icon: IMAGES.solana,
            coin: "Solana",
            sortName: "SOL",
            address: "",
        },
    ]);

    const [BChainTypeValue, setBChainTypeValue] = useState(BlockChainType[0]);



    const { user, error } = useSelector((state: RootState) => state.auth);


    const [modalVisibleCopy, setModalVisibleCopy] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');

    const retrieveData = () => {
        AsyncStorage.getItem('depSelectData')
            .then(value => {
                if (value !== null) {
                    const depAddress = JSON.parse(value);
                    console.log('Get deposit Select Data', value)
                    setdepAddress(depAddress);
                }
            })
            .catch(error => {
                console.log(error);
            });
    };
    const [pendingTime, setPendingTime] = useState("");

    const countDownDate = new Date().getTime() + (30 * 60 * 1000);

    const countDown = () => {

        setTimeout(() => {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Output the result in an element with id="demo"
            var text = minutes + " : " + seconds + "";
            setPendingTime(text);

            countDown();
        }, 1000);
    }

    useEffect(() => {
        // retrieveData();
        getAddress();
    }, [BChainTypeValue]);


    const getAddress = async () => {
        PrivateApi.get('/wallet/user', user)
            .then(async (response: any) => {
                console.log('response', response)

                let arr: any = [];

                await response.map((item: any) => {
                    if (item.network === 'ERC20') {
                        let obj = {
                            icon: IMAGES.erc20,
                            coin: item.network,
                            sortName: "ERC20",
                            address: item.address,
                        }
                        arr.push(obj);
                    }

                    if (item.network === 'solana') {
                        let obj = {
                            icon: IMAGES.solana,
                            coin: item.network,
                            sortName: "solana",
                            address: item.address,
                        }
                        arr.push(obj);
                    }

                    if (item.network === 'TRC20') {
                        let obj = {
                            icon: IMAGES.trc20,
                            coin: item.network,
                            sortName: "TRC20",
                            address: item.address,
                        }
                        arr.push(obj);
                    }

                });
                console.log(arr);
                setBlockChainType(arr);
            }
            )
            .catch((error) => {
                console.log(error);
            }
            );
    }

    const savedDepDetails = async () => {
        AsyncStorage.setItem("depSelectData", JSON.stringify(BChainTypeValue))
            .then(() => {
                console.log("data saved");
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const dipositTry = async () => {
        let obj = {
            address: depAddress,
            wallet: "TRC20",

        }
        try {
            PrivateAPI.post('/deposit-try', tokens.user, obj).then((response: any) => {
                console.log(response);
            }).catch((error: any) => {
                console.log(error);
            })
        } catch (error) {

        }
        model('checkmark-done', 'Deposte Pending', "Please be patient for deposit completion");
        setTimeout(() => {
            props.navigation.navigate('Home');
        }, 2900)

    }

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisibleCopy(true);
        setTimeout(() => {
            setModalVisibleCopy(false);
        }, 3000);

    }

    const copyToClipboard = async () => {
        console.log(BChainTypeValue.address);
        Clipboard.setStringAsync(BChainTypeValue.address);
        model('checkmark-done', 'Copy', "Copied to clipboard");
    };




    return (
        <>

            <HeaderBar
                leftIcon={'back'}
                title={"Deposit Crypto"}
            />


            <ScrollView contentContainerStyle={{
                paddingTop: 20,
                paddingBottom: 20,
                paddingHorizontal: 20,
                backgroundColor: colors.card,
            }}>

                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',  // Align children to the right
                    alignItems: 'center',       // Center children vertically
                    marginBottom: 20,
                    marginTop: 20,
                    height: 50,
                    borderRadius: 10,

                }}>
                    <Text style={{ color: colors.text }} >Records</Text>
                    <TouchableOpacity
                        onPress={() => { props.navigation.navigate("DepositNotification") }}
                        style={{
                            height: '100%',
                            width: 50,  // Adjust width as needed
                            alignItems: 'center',  // Center content horizontally within TouchableOpacity
                            justifyContent: 'center',  // Center content vertically within TouchableOpacity
                        }}
                    >

                        <FeatherIcon name='list' size={22} color={colors.title} />
                    </TouchableOpacity>

                </View>

                <View
                    style={[{ flex: 1 }, Platform.OS === "ios" && { paddingTop: 100, backgroundColor: colors.card, }]}
                >


                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={modalVisibleCopy}
                    >
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            flex: 1,
                            position: 'relative',
                        }}>
                            <TouchableOpacity
                                activeOpacity={1}
                                onPress={() => setModalVisible(false)}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                    backgroundColor: 'rgba(0,0,0,.3)',
                                }}
                            />
                            <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                        </View>
                    </Modal>

                    <Modal animationType="slide" transparent={true} visible={modalVisible}>
                        <View
                            style={[
                                { flex: 1, backgroundColor: colors.card },
                                Platform.OS === "ios" && { paddingTop: 40 },
                            ]}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingTop: 20,
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => setModalVisible(false)}
                                    style={{
                                        padding: 12,
                                    }}
                                >
                                    <FeatherIcon name="arrow-left" size={20} color={colors.title} />
                                </TouchableOpacity>
                            </View>
                            <ScrollView>
                                {CoinItem.map((data, index) => (
                                    <View key={index}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                setItemValue(data);
                                                setModalVisible(false);
                                            }}
                                            style={[
                                                {
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    paddingVertical: 12,
                                                    paddingHorizontal: 15,
                                                },
                                                ItemValue.coin === data.coin && {},
                                            ]}
                                        >
                                            <Image
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                    borderRadius: 30,
                                                    marginRight: 10,
                                                }}
                                                source={data.icon}
                                            />
                                            <Text
                                                style={{
                                                    ...FONTS.font,
                                                    ...FONTS.fontMedium,
                                                    color: colors.title,
                                                    flex: 1,
                                                }}
                                            >
                                                {data.coin}
                                            </Text>
                                            <Text style={{ ...FONTS.fontSm, color: colors.text }}>
                                                {data.sortName}
                                            </Text>
                                        </TouchableOpacity>
                                        <View
                                            // start={{ x: 0, y: 0 }}
                                            // end={{ x: 1, y: 0 }}
                                            // colors={
                                            //   theme.dark
                                            //     ? [
                                            //         "rgba(255,255,255,0)",
                                            //         "rgba(255,255,255,.1)",
                                            //         "rgba(255,255,255,0)",
                                            //       ]
                                            //     : ["rgba(0,0,0,0)", "rgba(0,0,0,.1)", "rgba(0,0,0,0)"]
                                            // }
                                            style={{
                                                height: 1,
                                                width: "100%",
                                            }}
                                        ></View>
                                    </View>
                                ))}
                            </ScrollView>
                        </View>
                    </Modal>

                   

                    <Modal animationType="slide" transparent={true} visible={BCTModalVisible}>
                    
                        <View
                            style={[
                                { flex: 1, backgroundColor: colors.card },
                                Platform.OS === "ios" && { paddingTop: 40 },
                            ]}
                        >
                            <View
                                style={{
                                    flexDirection: "row",
                                    alignItems: "center",
                                    paddingTop: 20,
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => setBCTModalVisible(false)}
                                    style={{
                                        padding: 12,
                                    }}
                                >
                                    <FeatherIcon name="arrow-left" size={20} color={colors.title} />
                                </TouchableOpacity>
                            </View>
                            <ScrollView>
                                {BlockChainType.map((data, index) => (
                                    <View key={index}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                setBChainTypeValue(data);
                                                setdepAddress(data.address);
                                                setBCTModalVisible(false);
                                                Simple(data.address);

                                            }}
                                            style={[
                                                {
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    paddingVertical: 12,
                                                    paddingHorizontal: 15,
                                                },
                                                ItemValue.coin === data.coin && {},
                                            ]}
                                        >
                                            <Image
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                    borderRadius: 30,
                                                    marginRight: 10,
                                                }}
                                                source={data.icon}
                                            />
                                            <Text
                                                style={{
                                                    ...FONTS.font,
                                                    ...FONTS.fontMedium,
                                                    color: colors.title,
                                                    flex: 1,
                                                }}
                                            >
                                                {data.coin}
                                            </Text>
                                            <Text style={{ ...FONTS.fontSm, color: colors.text }}>
                                                {data.sortName}
                                            </Text>
                                        </TouchableOpacity>
                                        <View
                                            // start={{ x: 0, y: 0 }}
                                            // end={{ x: 1, y: 0 }}
                                            // colors={
                                            //   theme.dark
                                            //     ? [
                                            //       "rgba(255,255,255,0)",
                                            //       "rgba(255,255,255,.1)",
                                            //       "rgba(255,255,255,0)",
                                            //     ]
                                            //     : ["rgba(0,0,0,0)", "rgba(0,0,0,.1)", "rgba(0,0,0,0)"]
                                            // }
                                            style={{
                                                height: 1,
                                                width: "100%",
                                            }}
                                        ></View>
                                    </View>
                                ))}



                            </ScrollView>


                        </View>
                    </Modal>

                  
                    <RBSheet
                        ref={refRBSheet}
                        closeOnDragDown={true}
                        height={660}
                        openDuration={300}
                        customStyles={{
                            container: {
                                backgroundColor: colors.background,
                                borderTopLeftRadius: 15,
                                borderTopRightRadius: 15,
                            },
                            draggableIcon: {
                                marginTop: 5,
                                marginBottom: 0,
                                height: 5,
                                width: 90,
                                backgroundColor: colors.borderColor,
                            },
                        }}
                    >
                        {theme.dark && (
                            <View
                                // colors={["rgba(22,23,36,.7)", "rgba(22,23,36,0)"]}
                                style={{
                                    position: "absolute",
                                    height: "100%",
                                    width: "100%",
                                }}
                            ></View>
                        )}
                        {/* <WithdrawCryptoQr /> */}
                        <DepositCryptoQr />
                    </RBSheet>

                    <View
                        style={{
                            paddingHorizontal: 15,
                            marginTop: 15,
                        }}
                    >
                        {/* <Text style={{ ...FONTS.h5, color: colors.title }}>Deposit Crypto</Text> */}
                    </View>

                   


                    <View style={{ ...GlobalStyleSheet.modalBody, flex: 1, paddingBottom: 15 }}>
                        <View
                            style={{
                                backgroundColor: colors.card,
                                borderRadius: SIZES.radius,
                                marginBottom: 15,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => setModalVisible(true)}
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    paddingHorizontal: 15,
                                    marginBottom: 0,
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Image
                                    source={ItemValue.icon}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        marginRight: 8,
                                        borderRadius: 24,
                                    }}
                                />
                                <Text style={{ ...FONTS.font, color: colors.title, flex: 1 }}>
                                    {ItemValue.coin}
                                </Text>
                                <FeatherIcon color={colors.text} size={20} name="chevron-down" />
                            </TouchableOpacity>
                        </View>

                        <Text style={{ color: "white" }}> Select Networ </Text>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                borderRadius: SIZES.radius,
                                marginBottom: 15,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => setBCTModalVisible(true)}
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    paddingHorizontal: 15,
                                    marginBottom: 0,
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                <Image
                                    source={BChainTypeValue.icon}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        marginRight: 8,
                                        borderRadius: 24,
                                    }}
                                />
                                <Text style={{ ...FONTS.font, color: colors.title, flex: 1 }}>
                                    {BChainTypeValue.coin}
                                </Text>
                                <FeatherIcon color={colors.text} size={20} name="chevron-down" />
                            </TouchableOpacity>
                        </View>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                // value='0xbc6b1972ea764159a4cf1c0'
                                value={BChainTypeValue.address}
                            // onChangeText={(text) => setdepAddress(text)}
                            />
                            <View
                                style={{
                                    flexDirection: "row",
                                    position: "absolute",
                                    right: 18,
                                    top: 12,
                                }}
                            >
                                <TouchableOpacity
                                    onPress={() => copyToClipboard()}
                                    style={{ marginRight: 12 }}>
                                    <Image
                                        style={{
                                            height: 20,
                                            width: 20,
                                            resizeMode: "contain",
                                            tintColor: COLORS.primary,
                                        }}
                                        source={ICONS.copy}
                                    />
                                </TouchableOpacity>

                                {/* <TouchableOpacity
                            onPress={() => {
                                refRBSheet.current.open();
                                savedDepDetails();
                            }}
                        >
                            <Image
                                style={{
                                    height: 18,
                                    width: 18,
                                    resizeMode: "contain",
                                    tintColor: COLORS.primary,
                                }}
                                source={ICONS.qr}
                            />
                        </TouchableOpacity> */}

                            </View>
                        </View>

                        {depAddress != "" ?

                            <View style={{
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: 175,
                                marginTop: 10,
                                position: 'relative',
                            }}>
                                <SvgQRCode backgroundColor='transparent' size={300} color='white' value={depAddress} />
                            </View>
                            : null}



                        <View style={{ alignItems: 'left', marginBottom: 30, marginTop: 10 }}>

                            <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                1.please note that only supported networks on bitzwallet platform are shown,if you deposit via another network your assets may be lost.
                            </Text>
                            <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                2.please don't deposit any other digital assets except TRC20_USDT to the above address
                            </Text>
                            <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                3.minimum deposit amount : 3 USDT(TRC20) to ensure the safety of your funds,your deposit request will be manually reviewed if your security strategy or password is changed.please wait for phone calls or emails from our staff.
                            </Text>
                            <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                4.any changes,we will inform you by announcement or email
                            </Text>
                            <Text style={{ ...FONTS.h6, color: colors.text, textAlign: 'justify', paddingBottom: 10 }}>
                                5.Deposit crypto within 30 minutes. Click 'Transaction Complete' after deposit. Est.arrival within 24hr
                            </Text>

                        </View>

                        <View style={{ flexDirection: 'row', alignItems: 'left', marginRight: 10 }}>
                            <View
                                style={[Platform.OS === 'ios' && {
                                    transform: [{
                                        scale: .8
                                    }],
                                    marginRight: 5,
                                }]}
                            >
                                <Checkbox
                                    // tintColors={{ true: COLORS.primary, false: colors.text }}
                                    // onCheckColor={COLORS.primary}
                                    // onTintColor={COLORS.primary}
                                    value={toggleCheckBox}
                                    onValueChange={(newValue) => {
                                        setToggleCheckBox(newValue);
                                        countDown();
                                    }}
                                />
                            </View>
                            <Text style={{ ...FONTS.font, color: colors.text, marginLeft: 8, paddingBottom: 10 }}>
                                I have read and agree to the terms and conditions
                            </Text>



                        </View>

                        {toggleCheckBox ?
                            <CustomButton
                                onPress={() => { dipositTry() }}
                                title={pendingTime + ' Transaction Complete'}
                            /> : ""}


                    </View>
                </View>
            </ScrollView>
        </>
    );
};

export default Deposit;
