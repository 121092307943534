import React, { useState } from 'react'
import {
    View,
    Text,
    ScrollView,
    ImageBackground,
    Image,
    TouchableOpacity,
    Modal,
    PermissionsAndroid,
    Platform,
} from 'react-native'
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import HeaderBar from '../../layout/header';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
// import { GlobalStyleSheet } from '../constants/styleSheet';
import * as Animatable from 'react-native-animatable';
import ButtonOutline from '../../components/Button/ButtonOutline';
import CustomButton from '../../components/customButton';
import CustomInput from '../../components/Input/CustomInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import PrivateApi from '../../api/PrivateAPI';
import { useIsFocused, useRoute } from '@react-navigation/native';
import OptionBar from '../../components/modal/OptionBar';

const BuyPlan = (navigation: any) => {

    const { colors } = useTheme();
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [availableAmount, setAvailableAmount] = useState('0.0000');
    const [entereAmount, setEntereAmount] = useState('0.0000');
    const [xyValues, setXYValues] = useState({
        id: 0,
        x: "0",
        y: "0",
        at: "2023-11-27T03:52:08.000Z",
    })
    const [xYPlans, setXYPlans] = useState([{
        id: 0,
        name: "Upper node",
        description: " ",
        x_min: "0.000000",
        x_max: "0.000000",
        leverage: "0.000000",
        duration: 0,
        daily_roi: "0.000000",
        monthly_roi: "0.000000",
        status: 1,
        created_at: "2023-11-23T13:48:02.000Z",
        updated_at: "2023-11-23T13:48:09.000Z"
    }]);
    const [myBalances, setMyBalances] = useState({
        id: 1,
        user: 1,
        usdt_balance: "0.0000",
        energy_balance: "0.0000",
        btc_balance: "0.0000",
        eth_balance: "0.0000",
        solana_balance: "0.0000",
        usdt_processing: "0.0000",
        energy_processing: "0.0000",
        btc_processing: "0.0000",
        eth_processing: "0.0000",
        solana_processing: "0.0000",
        created_at: null,
        updated_at: null,
        updated_by: null
    });
    const [profile, setProfile] = React.useState<any>({
        userName: "",
        email: "",
        "id": 1,
        mobile: null,
        profileImage: "",
        parent: null,
        "ref": "Mx9vXy5EcP",
        "status": 0,
        "type": "0",
        "verification": "328QADMfB4Jb"
    });

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setMyBalances(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getXYValues = () => {
        PrivateApi.get('/xy-value/value', tokens.user).then((res: any) => {
            console.log('res', res);
            setXYValues(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getXYPlans = () => {
        PrivateApi.get('/xy-plans', tokens.user).then((res: any) => {
            console.log('res', res);
            setXYPlans(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const setAvailableBalance = (value: any) => {
        if (value <= '0' || value == null) {
            setAvailableAmount('0.0000');
        }
        else {
            // if(parseFloat(xYPlans.x_min) > parseFloat(xyValues.x)){
            //     model('error', 'Error', 'The minimum amount of resonance is '+xYPlans.x_min+' XY');
            // }
            console.log(value)
            let calBalace = parseFloat(value) / parseFloat(xyValues.x);
            setEntereAmount(value);
            setAvailableAmount(calBalace.toString());
            console.log(calBalace)
        }
    }

    const getProfile = () => {
        PrivateApi.get('/user/my', tokens.user).then((res: any) => {
            console.log('res', res);
            setProfile(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const setBuyPlan = () => {
        console.log('x_min', xYPlans[1].x_min);
        console.log('x_max', xYPlans[1].x_max);
        if (parseFloat(xYPlans[1].x_min) > parseFloat(availableAmount)) {
            model('information-circle-sharp', 'Error', 'The minimum amount of resonance is ' + xYPlans[1].x_min);
        }
        else if (parseFloat(xYPlans[1].x_max) < parseFloat(availableAmount)) {
            model('information-circle-sharp', 'Error', 'The maximum amount of resonance is ' + xYPlans[1].x_max);
        }
        else {
            if (parseFloat(myBalances.usdt_balance) <= parseFloat(entereAmount)) {
                model('information-circle-sharp', 'Error', 'Insufficient balance, Your balance is ' + myBalances.usdt_balance + ' USDT');
            }
            else {
                let obj = {
                    userId: profile.id,
                    plan_id: xYPlans[1].id,
                    invested_x_value: availableAmount,
                }
                console.log(obj);
                PrivateApi.post('/xy-buy', tokens.user, obj).then((res: any) => {
                    console.log('res --- >  ', res);
                    if (res && res.id) {
                        // console.log("--------------------  RES  --------------------")
                        model('checkmark-done-sharp', 'Success', 'Successfully purchased');
                    } else {
                        // console.log("-------------------- No Responce   --------------------")
                        model('information-circle-sharp', 'Error', 'Unsuccessfully purchased');
                    }
                }).catch((err) => {
                    console.log('err', err);
                });
            }
        }
    }

    React.useEffect(() => {
        getXYValues();
        getXYPlans();
        getMyBalance();
        getProfile();
    }, [
        isFocused
    ]);


    return (

        <View
            style={{
                flex: 1,
                backgroundColor: colors.background,
            }}
        >
            <HeaderBar
                leftIcon={'back'}
                title={"Resonance upper node"}
            />
            <ScrollView
                contentContainerStyle={{
                    paddingBottom: 100,
                }}
            >

                <Animatable.View
                    animation="fadeInUp"
                    duration={1000}
                    delay={500}
                >
                    <View
                        style={{
                            padding: 15,
                        }}
                    >
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                marginLeft: 10,
                                marginBottom: 10,
                            }}
                        >
                            <Text style={{ ...FONTS.font, color: COLORS.white }}>Number of resonance Miner (2999.99 BiTC) </Text>
                        </View>
                        <ImageBackground
                            source={IMAGES.bg1}
                            style={{
                                flexDirection: 'row',
                                paddingHorizontal: 18,
                                paddingVertical: 18,
                                borderRadius: SIZES.radius_lg,
                                overflow: 'hidden',
                                alignItems: 'center',
                                height: 120,
                            }}
                        >
                            <View style={{ flex: 1, }}>
                                <View style={{ marginBottom: 10 }}>
                                    <CustomInput
                                        inputBorder
                                        value={''}
                                        keyboardType='number-pad'
                                        placeholder="Enter the amount of resonance"
                                        onChangeText={(value: any) => setAvailableBalance(value)}
                                    />
                                </View>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: 3,
                                    }}
                                >
                                    <Text style={{ ...FONTS.fontSm, color: COLORS.text, opacity: 0.8 }}>Account available BiTC</Text>
                                    <Text style={{ ...FONTS.h5, color: COLORS.white }}>{availableAmount}</Text>
                                </View>
                            </View>
                        </ImageBackground>

                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                marginLeft: 10,
                                marginTop: 10,
                                marginBottom: 30,
                            }}
                        >
                            <Text style={{ ...FONTS.fontSm, color: COLORS.light }}>If the available BiTC of the account is insufficient, the remaining amount can be resonated in other currencies of the account </Text>
                        </View>

                        <Animatable.View
                            animation="fadeInUpBig"
                            duration={1000}
                            delay={500}
                        >
                            <CustomButton
                                onPress={setBuyPlan}
                                title="Immediate resonance"
                            />
                        </Animatable.View>

                    </View>
                </Animatable.View>


                <Image
                    source={IMAGES.tokenAllocation}
                    style={{
                        height: "95%",
                        width: "100%",
                        borderRadius: 10,
                    }}
                />




            </ScrollView>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

        </View>
    )
}

export default BuyPlan

