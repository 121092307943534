import React from 'react';
import WalletBalance from './walletBalance';
import DepositCryptoModal from '../../components/modal/depositCryptoModal';
import WithdrawCryptoModal from '../../components/modal/withdrawCryptoModal';
import RBSheet from "react-native-raw-bottom-sheet";
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { View } from 'react-native-animatable';
import { COLORS } from '../../constants/theme';
import { Colors } from 'react-native/Libraries/NewAppScreen';


class WalletBalanceList extends React.Component {


  state = {
    data: this.props.data,
    swiping: false,
    rbSheet: '',
  }

  componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data })
    }
  }




  cleanFromScreen(id: any) {
    const data = this.state.data.filter(item => {
      return item.id !== id;
    });
    this.setState({ data });
  }


  renderItems() {

    return this.state.data.map((item: any) => {

      return (
        <WalletBalance
          theme={this.props.theme}
          key={item.id}
          swipingCheck={(swiping) => this.setState({ swiping })}
          coin={item.coin}
          coinName={item.coinName}
          amount={item.amount}
          trade={item.trade}
          data={item.data}
          btc={item.btc}
          tag={item.tag}
          id={item.id}
          cleanFromScreen={(id) => this.cleanFromScreen(id)}
          leftButtonPressed={() => console.log('Delete')}
          // withdrawButtonPressed={() => { this.setState({ rbSheet: 'withdraw' }), this.RBSheet.open() }}
          withdrawButtonPressed={() => this.props.navigate("Withdraw")}
          tradeButtonPressed={() => this.props.navigate(this.props.destination)}


          depositButtonPressed={() => { this.props.navigate("Deposit", { coin: item.coin, coinName: item.coinName, tag: item.tag }) }}
          // depositButtonPressed={() => { this.setState({ rbSheet: 'deposit' }), this.RBSheet.open() }}
        />
      );
    });


  }


  render() {

    return (
      <>


        <RBSheet
          ref={ref => {
            this.RBSheet = ref;
          }}
          closeOnDragDown={true}
          height={this.state.rbSheet === "deposit" ? 300 : this.state.rbSheet === "withdraw" ? 500 : 430}
          openDuration={300}
          customStyles={{
            wrapper: {
              // backgroundColor: this.props.theme.colors.modalBackLayer,
            },
            container: {
              backgroundColor: "#3F3F46", // Change this to change the modal background
              borderTopLeftRadius: 15,
              borderTopRightRadius: 15,
            },
            draggableIcon: {
              width: 90,
              backgroundColor: this.props.theme.colors.borderColor,
            }
          }}
        >
          {this.props.theme.dark &&
            <View
              // colors={["rgba(22,23,36,.7)","rgba(22,23,36,0)"]}

              style={{
                position: 'absolute',
                height: '100%',
                width: '100%',
              }}
            >
            </View>
          }
          {this.state.rbSheet === "deposit" ?
            <DepositCryptoModal />
            :
            this.state.rbSheet === "withdraw" ?
              <WithdrawCryptoModal />
              :
              <DepositCryptoModal />
          }
        </RBSheet>


        <GestureHandlerRootView>
          {
            console.log("+++++++++++++++++++++++++++++++++++++++++++++")
          }
          {
            console.log(this.state.data)
          }

          {this.renderItems()}
        </GestureHandlerRootView>
      </>
    );
  }

}

export default WalletBalanceList;