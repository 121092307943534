
import React, { useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    ScrollView,
    Image,
    Alert,
    Platform,
    Modal,
} from 'react-native';
import { useTheme } from '@react-navigation/native';
import { FONTS, SIZES, COLORS, ICONS, IMAGES } from '../../constants/theme';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import CustomButton from '../../components/customButton';
import * as Animatable from 'react-native-animatable';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Checkbox from 'expo-checkbox';
import PublicAPI from '../../api/PublicApi';
import OptionBar from '../../components/modal/OptionBar';
import HeaderBar from '../../layout/header';
import PrivateAPI from '../../api/PrivateAPI';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

const login = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="svg-main-icon">
<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <rect x="0" y="0" width="24" height="24"/>
    <rect fill="#3899f3" opacity="0.3" transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) " x="8" y="6" width="2" height="12" rx="1"/>
    <path d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z" fill="#3899f3" fill-rule="nonzero" opacity="0.3" transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "/>
    <path d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z" fill="#3899f3" fill-rule="nonzero" transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "/>
</g>
</svg>`;

const Trc20Add = (props: any) => {

    const { navigation } = props;

    const tokens: any = useSelector((state: RootState) => state.auth);

    const theme = useTheme();
    const { colors } = useTheme();
    const [handlePassword, setHandlePassword] = useState(true);
    const [handlePassword2, setHandlePassword2] = useState(true);
    const [isFocused, setisFocused] = useState(false);
    const [isFocused2, setisFocused2] = useState(false);
    const [isFocused3, setisFocused3] = useState(false);
    const [isFocused4, setisFocused4] = useState(false);
    const [isFocused5, setisFocused5] = useState(false);


    const [userName, setUserName] = useState('');

    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');




    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setModalVisible(true);
        setTimeout(() => {
            setModalVisible(false);
        }, 3000);
    }

    const handleRegister = async () => {
        // Perform registration logic here
        if (password !== password2) {
            model('information-circle-sharp', 'Error', 'Passwords do not match');
            console.log('Passwords do not match');
        } else {
            setIsSubmitting(true);
            console.log('Password:', password);
            console.log('Password:', password2);
            try {
                const result = await PrivateAPI.post('/user/addTRC20', tokens.user, { trc20_address: userName, withdraw_password: password });
                console.log(result);
                if (result) {
                    model('checkmark-done-sharp', 'Confirm', 'Withdrawal address created successfully');
                    // navigation.navigate('Profile');
                } else {
                    console.log('User creation failed');
                }
                setIsSubmitting(false);
            } catch (error) {
                console.log('Error creating user:', error);
                model('information-circle-sharp', 'Error', 'Withdrawal address creation failed');
                setIsSubmitting(false);
            }
            setIsSubmitting(false);
        }
    };


    return (
        <View style={{ backgroundColor: COLORS.secondary, flex: 1 }}>

            <HeaderBar
                leftIcon={'back'}
                title={"Know Your Crypto"}
            />

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <View style={{
                height: 140,
                backgroundColor: COLORS.secondary,
                position: 'absolute',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}>

                <Image
                    source={IMAGES.logoFullWhite}
                    style={{
                        width: 180,
                        resizeMode: 'contain',
                        marginBottom: 20,
                    }}
                />
            </View>

            <ScrollView
                contentContainerStyle={{ flexGrow: 1 }}
            >

                <Animatable.View
                    animation="fadeInUpBig"
                    duration={1000}
                    style={{ paddingTop: 140, flex: 1 }}>

                    {!theme.dark &&
                        <View
                            style={{
                                height: 30,
                                backgroundColor: 'rgba(255,255,255,.2)',
                                left: 20,
                                right: 20,
                                position: 'absolute',
                                top: 114,
                                borderRadius: 40,
                            }}
                        />
                    }

                    <View
                        style={{
                            ...styles.container,
                            backgroundColor: colors.background,
                            position: 'relative',
                        }}>
                        {theme.dark &&
                            <View
                                // colors={["rgba(22,23,36,.7)", "rgba(22,23,36,0)"]}
                                style={{
                                    position: 'absolute',
                                    height: '100%',
                                    width: '100%',
                                }}
                            >
                            </View>
                        }

                        <View style={{
                            paddingHorizontal: SIZES.padding,
                            paddingTop: 20,
                            flex: 1,
                        }}>

                            <View style={{ alignItems: 'center', paddingTop: 15, marginBottom: 30 }}>
                                <Animatable.Text
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={700}
                                    style={{ ...FONTS.h3, color: colors.text }}>Withdrawal Confirmation</Animatable.Text>
                                <Animatable.Text
                                    animation="fadeInUp"
                                    duration={1000}
                                    delay={700}
                                    style={{ ...FONTS.font, color: colors.text }}>Enter your Withdraw Details</Animatable.Text>
                            </View>

                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1000}
                                style={[styles.inputGroup]}>
                                <Text style={{ ...FONTS.fontSm, color: colors.text, marginBottom: 6 }}>USDT - TRC20 Address</Text>
                                <View
                                    style={{
                                        ...GlobalStyleSheet.shadow,
                                        backgroundColor: colors.card,
                                        borderRadius: SIZES.radius,
                                    }}
                                >
                                    <View style={styles.inputIco}>
                                        <FeatherIcon name='user' color={COLORS.primary} size={18} />
                                    </View>
                                    <TextInput
                                        onFocus={() => setisFocused5(true)}
                                        onBlur={() => setisFocused5(false)}
                                        onChangeText={(text) => setUserName(text)}
                                        style={[
                                            styles.input,
                                            {
                                                color: colors.text,
                                                backgroundColor: colors.card
                                            },
                                            isFocused5 ? styles.inputActive : ""
                                        ]}
                                        placeholderTextColor={colors.text}
                                        placeholder='Enter your TRC20 Address'
                                    />
                                </View>
                            </Animatable.View>

                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1200}
                                style={styles.inputGroup}>
                                <Text style={{ ...FONTS.fontSm, color: colors.text, marginBottom: 6 }}>Withdrawal Password</Text>
                                <View
                                    style={{
                                        ...GlobalStyleSheet.shadow,
                                        backgroundColor: colors.card,
                                        borderRadius: SIZES.radius,
                                    }}
                                >
                                    <View style={styles.inputIco}>
                                        <FeatherIcon name='lock' color={COLORS.primary} size={18} />
                                    </View>
                                    <TextInput
                                        onFocus={() => setisFocused2(true)}
                                        onBlur={() => setisFocused2(false)}
                                        onChangeText={(text) => setPassword(text)}
                                        style={[
                                            styles.input,
                                            {
                                                color: colors.title,
                                                backgroundColor: colors.card
                                            },
                                            isFocused2 ? styles.inputActive : ""
                                        ]}
                                        placeholderTextColor={colors.text}
                                        placeholder='Create password'
                                        secureTextEntry={handlePassword}
                                    />
                                    {(handlePassword) ?
                                        <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandlePassword(false)}>
                                            <FeatherIcon name='eye' color={COLORS.primary} size={18} />
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandlePassword(true)}>
                                            <FeatherIcon name='eye-off' color={COLORS.primary} size={18} />
                                        </TouchableOpacity>
                                    }
                                </View>
                            </Animatable.View>

                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1400}
                                style={styles.inputGroup}>
                                <Text style={{ ...FONTS.fontSm, color: colors.title, marginBottom: 6 }}>Confirm password</Text>
                                <View
                                    style={{
                                        ...GlobalStyleSheet.shadow,
                                        backgroundColor: colors.card,
                                        borderRadius: SIZES.radius,
                                    }}
                                >
                                    <View style={styles.inputIco}>
                                        <FeatherIcon name='lock' color={COLORS.primary} size={18} />
                                    </View>
                                    <TextInput
                                        onFocus={() => setisFocused3(true)}
                                        onBlur={() => setisFocused3(false)}
                                        onChangeText={(text) => setPassword2(text)}
                                        style={[
                                            styles.input,
                                            {
                                                color: colors.title,
                                                backgroundColor: colors.card
                                            },
                                            isFocused3 ? styles.inputActive : ""
                                        ]}
                                        placeholderTextColor={colors.text}
                                        placeholder='Confirm password'
                                        secureTextEntry={handlePassword2}
                                    />
                                    {(handlePassword2) ?
                                        <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandlePassword2(false)}>
                                            <FeatherIcon name='eye' color={COLORS.primary} size={18} />
                                        </TouchableOpacity>
                                        :
                                        <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandlePassword2(true)}>
                                            <FeatherIcon name='eye-off' color={COLORS.primary} size={18} />
                                        </TouchableOpacity>
                                    }
                                </View>
                            </Animatable.View>


                            <Animatable.View
                                animation="fadeInUp"
                                duration={1000}
                                delay={1500}
                            >
                                <CustomButton
                                    // onPress={() => { navigation.navigate('signin') }}
                                    onPress={handleRegister} disabled={isSubmitting}
                                    title="Confirm Withdrawal Data"
                                />
                            </Animatable.View>

                        </View>
                    </View>
                </Animatable.View>
            </ScrollView>
        </View>
    );
};


const styles = StyleSheet.create({
    container: {
        flex: 1,
        borderTopLeftRadius: SIZES.radius_lg,
        borderTopRightRadius: SIZES.radius_lg,
        overflow: 'hidden',
        marginTop: -16,
    },
    inputGroup: {
        position: 'relative',
        marginBottom: 40,
    },
    input: {
        height: 48,
        borderWidth: 1,
        borderColor: 'transparent',
        fontSize: SIZES.font,
        borderRadius: SIZES.radius,
        paddingLeft: 50,
    },
    inputActive: {
        borderColor: COLORS.primary,
    },
    inputGroupActive: {
        shadowColor: COLORS.primary,
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: 0.27,
        shadowRadius: 4.65,
        elevation: 6,
    },
    inputIco: {
        position: 'absolute',
        left: 17,
        top: 15,
        tintColor: COLORS.primary,
        height: 18,
        width: 18,
        resizeMode: 'contain',
        zIndex: 1,
    },
    seprator: {
        height: 1,
        width: '100%',
        position: 'absolute',
    },
    eyeIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        height: 48,
        width: 48,
        alignItems: 'center',
        justifyContent: 'center',
    },
    eyeImg: {
        height: 20,
        width: 20,
    }
})

export default Trc20Add;
