import React, { useEffect, useRef, useState } from 'react';
import {
    View,
    Text,
    Image,
    TextInput,
    TouchableOpacity,
    Modal,
    Platform
} from 'react-native';


import RBSheet from "react-native-raw-bottom-sheet";
import { ScrollView } from 'react-native-gesture-handler';
import { useTheme } from '@react-navigation/native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { COLORS, FONTS, ICONS, IMAGES, SIZES } from '../../constants/theme';
import { GlobalStyleSheet } from '../../constants/styleSheet';
import { RootState } from '../../redux/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../customButton';
import WithdrawCryptoQr from './withdrawCryptoQr';
import WithdrawCryptoOTP from './withdrawCryptoOTP';
import OptionBar from '../../components/modal/OptionBar';
import PrivateApi from '../../api/PrivateAPI';


const CoinItem = [
    {
        icon: IMAGES.trc20,
        coin: 'TRC20',
        sortName: 'TRC',
    },
    {
        icon: IMAGES.erc20,
        coin: 'ERC20',
        sortName: 'ERC',
    },
    {
        icon: IMAGES.solana,
        coin: 'SOLANA',
        sortName: 'SOL',
    },

];

const WithdrawCryptoModal = () => {

    const { colors } = useTheme();
    const theme = useTheme();
    const refRBSheet = useRef();
    const tokens: any = useSelector((state: RootState) => state.auth);

    const [ItemValue, setItemValue] = useState(CoinItem[0]);
    const [modalVisible, setModalVisible] = useState(false);
    const [withdrawRBSheet, setWithdrawRBSheet] = useState('');
    const [WithdrawAddress, setWithdrawAdress] = useState('');
    const [WithdrawAmount, setWithdrawAmount] = useState(0);
    const [WithdrawTotal, setWithdrawTotal] = useState(0);
    const [alertModalVisible, setAlertModalVisible] = useState(false);
    const [modelIcon, setmodelIcon] = useState('');
    const [modelTitle, setmodelTitle] = useState('');
    const [modelDesc, setmodelDesc] = useState('');
    const [USDTValue, setUSDTValue] = useState('');

    const model = (icon: any, title: any, desc: any) => {
        setmodelIcon(icon);
        setmodelTitle(title);
        setmodelDesc(desc);
        setAlertModalVisible(true);
        setTimeout(() => {
            setAlertModalVisible(false);
        }, 3000);

    }

    const getMyBalance = () => {
        PrivateApi.get('/balance/my', tokens.user).then((res1: any) => {
            setUSDTValue(res1.usdt_balance);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const saveWithRequest = () => {
        if (WithdrawAddress) {
            if (Number(WithdrawAmount) < 100) {
                console.log(WithdrawAmount);
                model('information-circle-sharp', 'Error', 'Minimum withdraw amount is 100 USDT');
            }
            else {
                if (Number(WithdrawAmount) > Number(USDTValue)) {
                    model('information-circle-sharp', 'Error', 'Insufficient balance');
                }
                else {
                    let obj = {
                        "amount": WithdrawAmount,
                        "network": ItemValue.coin,
                        "toAddress": WithdrawAddress,
                        "fee": 4,
                    }
                    PrivateApi.post('/withdraw/create', tokens.user, obj).then((res: any) => {
                        console.log('res', res);
                        //model('information-circle-sharp', 'Success', 'Please check your email and get verification code');
                        getMyBalance();
                        setWithdrawAmount(0.0000);
                        setWithdrawAdress('');
                        setWithdrawTotal(0.0000);
                        setWithdrawRBSheet('otp'); refRBSheet.current.open();
                    }).catch((err) => {
                        console.log('err', err);
                        model('information-circle-sharp', 'Error', 'Unsuccessfully withdraw');
                    });

                }
            }
        }
        else {
            model('information-circle-sharp', 'Error', 'Please enter wallet address');
        }

    }

    const calwithdrawAmount = (txtAmunt: any) => {
        setWithdrawTotal(0);
        let tot = Number(txtAmunt) - 4;
        if (tot < 0) {
            setWithdrawTotal(0);
            setWithdrawAmount(0);
        }
        else {
            setWithdrawTotal(tot);
            setWithdrawAmount(tot);
        }


    }

    useEffect(() => {
        getMyBalance();
    }, []);


    return (
        <>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
            >
                <View
                    style={[{ flex: 1, backgroundColor: colors.card }, Platform.OS === "ios" && { paddingTop: 100 }]}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <TouchableOpacity
                            onPress={() => setModalVisible(false)}
                            style={{
                                padding: 12,
                            }}
                        >
                            <FeatherIcon
                                name='arrow-left'
                                size={20}
                                color={colors.title}
                            />
                        </TouchableOpacity>
                        <TextInput
                            autoFocus={true}
                            style={{
                                ...FONTS.font,
                                color: colors.title,
                                flex: 1,
                                paddingHorizontal: 10,
                                top: 1,
                            }}
                            placeholder='Search here..'
                            placeholderTextColor={colors.text}
                        />
                    </View>
                    <ScrollView>
                        {CoinItem.map((data, index) => (
                            <View
                                key={index}
                            >
                                <TouchableOpacity
                                    onPress={() => { setItemValue(data); setModalVisible(false) }}
                                    style={[{
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        paddingVertical: 12,
                                        paddingHorizontal: 15,
                                    }, ItemValue.coin === data.coin && {

                                    }]}
                                >
                                    <Image
                                        style={{
                                            height: 30,
                                            width: 30,
                                            borderRadius: 30,
                                            marginRight: 10,
                                        }}
                                        source={data.icon}
                                    />
                                    <Text style={{ ...FONTS.font, ...FONTS.fontMedium, color: colors.title, flex: 1 }}>{data.coin}</Text>
                                    <Text style={{ ...FONTS.fontSm, color: colors.text }}>{data.sortName}</Text>
                                </TouchableOpacity>
                                <View
                                    // start={{x: 0, y: 0}} end={{x: 1, y: 0}}
                                    // colors={theme.dark ? ["rgba(255,255,255,0)","rgba(255,255,255,.1)","rgba(255,255,255,0)"] : ["rgba(0,0,0,0)","rgba(0,0,0,.1)","rgba(0,0,0,0)"]}
                                    style={{
                                        height: 1,
                                        width: '100%',
                                    }}
                                >
                                </View>
                            </View>
                        ))}
                    </ScrollView>
                </View>
            </Modal>

            <Modal
                animationType="slide"
                transparent={true}
                visible={alertModalVisible}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setAlertModalVisible(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} />
                </View>
            </Modal>

            <RBSheet
                ref={refRBSheet}
                closeOnDragDown={true}
                height={withdrawRBSheet === 'Qrcode' ? 650 : withdrawRBSheet === 'otp' ? 240 : 240}
                openDuration={300}
                customStyles={{
                    container: {
                        backgroundColor: colors.background,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                    },
                    draggableIcon: {
                        marginTop: 5,
                        marginBottom: 0,
                        height: 5,
                        width: 90,
                        backgroundColor: colors.borderColor,
                    }
                }}
            >
                {theme.dark &&
                    <View
                        // colors={["rgba(22,23,36,.7)","rgba(22,23,36,0)"]}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                        }}
                    >
                    </View>
                }
                {
                    (withdrawRBSheet === 'Qrcode') ? <WithdrawCryptoQr /> :
                        (withdrawRBSheet === 'otp') ? <WithdrawCryptoOTP /> : <WithdrawCryptoOTP />
                }

            </RBSheet>


            <View
                style={{
                    paddingHorizontal: 15,
                    marginTop: 15,
                }}
            >
                <Text style={{ ...FONTS.h5, color: colors.title }}>Withdraw</Text>
            </View>
            <View style={{ flex: 1 }}>
                <ScrollView
                    contentContainerStyle={{
                        paddingHorizontal: 15,
                        paddingVertical: 20,
                    }}
                    showsVerticalScrollIndicator={true}>
                    <TouchableOpacity activeOpacity={1}>
                        <View
                            style={{
                                backgroundColor: "#3F3F46", // change this to change the card color
                                borderRadius: SIZES.radius,
                                marginBottom: 15,
                                ...GlobalStyleSheet.shadow
                            }}
                        >
                            <TouchableOpacity
                                onPress={() => setModalVisible(true)}
                                style={{
                                    backgroundColor: colors.card,
                                    ...GlobalStyleSheet.formControl,
                                    marginBottom: 0,
                                    paddingHorizontal: 15,
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <Image
                                    source={ItemValue.icon}
                                    style={{
                                        height: 24,
                                        width: 24,
                                        marginRight: 8,
                                        borderRadius: 24,
                                    }}
                                />
                                <Text style={{ ...FONTS.font, color: colors.title, flex: 1 }}>{ItemValue.coin}</Text>
                                <FeatherIcon color={colors.text} size={20} name='chevron-down' />
                            </TouchableOpacity>
                        </View>

                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                placeholder='Wallet address'
                                placeholderTextColor={colors.text}
                                onChangeText={(text) => setWithdrawAdress(text)}
                                value={WithdrawAddress.toString()}
                            //value='0xbc6b1972ea764159a4cf1c0'
                            />
                            <View style={{
                                flexDirection: 'row',
                                position: 'absolute',
                                right: 18,
                                top: 12,
                            }}>
                                <TouchableOpacity
                                    onPress={() => { setWithdrawRBSheet('Qrcode'), refRBSheet.current.open() }}
                                >
                                    <Image
                                        style={{
                                            height: 18,
                                            width: 18,
                                            resizeMode: 'contain',
                                            tintColor: COLORS.primary
                                        }}
                                        source={ICONS.qr} />
                                </TouchableOpacity>
                            </View>
                        </View>

                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ ...FONTS.font, color: colors.text, marginRight: 5 }}>Available:</Text>
                                <Text style={{ ...FONTS.font, color: COLORS.primary }}>{USDTValue} USDT</Text>
                            </View>
                        </View>
                        <View
                            style={{
                                backgroundColor: colors.card,
                                ...GlobalStyleSheet.formControl,
                                ...GlobalStyleSheet.shadow,
                            }}
                        >
                            <TextInput
                                style={{ ...GlobalStyleSheet.Input, color: colors.title }}
                                keyboardType="numeric"
                                inputMode='numeric'
                                placeholder='Amount'
                                onChangeText={(text) => calwithdrawAmount(text)}
                                placeholderTextColor={colors.text}
                            />
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 8 }}>
                            <View style={{ flexDirection: 'row' }}>
                                <Text style={{ ...FONTS.font, color: colors.text, marginRight: 5 }}>Fee:</Text>
                                <Text style={{ ...FONTS.font, color: COLORS.primary }}>4.0000 USDT</Text>
                            </View>
                        </View>

                        <View style={{ alignItems: 'center', marginBottom: 30, marginTop: 10 }}>
                            <Text style={{ ...FONTS.h2, color: colors.title }}>{WithdrawTotal} USDT</Text>
                            <Text style={{ ...FONTS.font, color: colors.text }}>Receive amount</Text>
                        </View>
                        <CustomButton
                            onPress={() => { saveWithRequest() }}
                            title='Continue'
                        />
                    </TouchableOpacity>
                </ScrollView>
            </View>
        </>
    )
}

export default WithdrawCryptoModal;