import React, { useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    Image,
    ImageBackground,
    Button,
    Modal,
} from 'react-native';

import FeatherIcon from 'react-native-vector-icons/Feather';
import * as Animatable from 'react-native-animatable';
import { Defs, LinearGradient, Stop } from "react-native-svg";
// import { VictoryArea, VictoryChart } from 'victory-native';
import { VictoryArea, VictoryChart } from '../components/chartModules/chart';
import { FONTS, SIZES, COLORS, ICONS, IMAGES } from '../constants/theme';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import PrivateApi from '../api/PrivateAPI';
import { useIsFocused, useRoute } from '@react-navigation/native';
import { useTheme } from '@react-navigation/native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { GlobalStyleSheet } from '../constants/styleSheet';

const chartTab = [
    {
        status: '1D'
    },
    {
        status: '1W'
    },
    {
        status: '1M'
    },
    {
        status: '1Y'
    },
    {
        status: 'All'
    }
]


const hourData = [50, 20, 30, 20, 20, 60];
const dayData = [35, 50, 45, 38, 20, 45, 65, 28, 45, 52];
const weekData = [40, 55, 31, 65, 45, 55, 42, 30, 65];
const monthData = [60, 50, 45, 38, 20, 45, 65, 28, 45, 52];
const yearData = [25, 35, 50, 32, 25, 52, 62, 34, 45, 25];
const allData = [20, 40, 35, 50, 25, 60, 50, 70, 60, 75, 20, 50, 43];

const BalanceChart = ({ headerTitle, header }) => {

    const { colors } = useTheme();
    const navigation = useNavigation();
    const isFocused = useIsFocused();
    const tokens: any = useSelector((state: RootState) => state.auth);
    const [handleViewAmount, setHandleViewAmount] = useState(true);
    const [modalDesRule, setModalDesRule] = useState(false);
    const [status, setStatus] = useState('All');
    const [chartdata, setChartdata] = useState(allData);

    const setChartStatusFilter = status => {
        switch (status) {
            case "1D":
                setChartdata(dayData);
                break;
            case "1W":
                setChartdata(weekData);
                break;
            case "1M":
                setChartdata(monthData);
                break;
            case "1Y":
                setChartdata(yearData);
                break;
            case "All":
                setChartdata(allData);
                break;
        }
        setStatus(status)
    }

    const [xyValues, setXYValues] = useState({
        id: 0,
        x: "0.0000",
        y: "0.0000",
        at: "2023-11-27T03:52:08.000Z",
    })

    const [amount, setAmount] = useState('$940,563.84');
    const [isHidden, setIsHidden] = useState(true);
    const [myXYBalance, setMyXYBalance] = useState({
        pool_x_value: "0.0000",
        released_x_value: "0.0000",
        claim_x_value: "0.0000",
        y_balance: "0.0000",
    });

    const toggleVisibility = () => {
        setIsHidden(!isHidden);
    };

    const getXYBalancesMy = () => {
        PrivateApi.get('/xy-buy/my', tokens.user).then((res: any) => {
            setMyXYBalance(res);
            console.log('//////////////////////////////')
            console.log(res);
            console.log('//////////////////////////////')
        }).catch((err) => {
            console.log('err', err);
        });
    }

    const getXYValues = () => {
        PrivateApi.get('/xy-value/value', tokens.user).then((res: any) => {
            console.log('res', res);
            setXYValues(res);
        }).catch((err) => {
            console.log('err', err);
        });
    }

    React.useEffect(() => {
        getXYBalancesMy();
    }, [
        isFocused
    ]);

    React.useEffect(() => {
        getXYBalancesMy();
        getXYValues();
    }, [

    ]);


    return (

        <ImageBackground
            source={IMAGES.bg1}
            style={[{
                alignItems: 'center',
                backgroundColor: COLORS.secondary,
                paddingBottom: 20,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
                overflow: 'hidden',
            }, header === false && {
                paddingTop: 30,
            }]}
        >
            {header != false &&
                <View
                    style={{
                        paddingHorizontal: 15,
                        paddingVertical: 10,
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        marginBottom: 5,
                    }}
                >
                    <Text style={{ ...FONTS.h6, color: COLORS.white, flex: 1 }}>{headerTitle ? headerTitle : "Home"}</Text>
                    {/* <TouchableOpacity
                        onPress={() => navigation.navigate('notifications')}
                        style={{
                            height:40,
                            width:40,
                            borderRadius:SIZES.radius,
                            alignItems:'center',
                            justifyContent:'center',
                            backgroundColor:'rgba(255,255,255,.1)',
                            marginRight:6,
                        }}
                    >
                        <Image
                            source={ICONS.bell}
                            style={{
                                height:20,
                                width:20,
                            }}
                        />
                        <View
                            style={{
                                height:5,
                                width:5,
                                borderRadius:6,
                                backgroundColor:"#B94646",
                                position:'absolute',
                                top:14,
                                right:13,
                            }}
                        />
                    </TouchableOpacity> */}
                    <TouchableOpacity
                        onPress={() => setModalDesRule(true)}
                        style={{
                            height: 30,
                            width: 135,
                            borderRadius: SIZES.radius,
                            alignItems: 'center',
                            justifyContent: 'center',
                            // backgroundColor: 'rgba(255,255,255,.1)',
                        }}
                    >
                        {/* <FeatherIcon
                            name='grid'
                            size={20}
                            color={COLORS.white}
                        /> */}
                        <Animatable.Text
                            animation="zoomInUp"
                            duration={1000}
                            delay={500}
                            style={{ ...FONTS.fontXs, color: COLORS.white }}>FLT Destruction rule</Animatable.Text>
                    </TouchableOpacity>
                </View>
            }
            <Animatable.Text
                animation="zoomInUp"
                duration={1000}
                delay={500}
                style={{
                    ...FONTS.fontXs,
                    color: 'rgba(255,255,255,.6)',
                    marginBottom: 8,
                }}>Pool </Animatable.Text>
            <Animatable.Text
                animation="zoomInUp"
                duration={1000}
                delay={500}
                style={{ ...FONTS.h2, color: COLORS.white }}>
                {isHidden ? '*********' : myXYBalance.pool_x_value}
                <TouchableOpacity style={styles.eyeIcon} onPress={toggleVisibility}>
                    <FeatherIcon
                        name={isHidden ? 'eye' : 'eye-off'}
                        color={COLORS.white}
                        size={18}
                    />
                </TouchableOpacity>
            </Animatable.Text>
            {/* <Animatable.Text
                animation="zoomInUp"
                duration={1000}
                delay={500}
                secureTextEntry={handleViewAmount}
                style={{ ...FONTS.h2, color: COLORS.white }}>$940,563.84
                {(handleViewAmount) ?
                    <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandleViewAmount(false)}>
                        <FeatherIcon name='eye' color={COLORS.white} size={18} />
                    </TouchableOpacity>
                    :
                    <TouchableOpacity style={styles.eyeIcon} onPress={() => setHandleViewAmount(true)}>
                        <FeatherIcon name='eye-off' color={COLORS.white} size={18} />
                    </TouchableOpacity>
                }
            </Animatable.Text> */}
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255,255,255,.1)',
                    borderRadius: 30,
                    paddingHorizontal: 20,
                    paddingVertical: 6,
                    marginTop: 8,
                }}
            >
                <Text style={{ ...FONTS.fontXs, color: "rgba(255,255,255,.6)", lineHeight: 16 }}>BiTC: {xyValues.x}</Text>
                <Text style={{ ...FONTS.fontXs, color: COLORS.primary, lineHeight: 16, ...FONTS.fontMedium }}></Text>
            </View>
            <View style={{ width: SIZES.width, alignItems: 'flex-start', paddingTop: 35 }}>

                <VictoryChart
                    height={60}
                    padding={0}>
                    <Defs>
                        <LinearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="gradientStroke" >
                            <Stop offset="0%" stopOpacity={.3} stopColor={COLORS.primary} />
                            <Stop offset="70%" stopOpacity={0} stopColor={COLORS.primary} />
                            <Stop offset="100%" stopOpacity={0} stopColor={COLORS.primary} />
                        </LinearGradient>
                    </Defs>
                    <VictoryArea
                        domain={{ y: [0, 80] }}
                        interpolation="natural"
                        animate={{
                            duration: 500,
                            onLoad: { duration: 1000 }
                        }}
                        style={{
                            data: {
                                fill: 'url(#gradientStroke)', fillOpacity: 0.6, stroke: COLORS.primary, strokeWidth: 2
                            },
                            labels: {
                                fontSize: 15,
                                fill: ({ datum }) => datum.x === 3 ? "#000000" : "#c43a31"
                            },

                        }}
                        data={chartdata}
                        labels={({ datum }) => datum.x}
                    />
                </VictoryChart>

            </View>
            <View style={{ flexDirection: 'row' }}>
                {
                    chartTab.map((e, index) => (
                        <TouchableOpacity
                            key={index}
                            onPress={() => setChartStatusFilter(e.status)}
                            style={[
                                styles.tabBtn,
                                {
                                    backgroundColor: 'rgba(255,255,255,.1)'
                                },
                                status === e.status && styles.btnTabActive,
                            ]}
                        >
                            <Text style={[
                                {
                                    color: COLORS.white,
                                    ...FONTS.font
                                },
                                status === e.status && styles.btnTabActiveText
                            ]}
                            >{e.status}</Text>
                        </TouchableOpacity>
                    ))
                }
            </View>

            <Modal
                animationType="slide"
                transparent={true}
                visible={modalDesRule}
            >
                <View style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    position: 'relative',
                }}>
                    <TouchableOpacity
                        activeOpacity={1}
                        onPress={() => setModalDesRule(false)}
                        style={{
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(0,0,0,.3)',
                        }}
                    />
                    {/* <OptionBar title={modelTitle} description={modelDesc} icon={modelIcon} /> */}
                    {/* <LoginModal/> */}

                    <View
                        style={{
                            backgroundColor: "#3F3F46",
                            maxWidth: 330,
                            width: '100%',
                            borderRadius: SIZES.radius,
                            paddingHorizontal: 10,
                            paddingVertical: 10,
                        }}
                    >
                        <View style={{
                            paddingHorizontal: 15,
                            borderBottomWidth: 1,
                            borderColor: colors.borderColor,
                            paddingVertical: 0,
                            marginBottom: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}>
                            <Text style={{ ...FONTS.h5, color: colors.title }}>Destruction Rules</Text>
                            <TouchableOpacity style={{ padding: 10 }} onPress={() => setModalDesRule(false)}>
                                <FeatherIcon name={'x'} size={24} color={colors.title} />
                            </TouchableOpacity>
                        </View>
                        <View style={GlobalStyleSheet.container}>

                            <View style={{ alignItems: 'center', marginBottom: 10 }}>
                                <Ionicons name='clipboard' style={{ marginBottom: 8 }} color={COLORS.info} size={40} />
                                <Text style={{ ...FONTS.h5, fontWeight: '900', color: colors.title, marginBottom: 6 }}>FLT Destruction Rules</Text>
                                <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5, marginBottom: 5 }}>If the pool value is 80 ≤ 3000,
                                    FLT equal to 10% of the daily share will be burned, otherwise that value will be destroyed from the daily share.
                                </Text>
                                {/* <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 3000 ≤ 5000       9%</Text>
                                <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 5000 ≤ 10000      8%</Text>
                                <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 10000 ≤ 20000     7%</Text>
                                <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 20000 ≤ 30000     6%</Text>
                                <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 30000 ≤ 999999999 5%</Text> */}
                            </View>
                            <View style={{ flexDirection: 'row', borderColor: 'white', borderWidth: 1, borderRadius: 15, padding:10}}>
                                <View style={{ width: '80%', alignItems:'flex-start' }}>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 3000 ≤ 5000</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 5000 ≤ 10000</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 10000 ≤ 20000</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 20000 ≤ 30000</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginLeft: 5 }}> ⚪ 30000 ≤ 999999999</Text>
                                </View>
                                <View style={{ width: '20%', alignItems:'center' }}>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginRight: 10 }}>9%</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginRight: 10 }}>8%</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginRight: 10 }}>7%</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginRight: 10 }}>6%</Text>
                                    <Text style={{ ...FONTS.font, color: colors.title, marginRight: 10 }}>5%</Text>
                                </View>

                            </View>


                            {/* <View style={{
                                marginTop: -20,
                                marginBottom: 10,
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}>
                                <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                    <FeatherIcon name={'user-check'} size={15} color={COLORS.info} />
                                    <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{profile.email}</Text>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center', }}>
                                    <FeatherIcon name={'check-circle'} size={15} color={COLORS.info} />
                                    <Text style={{ ...FONTS.fontSm, color: colors.title, marginLeft: 5 }}>{myXYBalance.released_x_value}</Text>
                                </View>
                            </View>

                            <View style={{ alignItems: 'center', marginBottom: 15 }}>
                                <Ionicons name='checkmark-circle' style={{ marginBottom: 8 }} color={COLORS.info} size={60} />
                                <Text style={{ ...FONTS.h5, fontWeight: '900', color: colors.title, marginBottom: 6 }}>Claim Success</Text>
                            </View>
                            <Button title={'Okey'} onPress={() => setModalDesRule(false)} /> */}
                        </View>
                    </View>



                </View>
            </Modal>


        </ImageBackground>
    )
}


const styles = StyleSheet.create({
    tabBtn: {
        paddingHorizontal: 10,
        paddingVertical: 4,
        width: 50,
        alignItems: 'center',
        borderRadius: 4,
        marginHorizontal: 3,
    },
    btnTabActive: {
        backgroundColor: COLORS.white,
    },
    btnTabActiveText: {
        color: COLORS.title,
    },
    eyeIcon: {
        // marginLeft: 15,
        // position: 'absolute',
        // right: 0,
        // top: 0,
        // height: 48,
        // width: 48,
        paddingStart: 10,
        alignItems: 'center',
        justifyContent: 'center',
    }
})


export default BalanceChart;