import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { BASE_URL } from '../config/config';

// const BASE_URL = process.env.EXPO_PUBLIC_API_URL;
// const BASE_URL = 'https://qa_green_wallet_api.bitzwallet.com'; // Replace with your API base UR
// const BASE_URL = 'http://192.168.8.137:3000'; // Replace with your API base URL


// const BASE_URL = 'https://green_wallet_api.bitzwallet.com';


const api = axios.create({
    baseURL: BASE_URL,
});

let accessToken: string | null = null;

// Function to handle API errors
const handleApiError = (error: any) => {
    console.error('API Error:', error);
    throw new Error('An error occurred while making the API call.');
};

// Function to refresh the access token using the refresh token
const refreshAccessToken = async (): Promise<void> => {
    try {


        const refreshToken = ''
        // Make a request to your API to refresh the access token using the refresh token
        const response = await api.post('/refresh-token', { refreshToken });

        if (response.data && response.data.accessToken) {
            accessToken = response.data.accessToken;
        } else {
            throw new Error('Failed to refresh access token.');
        }
    } catch (error) {
        handleApiError(error);
    }
};

// Function to make an API request with the access token
const makeApiRequest = async <T>(
    method: string,
    url: string,
    data?: any,
    accessToken?: string,
    // config?: AxiosRequestConfig
): Promise<any> => {


    try {
        if (!accessToken) {
            throw new Error('Access token is missing.');
        }

        const response: AxiosResponse<T> = await api.request<T>({
            method,
            url,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
            // ...config,
        });

        return response.data;
    } catch (error) {
        handleApiError(error);
    }
};

// API methods
const PrivateAPI = {
    get: async <T>(
        url: string,
        tokens: { accessToken: string, refreshToken: string },
        config?: AxiosRequestConfig
    ): Promise<T> => {
        try {
            return await makeApiRequest<T>('GET', BASE_URL + url, undefined, tokens.accessToken);
        } catch (error: any) {
            // If the API call fails due to an invalid access token, try refreshing the token and retry the request
            if (error.response && error.response.status === 401) {
                // const refreshedAccessToken = await refreshAccessToken();
                return await makeApiRequest<T>('GET', BASE_URL + url, undefined, tokens.accessToken);
            } else {
                throw error;
            }
        }
    },
    post: async <T>(
        url: string,
        tokens: { accessToken: string, refreshToken: string },
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<T> => {
        try {
            return await makeApiRequest<T>('POST', url, data, tokens.accessToken);
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                // const refreshedAccessToken = await refreshAccessToken();
                return await makeApiRequest<T>('POST', url, data, tokens.accessToken);
            } else {
                throw error;
            }
        }
    },


    patch: async <T>(
        url: string,
        tokens: { accessToken: string, refreshToken: string },
        data?: any,
        config?: AxiosRequestConfig
    ): Promise<T> => {
        try {
            return await makeApiRequest<T>('PATCH', url, data, tokens.accessToken);
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                // const refreshedAccessToken = await refreshAccessToken();
                return await makeApiRequest<T>('PATCH', url, data, tokens.accessToken);
            } else {
                throw error;
            }
        }
    },


    delete: async <T>(
        url: string,
        tokens: { accessToken: string, refreshToken: string },
        config?: AxiosRequestConfig
    ): Promise<T> => {
        try {
            return await makeApiRequest<T>('DELETE', url, undefined, tokens.accessToken);
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                // const refreshedAccessToken = await refreshAccessToken();
                return await makeApiRequest<T>('DELETE', url, undefined, tokens.accessToken);
            } else {
                throw error;
            }
        }
    },
};

export default PrivateAPI;